import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// react-router-dom components
import { Link } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import { Checkbox } from "@mui/material";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import CoverLayout from "../components/CoverLayout";
// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import ChangePassword from "../change-password/ChangePassword";
// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import logo from "../../../assets/logo/Logo.png";
import { postData } from "services/request";

import usePasswordResetLogic from "./passwordresetlogic";

function ResetThePassword() {
  const { email, setEmail, handleForgotPassword, loading, message, error } =
    usePasswordResetLogic();

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox bgColor="transparent" borderRadius="lg" p={2} textAlign="center" width="100%">
          <MDTypography variant="h4" fontWeight="medium" color="black" mt={1}>
            Forgot Password
          </MDTypography>
          <MDTypography
            variant="h6"
            fontWeight="normal"
            color="black"
            mt={1}
            sx={{ color: "#008080" }}
          >
            You forgot your password? Here you can easily retrieve a new password.
          </MDTypography>
        </MDBox>
        <MDBox pt={0} pb={3} px={3}>
          <MDBox component="form">
            <MDBox mb={4}>
              <MDTypography
                style={{
                  color: "#210302",
                  fontSize: "18px",
                  fontWeight: "normal",
                  margin: "5px",
                }}
              >
                Email:
              </MDTypography>
              <MDInput
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="esteban_schiller@gmail.com"
                fullWidth
              />
            </MDBox>
            <MDBox mt={6} mb={1}>
              <MDButton
                variant="gradient"
                type="submit"
                color="info"
                fullWidth
                onClick={(e) => handleForgotPassword(e)}
                sx={{
                  background: "#7F9AE3",
                  color: "#FFFFFF",
                  borderRadius: "50px",
                  "&:hover": {
                    background: "#7F9AE3",
                  },
                }}
              >
                {loading ? "loading ... " : "Request New Password"}
              </MDButton>
              {error && (
                <MDTypography variant="caption" color="error" textAlign="center">
                  {error}
                </MDTypography>
              )}
              {message && (
                <MDTypography variant="caption" color="success" textAlign="center">
                  {message}
                </MDTypography>
              )}
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default ResetThePassword;
