import { useEffect, useState } from "react";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "interface/Tables/DataTable";
import DashboardLayout from "interface/LayoutContainers/DashboardLayout";
import DashboardNavbar from "interface/Navbars/DashboardNavbar";
import UserProfile from "layouts/profile/userprofile";
import { useNavigate } from "react-router-dom";
import { Divider, Menu } from "@mui/material";
import Avatar from "@mui/material";
import pic from "assets/flags/france.png";
import MenuItem from "@mui/material/MenuItem";
import { getItem } from "utils/localStorage";
import { getData } from "services/request";
import MDButton from "components/MDButton";
// icons
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { responsiveFontSizes } from "@mui/material";
import { CheckBox, WidthFull } from "@mui/icons-material";
import MDInput from "components/MDInput";
import Addcustomer from "interface/Add-customer/add-customer";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import Editmedicinepopup from "interface/Edit-medicine";
import Editcustomer from "interface/Editcustomer";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

function Customermain() {
  // const [usersData, setUsersData] = useState([]);
  const [showUserProfile, setShowUserProfile] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedName, setSelectedName] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const navigate = useNavigate();
  // delete popup
  const Delete = () => {
    Swal.fire({
      title: "Are You Sure?",
      text: "This event data will be removed permanently.",
      icon: "error",
      showCancelButton: true,
      confirmButtonText: "Yes Delete",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#9FEFAB",
      cancelButtonColor: "#E8D0D0",
      allowOutsideClick: false,
      allowEscapeKey: false,
      customClass: {
        confirmButton: "custom-confirm-button", // Apply custom confirm button style
        cancelButton: "custom-cancel-button", // Apply custom cancel button style
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // navigate("/MedicineList");
      }
    });
  };
  const addCustomButtonStyles = () => {
    const style = document.createElement("style");
    style.innerHTML = `
      .custom-confirm-button {
        color: #000000 ; 
      }
      .custom-cancel-button {
        color: #000000 ; 
      }
    `;
    document.head.appendChild(style);
  };
  const [modalOpen, setModalOpen] = useState(false);
  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  // Call this function once to add the styles globally
  addCustomButtonStyles();
  // nnewwww
  const [menutag, setMenutag] = useState(null);
  const openMenutag = ({ currentTarget }) => setMenutag(currentTarget);
  const closeMenutag = () => setMenutag(null);
  const renderMenutag = (
    <Menu
      id="simple-menu"
      anchorEl={menutag}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menutag)}
      onClose={closeMenutag}
    >
      <MenuItem onClick={closeMenutag}>
        <MDButton onClick={handleOpen}>Edit Selected</MDButton>
      </MenuItem>
      <MenuItem onClick={closeMenutag}>
        <MDButton onClick={Delete}>Removes selected</MDButton>
      </MenuItem>
    </Menu>
  );

  const handleRowClick = (row) => {
    setSelectedRow(row.uniqueId);
    // navigate(`/user-profile/${row.uniqueId}`);
    setSelectedName(row.name);
    console.log("this is a name of selected row", selectedName);
  };

  const columns = [
    { Header: "ID", accessor: "Id" },
    // { Header: "Image", accessor: "Image", color: "#202224" },
    { Header: "Customer Name", accessor: "Name" },
    { Header: "Phone Number / Emails ", accessor: "Number" },
    { Header: "Address ", accessor: "Address" },
    { Header: "Payment", accessor: "Payment" },

    { Header: "Action", accessor: "Action" },
  ];
  const customerData = [
    {
      Id: "#P7685",
      Name: (
        <Grid
          item
          xs={12}
          container
          spacing={2}
          paddingTop={2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={pic} alt="hlo" style={{ borderRadius: "50%", margin: "0 10px" }} />
          <span>Andrew Bojangles</span>
        </Grid>
      ),
      Number: "+79000010101",
      Address: "$20.089 Kutch Green Apt. 448 USD",
      Payment: "Mastercard",
      Action: (
        <MDBox
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid #979797",
            padding: "6px",
            borderRadius: "10px",
            backgroundColor: "#f0f0f0",
            fontSize: "16px",
            position: "relative",
          }}
        >
          <MDBox style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <EditOutlinedIcon onClick={handleOpen} />
          </MDBox>
          <Divider
            style={{
              width: "2px",
              height: "24px",
              backgroundColor: "#979797",
              margin: "0 10px",
            }}
          />

          <MDBox style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <DeleteOutlinedIcon style={{ color: "#EF3826" }} onClick={Delete} />
          </MDBox>
        </MDBox>
      ),
    },
    {
      CustomerName: (
        <Grid
          item
          xs={12}
          container
          spacing={2}
          paddingTop={2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <input type="checkbox" style={{ border: "5px solid red" }} />
          <img src={pic} alt="hlo" style={{ borderRadius: "50%", margin: "0 10px" }} />
          <span>MED - 1008</span>
        </Grid>
      ),
      ID: "#P7685",
      Phonenumber: "+79000010101",
      PurchaseDetails: "Item: Zimax50mg Quantity: 12pcs",
      Amount: "$20.55 USD",
      Status: (
        <MDBox style={{ display: "flex", alignItems: "center" }}>
          <h5>Inactive</h5>
          <MDButton>
            <MoreVertIcon onClick={openMenutag}></MoreVertIcon>
          </MDButton>
          {renderMenutag}
        </MDBox>
      ),
    },
    {
      CustomerName: (
        <Grid
          item
          xs={12}
          container
          spacing={2}
          paddingTop={2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <input type="checkbox" style={{ border: "5px solid red" }} />
          <img src={pic} alt="hlo" style={{ borderRadius: "50%", margin: "0 10px" }} />
          <span>MED - 1008</span>
        </Grid>
      ),
      ID: "#P7685",
      Phonenumber: "+79000010101",
      PurchaseDetails: "Item: Zimax50mg Quantity: 12pcs",
      Amount: "$20.55 USD",
      Status: (
        <MDBox style={{ display: "flex", alignItems: "center" }}>
          <h5>Inactive</h5>
          <MDButton>
            <MoreVertIcon onClick={openMenutag}></MoreVertIcon>
          </MDButton>
          {renderMenutag}
        </MDBox>
      ),
    },
  ];
  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <MDBox
        // mx={2}
        // mt={3}
        p={4}
        // px={2}
        // variant="gradient"
        bgColor="none"
        borderRadius="lg"
        coloredShadow="transparent"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: { xs: "start" },
          flexDirection: { xs: "column", md: "row", sm: "row", xl: "row" },
          gap: { xs: 1, sm: 2 },
        }}
      >
        <Editcustomer open={modalOpen} handleClose={handleClose} />
        <MDBox>
          <MDTypography style={{ fontSize: "32px", fontWeight: "bold", color: "#304F5E" }}>
            Customer Lists
          </MDTypography>
        </MDBox>
        <MDBox
          sx={{
            display: "flex",
            // justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* <MDBox
            sx={{
              background: "#5478D9",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <AddIcon sx={{ color: " white ", marginLeft: "10px" }} />
            <Link to="/Customermain/addcustomer">
              <MDButton sx={{ color: " white " }}>Add Customer</MDButton>
            </Link>
          </MDBox> */}
        </MDBox>
      </MDBox>
      <MDBox>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3} style={{ height: "100%", minHeight: "400px" }}>
                <DataTable
                  entriesPerPage={{ defaultValue: 4, entries: [5, 10, 15] }}
                  // canSearch={true}
                  showTotalEntries={true}
                  table={{ columns, rows: customerData }}
                  pagination={{ variant: "gradient", color: "white" }}
                  isSorted={true}
                  noEndBorder={true}
                  onRowClick={handleRowClick}
                />
              </MDBox>
              <MDBox pt={1} style={{ height: "auto" }}>
                {showUserProfile && (
                  <UserProfile uniqueId={selectedRow} onClose={() => setShowUserProfile(false)} />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
export default Customermain;
