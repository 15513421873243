import React, { useState } from "react";
import { Modal, Box, Typography, Grid, IconButton } from "@mui/material";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { Card } from "@mui/material";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import CloseIcon from "@mui/icons-material/Close";
import colors from "assets/theme-dark/base/colors";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "200px", md: "500px", sm: "500px", xl: "500px" },
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  position: "relative",
};
const Editmedicinepopup = ({ open, handleClose }) => {
  const [price, setPrice] = useState("");
  const [category, setcategory] = useState("");
  const [stock, setstock] = useState("");
  const submit = (e) => {
    e.preventDefault();
    if (!price || !category || !stock) {
      // alert("Please fill out all fields.");
    } else {
      console.log("Form Data:", { price, category, stock });
      handleClose();
    }
  };
  return (
    <Modal
      open={open}
      onClose={(e, reason) => {
        if (reason === "backdropClick") {
          e.stopPropagation();
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      BackdropProps={{
        invisible: true,
      }}
      disableEscapeKeyDown
    >
      <Box sx={style}>
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
            zIndex: 10,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Edit Medicine
        </Typography>
        <MDBox>
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row", sm: "row", xl: "row" },
            }}
          >
            <Grid item xs={6}>
              <MDTypography
                sx={{
                  fontSize: { xs: "14px", md: "16px", sm: "20px", xl: "20px" },
                  width: { xs: "135px", xl: "100%" },
                }}
              >
                Price
              </MDTypography>
              <MDInput
                type="number"
                // placeholder="Enter medicine name"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                sx={{ width: { xs: "135px", md: "100%", xl: "100%" } }}
              />
            </Grid>
            <Grid item xs={6}>
              <MDTypography
                sx={{
                  fontSize: { xs: "14px", md: "16px", sm: "20px", xl: "20px" },
                  width: { xs: "135px", xl: "100%" },
                }}
              >
                Category
              </MDTypography>
              <MDInput
                type="text"
                // placeholder="Enter dosage"
                value={category}
                onChange={(e) => setcategory(e.target.value)}
                sx={{ width: { xs: "135px", md: "100%", xl: "100%" } }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row", sm: "row", xl: "row" },
            }}
          >
            {" "}
            <Grid item xs={6}>
              <MDTypography
                sx={{
                  fontSize: { xs: "14px", md: "16px", sm: "20px", xl: "20px" },
                  width: { xs: "135px", xl: "100%" },
                }}
              >
                Stock
              </MDTypography>
              <MDInput
                type="text"
                // placeholder="Enter manufacturer"
                value={stock}
                onChange={(e) => setstock(e.target.value)}
                sx={{ width: { xs: "135px", md: "100%", xl: "100%" } }}
              />
            </Grid>
          </Grid>
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <MDButton
              type="submit"
              onClick={submit}
              sx={{
                padding: { xs: "8px 12px", sm: "10px 15px", md: "12px 20px" },
              }}
            >
              <MDTypography
                sx={{
                  fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" },
                  color: "#FFFFFF",
                  backgroundColor: "#5478D9",
                  padding: "10px",
                  borderRadius: "8px",
                }}
              >
                Edit Medicine
              </MDTypography>
            </MDButton>
          </MDBox>
        </MDBox>
      </Box>
    </Modal>
  );
};
Editmedicinepopup.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
export default Editmedicinepopup;
