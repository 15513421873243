import { useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CloseIcon from "@mui/icons-material/Close";

import MDTypography from "components/MDTypography";

import { columns, rows } from "layouts/user/data/authorsTableData";

import DataTable from "interface/Tables/DataTable";

// import Overview from "layouts/profile";
import UserProfile from "layouts/profile/userprofile";

import MDBox from "components/MDBox";

import DashboardLayout from "interface/LayoutContainers/DashboardLayout";
import DashboardNavbar from "interface/Navbars/DashboardNavbar";
import Invoivecustomdata from "interface/invoice-customdata";
// icon
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { Padding } from "@mui/icons-material";
import MDButton from "components/MDButton";
import { space } from "postcss/lib/list";
import PropTypes from "prop-types";
import borders from "assets/theme/base/borders";
import { Box, IconButton, Modal } from "@mui/material";

// import backgroundimage from "assets/Invoices/Wallet.png";
const handleRowClick = (rows) => {
  console.log("Clicked row:", { rows });
};

function Invoiceform({ open, handleClose }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "320px", md: "500px", sm: "500px", xl: "900px" },
    height: "500px",
    overflow: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    position: "relative",
  };
  // const [showUserProfile, setShowUserProfile] = useState(false);
  // const [selectedRow, setSelectedRow] = useState(null);
  // <MDBox pt={6} pb={3}>
  //   <Grid container spacing={6}>
  //     <Grid item xs={12}>
  //       <Card>
  //         <MDBox pt={3} style={{ height: "100%", minHeight: "400px" }}>
  //           <DataTable
  //             entriesPerPage={{ defaultValue: 5, entries: [5, 10, 15] }}
  //             canSearch={true}
  //             showTotalEntries={true}
  //             table={{ columns, rows }}
  //             pagination={{ variant: "gradient", color: "info" }}
  //             isSorted={true}
  //             noEndBorder={true}
  //             onRowClick={handleRowClick}
  //           />
  //         </MDBox>

  //         <MDBox pt={3} style={{ height: "100%" }}>
  //           {showUserProfile && (
  //             <UserProfile rowData={selectedRow} onClose={() => setShowUserProfile(false)} />
  //           )}
  //         </MDBox>
  //       </Card>
  //     </Grid>
  //   </Grid>
  // </MDBox>;

  const [invoiceData, setInvoiceData] = useState({
    from: { name: "Virginia Walker", address: "9694 Krajcik Locks Suite 635" },
    to: { name: "John Doe", address: "1234 Elm Street, Apt 42" },
    invoiceDate: "2024-11-08",
    dueDate: "2024-12-08",
    total: 4680,
  });
  const [medicineName, setMedicineName] = useState("");
  const [dosage, setDosage] = useState("");
  const [manufacturer, setManufacturer] = useState("");
  const handleSave = (e) => {
    e.preventDefault();
    if (!medicineName || !dosage || !manufacturer) {
      alert("Please fill out all fields.");
    } else {
      console.log("Form Data:", { medicineName, dosage, manufacturer });
      handleClose();
    }
  };
  return (
    <Modal
      open={open}
      onClose={(e, reason) => {
        if (reason === "backdropClick") {
          e.stopPropagation();
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      BackdropProps={{
        invisible: true,
      }}
      disableEscapeKeyDown
    >
      <Box sx={style}>
        <MDBox sx={{ padding: 1 }}>
          {" "}
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
              zIndex: 10,
            }}
          >
            <CloseIcon />
          </IconButton>
        </MDBox>
        <MDBox>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <Grid container p={3} spacing={5}>
                  <Grid item xs={12} sm={6} md={4}>
                    <MDBox
                      style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                    >
                      <MDBox>
                        <MDTypography
                          style={{ color: "#404040", fontSize: "16px", paddingBottom: "8px" }}
                        >
                          Invoice From:
                        </MDTypography>
                        <MDTypography>
                          <h5 style={{ fontSize: "16px", color: "#404040" }}>
                            {invoiceData.from.name}
                          </h5>
                          <p style={{ fontSize: "14px", color: "#565656" }}>
                            {invoiceData.from.address}
                          </p>
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MDBox
                      style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                    >
                      <MDBox>
                        <MDTypography
                          style={{ color: "#404040", fontSize: "16px", paddingBottom: "8px" }}
                        >
                          Invoice To:
                        </MDTypography>
                        <MDTypography>
                          <h5 style={{ fontSize: "16px", color: "404040" }}>
                            {invoiceData.to.name}
                          </h5>
                          <p style={{ fontSize: "14px", color: "#565656" }}>
                            {invoiceData.to.address}
                          </p>
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MDBox
                      style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                    >
                      <MDBox pt={2}>
                        <MDBox>
                          <MDTypography style={{ color: "#404040", fontSize: "16px" }}>
                            Invoice Date: {invoiceData.invoiceDate}
                          </MDTypography>
                        </MDBox>
                        <MDBox>
                          <MDTypography style={{ color: "#404040", fontSize: "16px" }}>
                            Due Date: {invoiceData.dueDate}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Grid>
                </Grid>

                <MDBox pt={3} style={{ height: "100%", minHeight: "400px", overflowY: "auto" }}>
                  <Invoivecustomdata
                    entriesPerPage={false}
                    canSearch={false}
                    showTotalEntries={true}
                    table={{ columns, rows }}
                    pagination={{ variant: "#9a3230", color: "#9a3230" }}
                    isSorted={true}
                    noEndBorder={true}
                    onRowClick={handleRowClick}
                    // pagination={true}
                  />
                  <MDBox
                    mt={5}
                    mr={2}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      // border: "1px solid red",
                    }}
                  >
                    <MDBox
                      mr={2}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#202224",
                      }}
                    >
                      <MDTypography>Total =</MDTypography>
                      <MDTypography style={{ fontWeight: "bold" }}>
                        ${invoiceData.total}
                      </MDTypography>
                    </MDBox>

                    <MDButton style={{ backgroundColor: "#D5D5D5" }}>
                      <LocalPrintshopIcon></LocalPrintshopIcon>
                    </MDButton>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </Box>
    </Modal>
  );
}
Invoiceform.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default Invoiceform;
