import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import axios from "axios";
import { getItem } from "utils/localStorage";
import { useParams } from "react-router-dom";
// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "interface/LayoutContainers/DashboardLayout";
import DashboardNavbar from "interface/Navbars/DashboardNavbar";
import Footer from "interface/Footer";
import ProfileInfoCard from "interface/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "interface/Lists/ProfilesList";
import DefaultProjectCard from "interface/Cards/ProjectCards/DefaultProjectCard";
import RecentActivities from "layouts/dashboard/components/recentActivies";
// import recentActivities from "layouts/dashboard/components/OrdersOverview";
// Overview page components
import Header from "layouts/profile/components/Header/index";

import PlatformSettings from "layouts/profile/components/PlatformSettings";

// Data
import profilesListData from "layouts/profile/data/profilesListData";
// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import homeDecor4 from "assets/images/home-decor-4.jpeg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import { CallMerge } from "@mui/icons-material";
import { setItem } from "utils/localStorage";

function UserProfile({ onClose }) {
  const { uniqueId } = useParams();
  const [userDetails, setUserDetails] = useState(null);
  const [allUsers, setAllUsers] = useState([]);
  const [selectedName, setSelectedName] = useState(null);
  const [BlockedUserId, setBlockUser] = useState(null);
  console.log("this is unique id", uniqueId);

  useEffect(() => {
    const fetchAllUsers = async () => {
      try {
        const token = getItem("token");

        if (!token) {
          throw new Error("Token is missing from local storage.");
        }
        const response = await axios.get(`http://192.168.1.27:3000/api/users`, {
          headers: {
            "x-access-token": token,
          },
        });
        setAllUsers(response.data.items);
      } catch (error) {
        console.error("Error fetching all users:", error.response?.data || error.message);
      }
    };

    fetchAllUsers();
  }, []);

  useEffect(() => {
    if (uniqueId && allUsers.length > 0) {
      const user = allUsers.find((user) => user.userName === uniqueId);
      if (user) {
        setUserDetails(user.currentProfile);
        setSelectedName(user.currentProfile.name);
        setBlockUser(user.currentProfile.userId.id);
        // console.log("blockedUserId", user.currentProfile.userId.id);
        // setItem("blockUserId", user.currentProfile.userId.id);
      } else {
        console.error("User not found with ID:", uniqueId);
      }
    }
  }, [uniqueId, allUsers]);
  // console.log("this is a block user id", BlockedUserId);

  return (
    <DashboardLayout>
      <MDBox mb={2}>
        <Header userDetails={userDetails} selectedName={selectedName} BlockedUserId={BlockedUserId}>
          <MDBox mt={5} mb={3} sx={{ width: "90%", margin: "0 auto" }}>
            <Grid container spacing={1} justifyContent="space-between">
              <Grid
                item
                xs={12}
                md={8}
                xl={6}
                sx={{ display: "flex", justifyContent: "center", mr: 2 }}
              >
                <Divider orientation="vertical" sx={{ ml: 1, mr: 1 }} />
                <ProfileInfoCard
                  title="profile information"
                  description={userDetails ? userDetails.profileDesc : "N/A"}
                  info={{
                    FullName: userDetails ? userDetails.name : "N/A",
                    Mobile: userDetails ? userDetails.phone : "N/A",
                    Email: userDetails ? userDetails.email : "N/A",
                    Country: userDetails ? userDetails.countryName : "N/A",
                    Stone: userDetails ? userDetails.stons : "N/A",
                    Gem: userDetails ? userDetails.gems : "N/A",
                  }}
                  action={{ route: "", tooltip: "Edit Profile" }}
                  shadow={false}
                />
                <Divider orientation="vertical" sx={{ mx: 0 }} />
              </Grid>
              <Grid item xs={12} xl={4} sx={{ ml: 2 }}>
                {/* <recentActivities /> */}
                <RecentActivities />
              </Grid>
            </Grid>
          </MDBox>

          <MDBox pt={2} px={2} lineHeight={1.25}>
            <MDTypography variant="h6" fontWeight="medium">
              No. of Videos
            </MDTypography>
            {/* <MDBox mb={1}>
            <MDTypography variant="button" color="text">
              Architects design houses
            </MDTypography>
          </MDBox> */}
          </MDBox>
          <MDBox p={2}>
            <Grid container spacing={6}>
              <Grid item xs={12} md={6} xl={3}>
                <DefaultProjectCard
                  image={homeDecor1}
                  label="project #2"
                  // title="modern"
                  // description="As Uber works through a huge amount of internal management turmoil."
                  // action={{
                  //   type: "internal",
                  //   route: "/pages/profile/profile-overview",
                  //   color: "info",
                  //   label: "view project",
                  // }}
                  // authors={[
                  //   { image: team1, name: "Elena Morison" },
                  //   { image: team2, name: "Ryan Milly" },
                  //   { image: team3, name: "Nick Daniel" },
                  //   { image: team4, name: "Peterson" },
                  // ]}
                />
              </Grid>
              <Grid item xs={12} md={6} xl={3}>
                <DefaultProjectCard
                  image={homeDecor2}
                  label="project #1"
                  // title="scandinavian"
                  // description="Music is something that everyone has their own specific opinion about."
                  // action={{
                  //   type: "internal",
                  //   route: "/pages/profile/profile-overview",
                  //   color: "info",
                  //   label: "view project",
                  // }}
                  // authors={[
                  //   { image: team3, name: "Nick Daniel" },
                  //   { image: team4, name: "Peterson" },
                  //   { image: team1, name: "Elena Morison" },
                  //   { image: team2, name: "Ryan Milly" },
                  // ]}
                />
              </Grid>
              <Grid item xs={12} md={6} xl={3}>
                <DefaultProjectCard
                  image={homeDecor3}
                  label="project #3"
                  // title="minimalist"
                  // description="Different people have different taste, and various types of music."
                  // action={{
                  //   type: "internal",
                  //   route: "/pages/profile/profile-overview",
                  //   color: "info",
                  //   label: "view project",
                  // }}
                  // authors={[
                  //   { image: team4, name: "Peterson" },
                  //   { image: team3, name: "Nick Daniel" },
                  //   { image: team2, name: "Ryan Milly" },
                  //   { image: team1, name: "Elena Morison" },
                  // ]}
                />
              </Grid>
              <Grid item xs={12} md={6} xl={3}>
                <DefaultProjectCard
                  image={homeDecor4}
                  label="project #4"
                  // title="gothic"
                  // description="Why would anyone pick blue over pink? Pink is obviously a better color."
                  // action={{
                  //   type: "internal",
                  //   route: "/pages/profile/profile-overview",
                  //   color: "info",
                  //   label: "view project",
                  // }}
                  // authors={[
                  //   { image: team4, name: "Peterson" },
                  //   { image: team3, name: "Nick Daniel" },
                  //   { image: team2, name: "Ryan Milly" },
                  //   { image: team1, name: "Elena Morison" },
                  // ]}
                />
              </Grid>
            </Grid>
          </MDBox>
        </Header>
      </MDBox>
    </DashboardLayout>
  );
}

UserProfile.propTypes = {
  uniqueId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  userDetails: PropTypes.object,
};

export default UserProfile;
