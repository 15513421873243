import { useEffect, useState } from "react";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "interface/Tables/DataTable";
import DashboardLayout from "interface/LayoutContainers/DashboardLayout";
import DashboardNavbar from "interface/Navbars/DashboardNavbar";
import UserProfile from "layouts/profile/userprofile";
import { useNavigate } from "react-router-dom";
import { Menu } from "@mui/material";
import Avatar from "@mui/material";
import pic from "assets/flags/france.png";
import MenuItem from "@mui/material/MenuItem";
import { getItem } from "utils/localStorage";
import { getData } from "services/request";
import MDButton from "components/MDButton";
// icons
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { responsiveFontSizes } from "@mui/material";
import { CheckBox, WidthFull } from "@mui/icons-material";
import MDInput from "components/MDInput";
import { Action as NavigationType } from "@remix-run/router";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { red } from "@mui/material/colors";
import borders from "assets/theme/base/borders";
import { Link } from "react-router-dom";
// import { useNavigate } from "react-router-dom";

function Addcustomer() {
  // const [usersData, setUsersData] = useState([]);
  const [showUserProfile, setShowUserProfile] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedName, setSelectedName] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    number: "",
    address: "",
    purchased: "",
    quantity: "",
    amount: "",
    status: "",
    message: "",
  });

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // sweetalert("Form data submitted:", formData);
    Swal.fire({
      title: "Thank you",
      text: "Customer added successfully ! ",
      icon: "success",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonText: "Close",
      cancelButtonColor: "#5480DF",
    }).then(() => {
      navigate("/Customermain");
    });

    // Clear form after submission (optional)
    setFormData({
      name: "",
      email: "",
      message: "",
      number: "",
      address: "",
      purchased: "",
      quantity: "",
      amount: "",
      status: "",
      message: "",
    });
  };

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <MDBox
        mx={2}
        mt={3}
        // py={3}
        // px={2}
        xs={12}
        md={6}
        lg={3}
        // variant="gradient"
        bgColor="none"
        borderRadius="lg"
        coloredShadow="transparent"
        sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
      >
        <MDBox>
          <MDTypography variant="h3" color="dark">
            Add Customer
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox pt={2} pb={3} xs={12} md={6} lg={3}>
        <Card xs={12} md={6} lg={3}>
          <MDBox pt={3} pb={5} style={{ height: "100%" }}>
            <form onSubmit={handleSubmit}>
              <Grid
                pb={5}
                container
                // border={2}
                // pl={18}
                sx={{
                  pl: { lg: 18, md: 6, xs: 5 },
                  justifyContent: { xs: "center", md: "flex-start" },
                }}
              >
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <MDBox>
                    <MDTypography
                      style={{
                        color: "#202224",
                        fontWeight: " bold ",
                        fontSize: "18px",
                        marginBottom: "10px",
                      }}
                    >
                      Name:
                    </MDTypography>
                    <MDInput
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <MDBox>
                    <MDTypography
                      style={{
                        color: "#202224",
                        fontWeight: " bold ",
                        fontSize: "18px",
                        marginBottom: "10px",
                      }}
                    >
                      Phone:
                    </MDTypography>
                    <MDInput
                      type="Number"
                      name="Phone"
                      value={formData.phone}
                      onChange={handleChange}
                      required
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <MDBox>
                    <MDTypography
                      style={{
                        color: "#202224",
                        fontWeight: " bold ",
                        fontSize: "18px",
                        marginBottom: "10px",
                      }}
                    >
                      Email:
                    </MDTypography>
                    <MDInput
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </MDBox>
                </Grid>
              </Grid>
              <Grid
                pb={5}
                container
                // border={2}
                // pl={18}
                sx={{
                  pl: { lg: 18, md: 6, xs: 5 },
                  justifyContent: { xs: "center", md: "flex-start" },
                }}
              >
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <MDBox>
                    <MDTypography
                      style={{
                        color: "#202224",
                        fontWeight: " bold ",
                        fontSize: "18px",
                        marginBottom: "10px",
                      }}
                    >
                      Address
                    </MDTypography>
                    <MDInput
                      type="text"
                      name="address"
                      value={formData.address}
                      onChange={handleChange}
                      required
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <MDBox>
                    <MDTypography
                      style={{
                        color: "#202224",
                        fontWeight: " bold ",
                        fontSize: "18px",
                        marginBottom: "10px",
                      }}
                    >
                      Purchased Item
                    </MDTypography>
                    <MDInput
                      type="text"
                      name="purchased"
                      value={formData.purchased}
                      onChange={handleChange}
                      required
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <MDBox>
                    <MDTypography
                      style={{
                        color: "#202224",
                        fontWeight: " bold ",
                        fontSize: "18px",
                        marginBottom: "10px",
                      }}
                    >
                      Purchased Quantity
                    </MDTypography>
                    <MDInput
                      type="number"
                      name="quantity"
                      value={formData.quantity}
                      onChange={handleChange}
                      placeholder={"Example"}
                      required
                    />
                  </MDBox>
                </Grid>
              </Grid>

              <Grid
                pb={5}
                container
                // border={2}
                // pl={18}
                sx={{
                  pl: { lg: 18, md: 6, xs: 5 },
                  justifyContent: { xs: "center", md: "flex-start" },
                }}
              >
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <MDBox>
                    <MDTypography
                      style={{
                        color: "#202224",
                        fontWeight: " bold ",
                        fontSize: "18px",
                        marginBottom: "10px",
                      }}
                    >
                      Amount
                    </MDTypography>
                    <MDInput
                      type="text"
                      name="amount"
                      value={formData.amount}
                      onChange={handleChange}
                      required
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <MDBox>
                    <MDTypography
                      style={{
                        color: "#202224",
                        fontWeight: " bold ",
                        fontSize: "18px",
                        marginBottom: "10px",
                      }}
                    >
                      Status
                    </MDTypography>
                    <MDBox>
                      <select
                        name="status"
                        value={formData.status}
                        onChange={handleChange}
                        required
                        style={{ padding: "12px 40px", borderRadius: "5px" }}
                      >
                        <option value="" disabled>
                          Select Status
                        </option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </select>
                    </MDBox>
                  </MDBox>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}></Grid>
              </Grid>
              <Grid
                pb={5}
                container
                // border={2}
                sx={{ pl: { lg: 18, md: 6 } }}
              >
                <Grid
                  pb={5}
                  item
                  xs={12}
                  sx={{
                    pl: { xs: 5, xl: 0 },
                    justifyContent: { xs: "center", md: "flex-start" },
                  }}
                >
                  <MDInput
                    type="text"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                    fullWidth
                    style={{
                      width: "85%",
                      //   border: "1px solid red",
                    }}
                  />
                </Grid>

                <Grid
                  display={"flex"}
                  sx={{
                    pl: { xs: 5, xl: 0 },
                    justifyContent: { xs: "center", md: "flex-start" },
                  }}
                >
                  <MDBox
                    sx={{
                      background: "#5478D9",
                      borderRadius: "8px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <MDButton type="submit" sx={{ color: " white " }}>
                      Add product
                    </MDButton>
                  </MDBox>
                </Grid>
              </Grid>

              {/* <button type="submit">Submit</button> */}
            </form>
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
export default Addcustomer;
