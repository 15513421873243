import { forwardRef, createContext, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
// import CustomMDPaginationItemRoot from "./CustomMDPaginationItemRoot";
import CustomMDPaginationItemRoot from "./customMDPagination";

// The Pagination main context
const Context = createContext();

const MDPagination = forwardRef(
  ({ item, variant, color, size, active, customColor, children, ...rest }, ref) => {
    const context = useContext(Context);
    const paginationSize = context ? context.size : null;

    const value = useMemo(
      () => ({ variant, color, size, customColor }),
      [variant, color, size, customColor]
    );

    return (
      <Context.Provider value={value}>
        {item ? (
          <CustomMDPaginationItemRoot
            {...rest}
            ref={ref}
            variant={active ? (context ? context.variant : variant) : "outlined"}
            color={active ? (context ? context.color : color) : "secondary"}
            iconOnly
            circular
            ownerState={{ variant, active, paginationSize, customColor }}
          >
            {children}
          </CustomMDPaginationItemRoot>
        ) : (
          <MDBox
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            sx={{ listStyle: "none" }}
          >
            {children}
          </MDBox>
        )}
      </Context.Provider>
    );
  }
);

// Setting default values for the props of MDPagination
MDPagination.defaultProps = {
  item: false,
  variant: "gradient",
  color: "#9a3230",
  size: "medium",
  active: false,
  customColor: "#9a3230",
};

// Typechecking props for the MDPagination
MDPagination.propTypes = {
  item: PropTypes.bool,
  variant: PropTypes.oneOf(["gradient", "#9a3230"]),
  color: PropTypes.oneOf([
    "white",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
    "#9a3230",
  ]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
  active: PropTypes.bool,
  customColor: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default MDPagination;
