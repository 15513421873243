import React, { useState } from "react";
import { Modal, Box, Typography, Grid, IconButton } from "@mui/material";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { Card } from "@mui/material";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import CloseIcon from "@mui/icons-material/Close";
import borders from "assets/theme/base/borders";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "200px", md: "500px", sm: "500px", xl: "500px" },
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  position: "relative",
};
const Editmainmedicine = ({ open, handleClose }) => {
  const [medicineName, setMedicineName] = useState("");
  const [dosage, setDosage] = useState("");
  const [manufacturer, setManufacturer] = useState("");
  const [weight, setWeight] = useState("");
  const [price, setprice] = useState("");
  const handleSave = (e) => {
    e.preventDefault();
    if (!medicineName || !dosage || !manufacturer || !weight) {
      alert("Please fill out all fields.");
    } else {
      console.log("Form Data:", { medicineName, dosage, manufacturer, weight });
      handleClose();
      setMedicineName("");
      setDosage("");
      setManufacturer("");
      setWeight("");
    }
  };
  return (
    <Modal
      open={open}
      onClose={(e, reason) => {
        if (reason === "backdropClick") {
          e.stopPropagation();
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      BackdropProps={{
        invisible: true,
      }}
      disableEscapeKeyDown
    >
      <Box sx={style}>
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
            zIndex: 10,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ fontSize: { xs: "14px", md: "15px", sm: "20px", xl: "20px" } }}
        >
          Edit Medicine
        </Typography>
        <MDBox>
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row", sm: "row", xl: "row" },
            }}
          >
            <Grid item xs={6}>
              <MDTypography
                sx={{
                  fontSize: { xs: "12px", md: "13px", sm: "14px", xl: "14px" },
                  width: { xs: "135px", xl: "100%" },
                }}
              >
                Medicine Name
              </MDTypography>
              <MDInput
                type="text"
                placeholder="Enter medicine name"
                value={medicineName}
                onChange={(e) => setMedicineName(e.target.value)}
                sx={{ width: { xs: "135px", md: "100%", xl: "100%" } }}
              />
            </Grid>
            <Grid item xs={6}>
              <MDTypography
                sx={{
                  fontSize: { xs: "12px", md: "13px", sm: "14px", xl: "14px" },
                  width: { xs: "135px", xl: "100%" },
                }}
              >
                Price
              </MDTypography>
              <MDInput
                type="text"
                placeholder="Enter dosage"
                value={dosage}
                onChange={(e) => setDosage(e.target.value)}
                sx={{ width: { xs: "135px", md: "100%", xl: "100%" } }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row", sm: "row", xl: "row" },
            }}
          >
            <Grid item xs={6}>
              <MDTypography
                sx={{
                  fontSize: { xs: "12px", md: "13px", sm: "14px", xl: "14px" },
                  width: { xs: "135px", xl: "100%" },
                }}
              >
                Expiry Date
              </MDTypography>
              <MDInput
                type="text"
                placeholder="Enter manufacturer"
                value={manufacturer}
                onChange={(e) => setManufacturer(e.target.value)}
                sx={{ width: { xs: "135px", md: "100%", xl: "100%" } }}
              />
            </Grid>
            <Grid item xs={6}>
              <MDTypography
                sx={{
                  fontSize: { xs: "12px", md: "13px", sm: "14px", xl: "14px" },
                  width: { xs: "135px", xl: "100%" },
                }}
              >
                Weight
              </MDTypography>
              <MDInput
                type="text"
                placeholder="Enter manufacturer"
                value={weight}
                onChange={(e) => setWeight(e.target.value)}
                sx={{ width: { xs: "135px", md: "100%", xl: "100%" } }}
              />
            </Grid>
          </Grid>

          <MDBox>
            <MDButton
              type="submit"
              onClick={handleSave}
              sx={{
                marginLeft: "-25px",
              }}
            >
              <MDTypography
                sx={{
                  fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" },
                  color: "#FFFFFF",
                  backgroundColor: "#5478D9",
                  padding: "10px",
                  borderRadius: "8px",
                  width: "180px",
                }}
              >
                Update
              </MDTypography>
            </MDButton>
          </MDBox>
        </MDBox>
      </Box>
    </Modal>
  );
};
Editmainmedicine.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
export default Editmainmedicine;
