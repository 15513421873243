/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "interface/LayoutContainers/DashboardLayout";
import DashboardNavbar from "interface/Navbars/DashboardNavbar";
// import InboxSide from "./InboxSide";
import { BorderBottom, Margin } from "@mui/icons-material";
import borders from "assets/theme/base/borders";
import { Card, Hidden } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Checkbox from "@mui/material/Checkbox";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import MDTypography from "components/MDTypography";
import InboxSide from "../InboxSide";
import profile from "../../../../../assets/inboxprofile/inboxprofile.jpg";
import MDButton from "components/MDButton";
import MenuItem from "@mui/material/MenuItem";
import { Menu } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Editemail from "interface/Edit-eamil";
import DataTable from "interface/Tables/DataTable";
import Editmedicinepopup from "interface/Edit-medicine";
import { useNavigate } from "react-router-dom";

function Draftdata() {
  const data = [
    {
      name: "started",
      label: "Draft",
      time: "8:38 AM",
      description: "Our Bachelor of Commerce program is ACBSP-accredited.",
    },
    {
      name: "Another Person",
      label: "Draft",
      time: "9:00 AM",
      description: "Another description here.",
    },
    {
      name: "Someone Else",
      label: "Draft",
      time: "10:00 AM",
      description: "More text goes here.",
    },
    {
      name: "Someone Else",
      label: "Draft",
      time: "10:00 AM",
      description: "More text goes here.",
    },
    {
      name: "Someone Else",
      label: "Draft",
      time: "10:00 AM",
      description: "More text goes here.",
    },
    {
      name: "Someone Else",
      label: "Draft",
      time: "10:00 AM",
      description: "More text goes here.",
    },
    {
      name: "Someone Else",
      label: "Draft",
      time: "10:00 AM",
      description: "More text goes here.",
    },
    {
      name: "Someone Else",
      label: "Draft",
      time: "10:00 AM",
      description: "More text goes here.",
    },
    {
      name: "Someone Else",
      label: "Draft",
      time: "10:00 AM",
      description: "More text goes here.",
    },
  ];
  const Delete = () => {
    Swal.fire({
      title: "Are You Sure?",
      text: "This event data will be removed permanently.",
      icon: "error",
      showCancelButton: true,
      confirmButtonText: "Yes Delete",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#9FEFAB",
      cancelButtonColor: "#E8D0D0",
      allowOutsideClick: false,
      allowEscapeKey: false,
      customClass: {
        confirmButton: "custom-confirm-button", // Apply custom confirm button style
        cancelButton: "custom-cancel-button", // Apply custom cancel button style
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // navigate("/MedicineList");
      }
    });
  };
  const [modalOpen, setModalOpen] = useState(false);
  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);
  const [menutag, setMenutag] = useState(null);
  const openMenutag = ({ currentTarget }) => {
    console.log("Menu opened");
    setMenutag(currentTarget);
  };
  const closeMenutag = () => setMenutag(null);
  const renderMenutag = (
    <Menu
      id="simple-menu"
      anchorEl={menutag}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menutag)}
      onClose={closeMenutag}
    >
      <MenuItem onClick={closeMenutag}>
        <MDButton onClick={handleOpen}>Edit Selected</MDButton>
      </MenuItem>
      <MenuItem onClick={closeMenutag}>
        <MDButton onClick={Delete}>Removes selected</MDButton>
      </MenuItem>
    </Menu>
  );
  const [search, setSearch] = useState("");
  // Columns for the DataTable
  const columns = [
    { Header: "", accessor: "Draft", width: "5%" },
    { Header: "", accessor: "Pic", width: "5%" },
    { Header: "", accessor: "name", width: "20%" },
    { Header: "", accessor: "description", width: "40%" },
    { Header: "", accessor: "time", width: "15%" },
    { Header: "", accessor: "label", width: "15%" },
  ];
  // Rows for the DataTable
  const rows = data
    .filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))
    .map((item) => ({
      Pic: (
        <span>
          {" "}
          <img
            src={profile}
            alt="hlo"
            style={{
              borderRadius: "50%",
              height: "30px",
              width: "40px",
            }}
          />
        </span>
      ),
      Draft: <span style={{ color: "#EF3826", fontWeight: "bold" }}>Draft</span>,
      name: item.name,
      label: (
        <span>
          <MDButton onClick={openMenutag}>
            <MoreVertIcon></MoreVertIcon>
          </MDButton>
          {renderMenutag}
        </span>
      ),
      description: item.description,
      time: item.time,
    }));
  const navigate = useNavigate();

  const handleRowClick = () => {
    navigate("/inbox/Openmessage");
  };

  return (
    <DashboardLayout>
      <MDBox
        pb={4}
        pt={4}
        // variant="gradient"
        bgColor="none"
        borderRadius="lg"
        coloredShadow="transparent"
        sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
      >
        <MDBox>
          <MDTypography style={{ fontSize: "32px", fontWeight: "bold", color: "#304F5E" }}>
            Inbox
          </MDTypography>
        </MDBox>
      </MDBox>
      <Grid container spacing={3} sx={{ marginLeft: -4 }}>
        <Grid item xs={12} sm={3} md={3}>
          <InboxSide />
        </Grid>

        {/* Main Content */}
        <Grid item xs={12} sm={9} md={9}>
          <MDBox style={{ height: "100%", minHeight: "400px", width: "100%" }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Card>
                  <MDBox mx={1} mt={2} py={1}>
                    {/* <MDTypography
                      style={{ paddingLeft: "50px", paddingBottom: "20px", fontWeight: "bold" }}
                    >
                      Draft
                    </MDTypography> */}
                    <MDBox
                      sx={{
                        borderBottom: "1px solid gray",
                        display: "flex",
                        justifyContent: {
                          xs: "flex-start",
                          sm: "space-between",
                          md: "space-between",
                        },
                        gap: { xs: "20px", sm: "30px", md: "50px" },
                        paddingLeft: { xs: "10px", sm: "20px", md: "40px" },
                        paddingRight: { xs: "10px", sm: "20px", md: "40px" },
                      }}
                    >
                      <MDTypography sx={{ fontSize: { xs: "14px", sm: "15px", md: "16px" } }}>
                        Email
                      </MDTypography>
                      <MDTypography sx={{ fontSize: { xs: "14px", sm: "15px", md: "16px" } }}>
                        Time
                      </MDTypography>
                    </MDBox>

                    <MDBox
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mt: 2,
                        mb: 2,
                        pl: 2,
                        ml: 2,
                        border: "1px solid black",
                        borderRadius: "20px",
                        overflow: "hidden",
                        maxWidth: "200px",
                        backgroundColor: "#F5F6FA",
                        width: "100%", // Make search bar width responsive
                      }}
                    >
                      <IconButton sx={{ p: "10px" }} aria-label="search">
                        <SearchIcon />
                      </IconButton>
                      <input
                        style={{
                          border: "none",
                          outline: "none",
                          fontSize: "15px",
                          padding: "10px",
                          flex: 1,
                          backgroundColor: "#F5F6FA",
                        }}
                        placeholder="Search Mail"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </MDBox>
                    <DataTable
                      entriesPerPage={{ defaultValue: 6, entries: [5, 10, 15] }}
                      checkboxSelection
                      showTotalEntries={true}
                      table={{ columns, rows }}
                      pagination={{ variant: "gradient", color: "white" }}
                      isSorted={true}
                      sx={{ border: 0 }}
                      onRowClick={handleRowClick}
                    />
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default Draftdata;
