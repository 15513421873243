import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { setItem } from "utils/localStorage";

const usePasswordResetLogic = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const API = `${process.env.REACT_APP_BASE_URL}/auths/forgotPassword`;

  const handleForgotPassword = async (e) => {
    setLoading(true);
    e.preventDefault();

    if (!email.trim()) {
      setLoading(false);

      return setError("Please enter the email.");
    }
    setLoading(true);
    setError("");
    setMessage("");

    const payload = { authMethod: "email", username: email, deviceId: "123", deviceType: "web" };

    try {
      const response = await axios.post(API, payload);
      console.log(response);
      setItem("userId", response.data.data.id);
      setMessage("If an account with that email exists, a password reset link has been sent.");

      navigate("/otp-verification");
    } catch (error) {
      setError(error.response.data.message);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return { email, setEmail, handleForgotPassword, message, error, loading };
};

export default usePasswordResetLogic;
