import React, { useRef, useState } from "react";
import { Modal, Box, Typography, Grid, IconButton, TextField, useMediaQuery } from "@mui/material";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { Card } from "@mui/material";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import CloseIcon from "@mui/icons-material/Close";
import TextFormatOutlinedIcon from "@mui/icons-material/TextFormatOutlined";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";
import MinimizeOutlinedIcon from "@mui/icons-material/MinimizeOutlined";
import OpenInFullOutlinedIcon from "@mui/icons-material/OpenInFullOutlined";
import CloseFullscreenOutlinedIcon from "@mui/icons-material/CloseFullscreenOutlined";
import borders from "assets/theme/base/borders";
import { useTheme } from "@emotion/react";

const Newcompose = ({ open, handleClose }) => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const theme = useTheme();
  //   const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const handleFullScreen = () => {
    setIsFullscreen((prev) => !prev);
  };

  return (
    <Modal
      open={open}
      onClose={(e, reason) => {
        if (reason === "backdropClick") {
          e.stopPropagation();
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      BackdropProps={{
        invisible: true,
      }}
      disableEscapeKeyDown
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: isFullscreen
            ? isExtraSmallScreen
              ? "100%"
              : isSmallScreen
              ? "100%"
              : isMediumScreen
              ? "100%"
              : isLargeScreen
              ? "100%"
              : "500px"
            : isExtraSmallScreen
            ? "90%"
            : isSmallScreen
            ? "80%"
            : isMediumScreen
            ? "70%"
            : isLargeScreen
            ? "500px"
            : "500px",
          height: isFullscreen
            ? isExtraSmallScreen
              ? "100%"
              : isSmallScreen
              ? "100%"
              : isMediumScreen
              ? "100%"
              : isLargeScreen
              ? "100%"
              : "580px"
            : isExtraSmallScreen
            ? "70%"
            : isSmallScreen
            ? "70%"
            : isMediumScreen
            ? "60%"
            : isLargeScreen
            ? "500px"
            : "500px",
          overflow: "auto",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          position: "relative",
        }}
      >
        <MDBox>
          <Grid
            container
            sx={{
              backgroundColor: "#424242",
              position: "absolute",
              right: 1,
              top: 1,
              color: "#ffff",
            }}
          >
            <Grid item xs={9} sx={{ paddingLeft: 2 }}>
              New message
            </Grid>
            <Grid item xs={1}>
              <IconButton onClick={handleClose} sx={{ color: "#Ffff" }}>
                <MinimizeOutlinedIcon />
              </IconButton>
            </Grid>
            <Grid item xs={1}>
              <IconButton onClick={handleFullScreen} sx={{ color: "#ffffff" }}>
                {isFullscreen ? (
                  isExtraSmallScreen ? (
                    <CloseFullscreenOutlinedIcon />
                  ) : isMediumScreen ? (
                    <CloseFullscreenOutlinedIcon />
                  ) : (
                    <CloseFullscreenOutlinedIcon />
                  )
                ) : (
                  <OpenInFullOutlinedIcon />
                )}
              </IconButton>
            </Grid>{" "}
            <Grid item xs={1}>
              <IconButton onClick={handleClose} sx={{ color: "#Ffff" }}>
                <CloseIcon />
              </IconButton>
            </Grid>{" "}
          </Grid>
        </MDBox>

        <MDBox>
          <MDBox pt={3} pb={5}>
            <Grid container spacing={2} sx={{ borderBottom: 1 }}>
              <Grid item xs={12}>
                <TextField
                  placeholder="Recipients"
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ borderBottom: 1 }}>
              <Grid item xs={12}>
                <TextField
                  placeholder="Subject"
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ minHeight: 200 }}>
              <Grid item xs={12}>
                <TextField
                  placeholder="Message"
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              container
              // border={2}
              sx={{ position: "absolute", bottom: 5, right: 0, paddingLeft: 3 }}
            >
              <Grid display={"flex"}>
                <MDBox
                  sx={{
                    background: "#5478D9",
                    borderRadius: "8px",
                    display: "flex",
                  }}
                >
                  {" "}
                  <MDButton
                    onClick={() => {
                      handleClose;
                    }}
                    type="submit"
                    sx={{ color: " white " }}
                  >
                    Submit
                  </MDButton>
                </MDBox>
                <Grid container spacing={1}>
                  <Grid item>
                    <MDBox
                      component="button"
                      style={{
                        cursor: "pointer",
                        border: "none",
                        background: "none",
                        padding: 0,
                        fontSize: "24px",
                        paddingTop: "4px",
                      }}
                    >
                      <TextFormatOutlinedIcon />
                    </MDBox>
                  </Grid>
                  <Grid item>
                    <MDBox
                      component="button"
                      style={{
                        cursor: "pointer",
                        border: "none",
                        background: "none",
                        padding: 0,
                        fontSize: "24px",
                        paddingTop: "4px",
                      }}
                    >
                      <AttachFileOutlinedIcon />
                    </MDBox>
                  </Grid>
                  <Grid item>
                    <MDBox
                      component="button"
                      style={{
                        cursor: "pointer",
                        border: "none",
                        background: "none",
                        padding: 0,
                        fontSize: "24px",
                        paddingTop: "4px",
                      }}
                    >
                      <InsertLinkOutlinedIcon />
                    </MDBox>
                  </Grid>
                  <Grid item>
                    <MDBox
                      component="button"
                      style={{
                        cursor: "pointer",
                        border: "none",
                        background: "none",
                        padding: 0,
                        fontSize: "24px",
                        paddingTop: "4px",
                      }}
                    >
                      <InsertPhotoOutlinedIcon />
                    </MDBox>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* <button type="submit">Submit</button> */}
          </MDBox>
        </MDBox>
      </Box>
    </Modal>
  );
};
Newcompose.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
export default Newcompose;
