// BasicUI.js
import React from "react";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import logo from "../../../assets/logo/Logo.png";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import useBasicLogic from "./logicIndex";

function Basic() {
  const {
    rememberMe,
    email,
    emailError,
    password,
    passwordError,
    showPassword,
    handleClickShowPassword,
    handleCheckboxChange,
    handleApi,
    setEmail,
    setPassword,
    loading,
  } = useBasicLogic();

  return (
    <BasicLayout image={bgImage}>
      <Card style={{ margin: "auto", top: "50%" }}>
        <MDBox bgColor="transparent" borderRadius="lg" p={2} textAlign="center" width="100%">
          <img src={logo} alt="Logo" />
          <MDTypography variant="h4" fontWeight="medium" color="black" mt={1}>
            Login to Account
          </MDTypography>
          <MDTypography
            variant="h6"
            fontWeight="bold"
            color="black"
            mt={1}
            sx={{ color: "#008080" }}
          >
            Please enter your email and password to continue
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDTypography style={{ color: "#202224", fontWeight: "bold", fontSize: "18px" }}>
                Email address:
              </MDTypography>
              <MDInput
                type="email"
                value={email}
                placeholder="esteban_schiller@gmail.com"
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
              />
              <p style={{ fontSize: "12px", color: "red", marginTop: "1px" }}>{emailError}</p>
            </MDBox>
            <MDBox mb={2}>
              <MDTypography style={{ color: "#202224", fontWeight: "bold", fontSize: "18px" }}>
                Password
              </MDTypography>
              <MDInput
                type={showPassword ? "text" : "password"}
                value={password}
                placeholder="..........."
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <p style={{ color: "red", marginTop: "3px", fontSize: "12px" }}>{passwordError}</p>
            </MDBox>
            <MDBox display="flex" alignItems="center" justifyContent="space-between">
              <MDBox display="flex" alignItems="center">
                <Checkbox checked={rememberMe} onChange={handleCheckboxChange} color="primary" />
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="#202224"
                  onClick={handleCheckboxChange}
                  sx={{ cursor: "pointer", userSelect: "none" }}
                >
                  &nbsp;Remember Password
                </MDTypography>
              </MDBox>
              <MDTypography
                variant="button"
                fontWeight="bold"
                component={Link}
                to="/ResetThePassword"
                sx={{
                  cursor: "pointer",
                  userSelect: "none",
                  textDecoration: "underline",
                  color: "#339999",
                }}
              >
                Forget password?
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="dark"
                color="dark"
                fullWidth
                onClick={(e) => handleApi(e)}
                sx={{
                  textTransform: "none",
                  background: "#7F9AE3",
                  color: "#FFFFFF",
                  borderRadius: "50px",
                  "&:hover": { background: "#7F9AE3" },
                }}
              >
                {loading ? "Loading ..." : "Sign Up"}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
