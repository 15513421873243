/**
 * Get an item from local storage
 * @param {string} key - The key of the item to retrieve
 * @returns {any} - The parsed value from local storage, or null if not found
 */
export const getItem = (key) => {
  try {
    const item = window.localStorage.getItem(key);
    // console.log("item", item);

    return item ? JSON.parse(item) : null;
  } catch (error) {
    console.error(`Error getting localStorage key “${key}”`, error);
    return null;
  }
};

/**
 * Set an item in local storage
 * @param {string} key - The key of the item to set
 * @param {any} value - The value to store, will be stringified
 */
export const setItem = (key, value) => {
  try {
    const valueToStore = JSON.stringify(value);
    window.localStorage.setItem(key, valueToStore);
    console.log("storing tokken");
  } catch (error) {
    console.error(`Error setting localStorage key “${key}”`, error);
  }
};

/**
 * Remove an item from local storage
 * @param {string} key - The key of the item to remove
 */
export const removeItem = (key) => {
  try {
    window.localStorage.removeItem(key);
  } catch (error) {
    console.error(`Error removing localStorage key “${key}”`, error);
  }
};

/**
 * Clear all items from local storage
 */
export const clearAll = () => {
  try {
    window.localStorage.clear();
  } catch (error) {
    console.error("Error clearing localStorage", error);
  }
};
