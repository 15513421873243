import React, { useEffect } from "react";
import { useState } from "react";
import MDTypography from "components/MDTypography";
import axios from "axios";
import { getData } from "services/request";
import { getItem } from "utils/localStorage";

// const [usersData, setUsersData] = useState(null);
const users = async () => {
  try {
    const token = getItem("token");
    console.log("Retrieved token:", token);

    if (!token) {
      throw new Error("Token is missing from local storage.");
    }

    console.log("Token being sent in the header:", token);

    // Set the correct header with the token value
    const response = await axios.get("/users?pageNo=1&pageSize=20", {
      headers: {
        "x-access-token": token,
      },
    });

    console.log("API response:", response.data);
    // setUsersData(response.data.items);
    // console.log("this is a reponse items : ", usersData);
  } catch (error) {
    console.error("Error occurred:", error.response?.data || error.message);
  }
};

// Call the users function
users();

const columns = [
  { Header: "Serial No", accessor: "SerialNo", align: "left", color: "red" },
  { Header: "Description", accessor: "Description", align: "left" },
  { Header: "Quantity", accessor: "Quantity", align: "center" },
  { Header: "Base Cost", accessor: "BaseCost", align: "center" },
  { Header: "Total Cost", accessor: "TotalCost", align: "center" },
];

const rows = [
  {
    SerialNo: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ color: "#9a8c8c" }}>
        1
      </MDTypography>
    ),
    Description: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ color: "#9a8c8c" }}>
        Himalaya Liv.52 Syrup
        {/* {n} */}
      </MDTypography>
    ),
    Quantity: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ color: "#9a8c8c" }}>
        2
      </MDTypography>
    ),
    BaseCost: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ color: "#9a8c8c" }}>
        $6
      </MDTypography>
    ),

    TotalCost: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ color: "#9a8c8c" }}>
        $10
      </MDTypography>
    ),
  },
  {
    SerialNo: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ color: "#9a8c8c" }}>
        1
      </MDTypography>
    ),
    Description: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ color: "#9a8c8c" }}>
        Himalaya Liv.52 Syrup
        {/* {n} */}
      </MDTypography>
    ),
    Quantity: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ color: "#9a8c8c" }}>
        2
      </MDTypography>
    ),
    BaseCost: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ color: "#9a8c8c" }}>
        $6
      </MDTypography>
    ),

    TotalCost: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ color: "#9a8c8c" }}>
        $10
      </MDTypography>
    ),
  },
  {
    SerialNo: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ color: "#9a8c8c" }}>
        1
      </MDTypography>
    ),
    Description: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ color: "#9a8c8c" }}>
        Himalaya Liv.52 Syrup
        {/* {n} */}
      </MDTypography>
    ),
    Quantity: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ color: "#9a8c8c" }}>
        2
      </MDTypography>
    ),
    BaseCost: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ color: "#9a8c8c" }}>
        $6
      </MDTypography>
    ),

    TotalCost: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ color: "#9a8c8c" }}>
        $10
      </MDTypography>
    ),
  },
  {
    SerialNo: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ color: "#9a8c8c" }}>
        1
      </MDTypography>
    ),
    Description: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ color: "#9a8c8c" }}>
        Himalaya Liv.52 Syrup
        {/* {n} */}
      </MDTypography>
    ),
    Quantity: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ color: "#9a8c8c" }}>
        2
      </MDTypography>
    ),
    BaseCost: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ color: "#9a8c8c" }}>
        $6
      </MDTypography>
    ),

    TotalCost: (
      <MDTypography variant="caption" color="text" fontWeight="medium" style={{ color: "#9a8c8c" }}>
        $10
      </MDTypography>
    ),
  },
];

export { columns, rows };
