import { useState } from "react";

import { Link } from "react-router-dom";

import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

import { Checkbox } from "@mui/material";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { Translate } from "@mui/icons-material";

function ChangePassword() {
  const [rememberMe, setRememberMe] = useState(false);

  const handleCheckboxChange = (event) => {
    setRememberMe(event.target.checked);
  };

  return (
    <BasicLayout image={bgImage}>
      <Card
        style={{
          // border: "2px solid red",
          margin: "auto",
          width: "25vw",
          top: "50%",
          transform: "Translate-(-50%, -50%)",
        }}
      >
        <MDBox
          // variant="primary"
          bgColor="transparent"
          borderRadius="lg"
          // coloredShadow="info"
          // mx={2}
          mt={3}
          p={2}
          mb={1}
          textAlign="center"
          // border="2px solid red"
          width="100%"

          // border="2px solid green"
        >
          <MDTypography variant="h4" fontWeight="medium" color="black" mt={1}>
            Create New Password
          </MDTypography>
          <MDTypography
            variant="h6"
            fontWeight="light"
            color="black"
            mt={1}
            sx={{ color: "#008080" }}
          >
            your new password must be different from the new password
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput type="password" label="Password" fullWidth />
              <MDTypography
                variant="h6"
                fontWeight="light"
                color="black"
                mt={1}
                mb={2}
                sx={{ color: "#21030280" }}
              >
                Must be atleast 8 characters
              </MDTypography>
            </MDBox>

            <MDBox mb={2}>
              <MDInput type="password" label="Confirm Password" fullWidth />
              <MDTypography
                variant="h6"
                fontWeight="light"
                color="black"
                mt={1}
                mb={2}
                sx={{ color: "#21030280" }}
              >
                Both Passwords must matchs
              </MDTypography>
            </MDBox>

            <MDBox mt={4} mb={1}>
              <MDButton
                variant="dark"
                color="dark"
                fullWidth
                component={Link}
                to="../../dashboard"
                sx={{
                  background:
                    // "linear-gradient(90deg, rgba(135, 11, 8, 0.84) 0%, rgba(33, 3, 2, 0.84) 100%)",
                    "#FF774C",

                  color: "#ffffff",
                  borderRadius: "50px",
                  "&:hover": {
                    background:
                      "linear-gradient(90deg, rgba(135, 11, 8, 0.84) 0%, rgba(33, 3, 2, 0.84) 100%)",
                  },
                }}
              >
                Change password
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default ChangePassword;
