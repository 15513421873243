import axios from "axios";
import { getItem } from "../utils/localStorage";

const api = axios.create({
  baseURL: "http://192.168.1.28:3000/api",
  timeout: 10000,
});

api.interceptors.request.use(
  (config) => {
    const token = getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // Handle request error here
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Handle errors
    if (error.response) {
      // Server responded with a status other than 2xx
      if (error.response.status === 401) {
        // Handle unauthorized errors (e.g., redirect to login)
        console.log("Unauthorized, redirecting to login...");
        window.location.href = "/login";
      }
      // You can handle other status codes here
    } else if (error.request) {
      // Request was made, but no response was received
      console.error("No response received:", error.request);
    } else {
      // Something else happened
      console.error("Error:", error.message);
    }
    return Promise.reject(error);
  }
);

export default api;
