import React, { useEffect, useState } from "react";
import { Modal, Box, Typography, Grid, IconButton } from "@mui/material";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { Card } from "@mui/material";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import CloseIcon from "@mui/icons-material/Close";
import Orderdetials from "layouts/Order-layout/View-Order";
import { Height } from "@mui/icons-material";
import DataTable from "interface/Tables/DataTable";
import { useNavigate } from "react-router-dom";
import pic from "assets/flags/france.png";

const ViewOrder = ({ open, handleClose }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "200px", md: "500px", sm: "500px", xl: "500px" },
    height: "500px",
    overflow: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    position: "relative",
  };
  const [medicineName, setMedicineName] = useState("");
  const [dosage, setDosage] = useState("");
  const [manufacturer, setManufacturer] = useState("");
  const handleSave = (e) => {
    e.preventDefault();
    if (!medicineName || !dosage || !manufacturer) {
      alert("Please fill out all fields.");
    } else {
      console.log("Form Data:", { medicineName, dosage, manufacturer });
      handleClose();
    }
  };
  const navigate = useNavigate();

  // Step 1: Define a state for the status and its corresponding color
  const [status, setStatus] = useState("Processing");
  const [statusColor, setStatusColor] = useState("#E46A11"); // Default color for Processing
  const [statusBackgroundColor, setStatusBackgroundColor] = useState("#FFFFFF"); // Default background color

  // Step 2: Function to change the status and color
  const changeStatus = (newStatus) => {
    setStatus(newStatus);
    // Set the color based on the status
    if (newStatus === "Cancelled") {
      setStatusColor("#F04438"); // Red for Cancelled
      setStatusBackgroundColor("#FEEDEC");
    } else if (newStatus === "Shipped") {
      setStatusColor("#13B2E4"); // Blue for Shipped
      setStatusBackgroundColor("#FFFFFF");
    } else if (newStatus === "Delivered") {
      setStatusColor("#2ecc71"); // Green for Delivered
      setStatusBackgroundColor("#E7F4EE");
    } else {
      setStatusColor("#0D894F"); // Default color for Processing
      setStatusBackgroundColor("#FFFFFF");
    }
  };

  // Step 3: Optionally, use useEffect to automatically change status after 3 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      changeStatus("Shipped"); // Change to "Shipped" after 3 seconds
    }, 3000); // Change after 3 seconds

    return () => clearTimeout(timer); // Cleanup on component unmount
  }, []);

  // Step 4: Optionally, you can add more timeouts or conditions to update the status
  useEffect(() => {
    const timer2 = setTimeout(() => {
      changeStatus("Delivered"); // Change to "Delivered" after 6 seconds
    }, 6000);

    return () => clearTimeout(timer2);
  }, []);
  const columns = [
    { Header: "Medicine Name", accessor: "Medicine" },
    { Header: "Piece", accessor: "Piece" },
    { Header: "Qunity", accessor: "Qunity" },
  ];

  const orderlistData = [
    {
      Medicine: (
        <Grid
          item
          xs={12}
          container
          spacing={2}
          paddingTop={2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={pic} alt="hlo" style={{ borderRadius: "50%", margin: "0 10px" }} />
          <span>Himalaya Liv.52</span>
        </Grid>
      ),
      Piece: "$690.00",
      Qunity: "10",
    },
    {
      Medicine: (
        <Grid
          item
          xs={12}
          container
          spacing={2}
          paddingTop={2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={pic} alt="hlo" style={{ borderRadius: "50%", margin: "0 10px" }} />
          <span>Himalaya Liv.52</span>
        </Grid>
      ),
      Piece: "$690.00",
      Qunity: "10",
    },
    {
      Medicine: (
        <Grid
          item
          xs={12}
          container
          spacing={2}
          paddingTop={2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={pic} alt="hlo" style={{ borderRadius: "50%", margin: "0 10px" }} />
          <span>Himalaya Liv.52</span>
        </Grid>
      ),
      Piece: "$690.00",
      Qunity: "10",
    },
    {
      Medicine: (
        <Grid
          item
          xs={12}
          container
          spacing={2}
          paddingTop={2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={pic} alt="hlo" style={{ borderRadius: "50%", margin: "0 10px" }} />
          <span>Himalaya Liv.52</span>
        </Grid>
      ),
      Piece: "$690.00",
      Qunity: "10",
    },
  ];
  return (
    <Modal
      open={open}
      onClose={(e, reason) => {
        if (reason === "backdropClick") {
          e.stopPropagation();
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      BackdropProps={{
        invisible: true,
      }}
      disableEscapeKeyDown
    >
      <Box sx={style}>
        <MDBox sx={{ padding: 1 }}>
          {" "}
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
              zIndex: 10,
            }}
          >
            <CloseIcon />
          </IconButton>
        </MDBox>

        <Card>
          <MDBox sx={{ padding: "15px" }}>
            <MDTypography
              sx={{
                fontSize: { xs: "18px", sm: "22px", md: "24px" },
                fontWeight: "bold",
                color: "#304F5E",
                fontfamily: "Times, serif",
              }}
            >
              Delivery Details
            </MDTypography>

            <MDBox
              sx={{
                display: "flex",
                gap: "2px",
                textAlign: "center",
                pt: { xs: "10px", sm: "15px", md: "20px" },
                flexDirection: { xs: "column", sm: "row" },
              }}
            >
              <MDTypography
                sx={{
                  fontSize: { xs: "16px", sm: "18px", md: "20.48px" },
                  fontWeight: "bold",
                  color: "#161616",
                }}
              >
                Address :-
              </MDTypography>
              <h6
                style={{
                  padding: "5px",
                  fontSize: "14px",
                  color: "#000000",
                }}
              >
                089 Kutch Green Apt. 448
              </h6>
            </MDBox>

            <MDBox
              sx={{
                display: "flex",
                gap: "2px",
                textAlign: "center",
                flexDirection: { xs: "column", sm: "row" },
              }}
            >
              <MDTypography
                sx={{
                  fontSize: { xs: "14px", sm: "18px", md: "20.48px" },
                  fontWeight: "bold",
                  color: "#161616",
                }}
              >
                Date/ Time :-
              </MDTypography>
              <h6
                style={{
                  padding: { xs: "0", md: "5" },
                  fontSize: { xs: "8px", sm: "12px", md: "14px" },
                  color: "#000000",
                }}
              >
                4/5/2022
              </h6>
            </MDBox>

            <MDBox
              sx={{
                display: "flex",
                gap: "2px",
                textAlign: "center",
                flexDirection: { xs: "column", sm: "row" },
              }}
            >
              <MDTypography
                sx={{
                  fontSize: { xs: "16px", sm: "18px", md: "20.48px" },
                  fontWeight: "bold",
                  color: "#161616",
                }}
              >
                Status :-
              </MDTypography>
              <h6
                style={{
                  padding: "5px",
                  fontSize: "14px",
                  color: "#000000",
                }}
              >
                Processing
              </h6>
            </MDBox>
          </MDBox>
        </Card>

        <MDBox>
          {/* <DashboardNavbar /> */}
          <Card sx={{ marginTop: "20px" }}>
            <MDBox p={3}>
              <MDBox>
                <MDTypography variant="h3" color="dark" style={{ fontSize: "24px" }}>
                  View Order Detail
                </MDTypography>
                {/* <Ordernav /> */}
              </MDBox>
              <Grid container>
                <Grid item xs={12}>
                  <MDBox pt={3} style={{ height: "100%" }}>
                    <DataTable
                      // rows={rows}
                      // columns={columns}
                      entriesPerPage={{ defaultValue: 3, entries: [5, 10, 15] }}
                      // pageSizeOptions={[5, 10]}
                      checkboxSelection
                      showTotalEntries={true}
                      table={{ columns, rows: orderlistData }}
                      pagination={{ variant: "gradient", color: "white" }}
                      isSorted={true}
                      // noEndBorder={true}
                      sx={{ border: 0 }}
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          </Card>
        </MDBox>
      </Box>
    </Modal>
  );
};
ViewOrder.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
export default ViewOrder;
