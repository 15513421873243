import React, { useState } from "react";
import PropTypes from "prop-types";
import logo from "../../../assets/logo/Logo.png";
import axios from "axios";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { getItem, removeItem } from "utils/localStorage"; // Import removeItem function

function UnBlockUser({ onBlockStatusChange, blockid }) {
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  console.log("inside unblock component", blockid);

  const handleBlockUser = async () => {
    try {
      const userId = getItem("userId");
      // const unblockUserId = getItem("blockUserId");

      if (!userId || !blockid) {
        throw new Error("User ID or Unblock User ID is missing.");
      }

      const response = await axios.post(`http://192.168.1.27:3000/api/auths/unblock`, {
        userId,
        unblockUserId: blockid,
      });

      // Successfully unblocked the user, remove unblockUserId from local storage
      // removeItem("blockUserId");

      setMessage("User has been successfully unblocked.");
      console.log("Unblocked user response:", response.data);
      onBlockStatusChange(false);
    } catch (error) {
      setError("Error unblocking the user: " + (error.response?.data || error.message));
    }
  };

  return (
    <Card style={{ width: "30vw" }}>
      <MDBox
        bgColor="transparent"
        borderRadius="lg"
        mt={3}
        p={2}
        mb={1}
        textAlign="center"
        width="100%"
      >
        <img src={logo} alt="logo" />
        <MDTypography variant="h4" fontWeight="medium" color="black" mt={1}>
          Unblock User
        </MDTypography>
        <MDTypography
          variant="h6"
          fontWeight="light"
          color="black"
          mt={1}
          sx={{ color: "#21030280" }}
        >
          Clicking the proceed button will unblock this user. Proceed?
        </MDTypography>
      </MDBox>
      <MDBox pt={4} pb={3} px={3}>
        <MDBox component="form">
          <MDBox mt={6} mb={1}>
            <MDButton
              variant="gradient"
              color="info"
              fullWidth
              onClick={handleBlockUser}
              sx={{
                background:
                  "linear-gradient(90deg, rgba(135, 11, 8, 0.84) 0%, rgba(33, 3, 2, 0.84) 100%)",
                color: "#ffffff",
                borderRadius: "50px",
                "&:hover": {
                  background:
                    "linear-gradient(90deg, rgba(135, 11, 8, 0.84) 0%, rgba(33, 3, 2, 0.84) 100%)",
                },
              }}
            >
              Proceed
            </MDButton>
            {error && <div className="text-red-500 text-center">{error}</div>}
            {message && <div className="text-green-500 text-center">{message}</div>}
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

UnBlockUser.propTypes = {
  onBlockStatusChange: PropTypes.func.isRequired,
  blockid: PropTypes.string,
};

export default UnBlockUser;
