/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import TimelineItem from "interface/Timeline/TimelineItem";
import { useEffect } from "react";
import { postData } from "services/request";

function RecentActivities() {
  // useEffect(() => {
  //   const block = async (e) => {
  //     const userId = getItem("userId");
  //     // setLoading(true);
  //     // setError(null);

  //     try {
  //       const response = await postData("/auths/block", {
  //         userId: userId,
  //       });
  //       console.log(response.data.timeSpentByDay);
  //     } catch (error) {
  //       // setError("Failed to send password reset link. Please try again.");
  //       console.log(error);
  //     } finally {
  //       // setLoading(false);
  //     }
  //   };
  //   block();
  // }, []);

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={3} px={3}>
        <MDTypography variant="h6" fontWeight="medium">
          Recent Activities
        </MDTypography>
        <MDBox mt={0} mb={2}></MDBox>
      </MDBox>
      <MDBox p={2}>
        <TimelineItem
          color="success"
          // icon="notifications"
          title="Agent Rose"
          p="Message:  Please, kindly do take a snap-shot of the card."
        />
        <TimelineItem
          color="error"
          // icon="inventory_2"
          title="GOGE Team"
          p="Alert:  New card added this week check em out."
        />
        <TimelineItem
          color="info"
          // icon="shopping_cart"
          title="Halloween Promo"
          p="Promotion:  Get 13% the price when you exchange bitcoin to cash."
        />
        <TimelineItem
          color="warning"
          // icon="payment"
          title="GOGO TEAM"
          p="Alert:  Sorry to see you go. You just unsubscribe from the newsletter."
          lastItem
        />
      </MDBox>
    </Card>
  );
}

export default RecentActivities;
