import { useEffect, useState } from "react";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "interface/Tables/DataTable";
import DashboardLayout from "interface/LayoutContainers/DashboardLayout";
import DashboardNavbar from "interface/Navbars/DashboardNavbar";
import UserProfile from "layouts/profile/userprofile";
import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router-dom";
import { Menu } from "@mui/material";
import pic from "assets/flags/france.png";
import MenuItem from "@mui/material/MenuItem";
import { getItem } from "utils/localStorage";
import { getData } from "services/request";
import MDButton from "components/MDButton";
// icons
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { responsiveFontSizes } from "@mui/material";
import { CheckBox, WidthFull } from "@mui/icons-material";
import MDInput from "components/MDInput";
import { Link } from "react-router-dom";
import Editmedicinepopup from "interface/Edit-medicine";
import Medicinedetail from "../Medicinedetail";
import Editmainmedicine from "interface/Editmainmedicine";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

// import Swal from "sweetalert2";

function MedicineList() {
  // const [usersData, setUsersData] = useState([]);
  const [showUserProfile, setShowUserProfile] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedName, setSelectedName] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const navigate = useNavigate();
  // delete popup
  const Delete = () => {
    Swal.fire({
      title: "Are You Sure?",
      text: "This event data will be removed permanently.",
      icon: "error",
      showCancelButton: true,
      confirmButtonText: "Yes Delete",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#9FEFAB",
      cancelButtonColor: "#E8D0D0",
      allowOutsideClick: false, // Prevents clicking outside to close
      allowEscapeKey: false, // Prevents pressing Escape to close
      customClass: {
        confirmButton: "custom-confirm-button", // Apply custom confirm button style
        cancelButton: "custom-cancel-button", // Apply custom cancel button style
      },
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/MedicineList");
      }
    });
  };
  const addCustomButtonStyles = () => {
    const style = document.createElement("style");
    style.innerHTML = `
      .custom-confirm-button {
        color: #000000 ; 
      }
      .custom-cancel-button {
        color: #000000 ; 
      }
    `;
    document.head.appendChild(style);
  };
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);
  const [modalOpenview, setModalOpenview] = useState(false);

  const handleOpenview = () => setModalOpenview(true);
  const handleCloseview = () => setModalOpenview(false);

  // Call this function once to add the styles globally
  addCustomButtonStyles();
  // nnewwww
  const [menutag, setMenutag] = useState(null);
  const openMenutag = ({ currentTarget }) => setMenutag(currentTarget);
  const closeMenutag = () => setMenutag(null);
  const renderMenutag = (
    <Menu
      id="simple-menu"
      anchorEl={menutag}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menutag)}
      onClose={closeMenutag}
    >
      <MenuItem onClick={closeMenutag}>
        <Link to="/MedicineList/manufacturerdata">
          <MDButton>Manufacturer</MDButton>
        </Link>
      </MenuItem>
      <MenuItem onClick={closeMenutag}>
        <MDButton onClick={handleOpenview}>view details</MDButton>
      </MenuItem>
      <MenuItem onClick={closeMenutag}>
        <MDButton onClick={handleOpen}>Edit Selected</MDButton>
      </MenuItem>
      <MenuItem onClick={closeMenutag}>
        <MDButton onClick={Delete}>Removes selected</MDButton>
      </MenuItem>
    </Menu>
  );

  const handleRowClick = (row) => {
    setSelectedRow(row.uniqueId);
    // navigate(/user-profile/${row.uniqueId});
    setSelectedName(row.name);
    console.log("this is a name of selected row", selectedName);
  };
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      {/* <MDButton>Alphabetically A - Z</MDButton>
      <MDButton>Alphabetically A - Z</MDButton> */}
      <MDTypography>Alphabetically A - Z</MDTypography>
      <MDTypography>Alphabetically Z - A</MDTypography>
    </Menu>
  );
  const columns = [
    { Header: "Image", accessor: "Image", color: "#202224" },
    { Header: "Name", accessor: "Name" },
    { Header: "Category ", accessor: "Category" },
    { Header: "Price ", accessor: "Price" },
    { Header: "Weight", accessor: "weight" },
    // { Header: "Stock", accessor: "Stock" },
    { Header: " Manufacturer Date ", accessor: "Manufacturer" },
    { Header: " Expire Date ", accessor: "Expire" },
    { Header: "Action", accessor: "Action" },
  ];
  const usersData = [
    {
      Image: <img src={pic} />,
      Name: "MED - 1008",
      Category: "Tablet",
      Price: "$20.55 USD",
      weight: "500mg",
      Manufacturer: "march,2024",
      Expire: "04 Sep 2019",
      Action: (
        <MDBox
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid #979797",
            padding: "6px",
            borderRadius: "10px",
            backgroundColor: "#f0f0f0",
            fontSize: "16px",
            position: "relative",
          }}
        >
          <MDBox style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <EditOutlinedIcon onClick={handleOpen} />
          </MDBox>
          <Divider
            style={{
              width: "2px",
              height: "24px",
              backgroundColor: "#979797",
              margin: "0 10px",
            }}
          />

          <MDBox style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <DeleteOutlinedIcon style={{ color: "#EF3826" }} onClick={Delete} />
          </MDBox>
        </MDBox>
      ),
    },
    {
      Image: <img src={pic} />,
      Name: "MED - 1008",
      Category: "Tablet",
      Price: "$20.55 USD",
      weight: "500mg",
      Manufacturer: "march,2024",
      Expire: "04 Sep 2019",
      Action: (
        <MDBox
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid #979797",
            padding: "6px",
            borderRadius: "10px",
            backgroundColor: "#f0f0f0",
            fontSize: "16px",
            position: "relative",
          }}
        >
          <MDBox style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <EditOutlinedIcon onClick={handleOpen} />
          </MDBox>
          <Divider
            style={{
              width: "2px",
              height: "24px",
              backgroundColor: "#979797",
              margin: "0 10px",
            }}
          />

          <MDBox style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <DeleteOutlinedIcon style={{ color: "#EF3826" }} onClick={Delete} />
          </MDBox>
        </MDBox>
      ),
    },
    {
      Image: <img src={pic} />,
      Name: "MED - 1008",
      Category: "Tablet",
      Price: "$20.55 USD",
      weight: "500mg",
      Manufacturer: "march,2024",
      Expire: "04 Sep 2019",
      Action: (
        <MDBox
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid #979797",
            padding: "6px",
            borderRadius: "10px",
            backgroundColor: "#f0f0f0",
            fontSize: "16px",
            position: "relative",
          }}
        >
          <MDBox style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <EditOutlinedIcon onClick={handleOpen} />
          </MDBox>
          <Divider
            style={{
              width: "2px",
              height: "24px",
              backgroundColor: "#979797",
              margin: "0 10px",
            }}
          />

          <MDBox style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <DeleteOutlinedIcon style={{ color: "#EF3826" }} onClick={Delete} />
          </MDBox>
        </MDBox>
      ),
    },
  ];
  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <MDBox
        // mx={2}
        mt={0}
        p={4}
        xs={12}
        // md={6}
        lg={3}
        // px={2}
        // variant="gradient"
        bgColor="none"
        borderRadius="lg"
        coloredShadow="transparent"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: { xs: "start" },
          flexDirection: { xs: "column", md: "row", sm: "row", xl: "row" },
          gap: { xs: 1, sm: 2 },
        }}
      >
        <Editmainmedicine open={modalOpen} handleClose={handleClose} />
        <Medicinedetail open={modalOpenview} handleCloseview={handleCloseview} />
        <MDBox sx={{ textalign: { xs: "start" } }}>
          <MDTypography style={{ fontSize: "32px", color: "#304F5E", fontWeight: "bold" }}>
            Medicine List
          </MDTypography>
        </MDBox>
        <MDBox
          xs={12}
          md={6}
          lg={3}
          sx={{
            display: "flex",
            // justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* {renderMenu()}
          <MDButton
            size="large"
            disableRipple
            color="inherit"
            sx={navbarIconButton}
            aria-controls="notification-menu"
            aria-haspopup="true"
            variant="contained"
            onClick={handleOpenMenu}
          >
            <FilterAltOutlinedIcon style={{ color: "#000000" }}></FilterAltOutlinedIcon>
          </MDButton> */}
          <MDBox
            sx={{
              background: "#5478D9",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <AddIcon sx={{ color: " white ", marginLeft: "10px" }} />
            <Link to="/MedicineList/addmedicine">
              <MDButton sx={{ color: " white ", textTransform: "none" }}>Add Medicine</MDButton>
            </Link>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3} style={{ height: "100%", minHeight: "400px" }}>
                <DataTable
                  entriesPerPage={{ defaultValue: 4, entries: [5, 10, 15] }}
                  // canSearch={true}
                  showTotalEntries={true}
                  table={{ columns, rows: usersData }}
                  pagination={{ variant: "gradient", color: "white" }}
                  isSorted={true}
                  noEndBorder={true}
                  onRowClick={handleRowClick}
                />
              </MDBox>
              <MDBox pt={1} style={{ height: "auto" }}>
                {showUserProfile && (
                  <UserProfile uniqueId={selectedRow} onClose={() => setShowUserProfile(false)} />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
export default MedicineList;
