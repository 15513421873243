/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "interface/LayoutContainers/DashboardLayout";
import DashboardNavbar from "interface/Navbars/DashboardNavbar";
// import InboxSide from "./InboxSide";
import { Margin } from "@mui/icons-material";
import borders from "assets/theme/base/borders";
import { Card, Hidden } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Checkbox from "@mui/material/Checkbox";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import MDTypography from "components/MDTypography";
import InboxSide from "../InboxSide";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import MDButton from "components/MDButton";
import DataTable from "interface/Tables/DataTable";
import { useNavigate } from "react-router-dom";

function Spamdata() {
  const Delete = () => {
    Swal.fire({
      title: "Are You Sure?",
      text: "This event data will be removed permanently.",
      icon: "error",
      showCancelButton: true,
      confirmButtonText: "Yes Delete",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#9FEFAB",
      cancelButtonColor: "#E8D0D0",
      allowOutsideClick: false,
      allowEscapeKey: false,
      customClass: {
        confirmButton: "custom-confirm-button", // Apply custom confirm button style
        cancelButton: "custom-cancel-button", // Apply custom cancel button style
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // navigate("/MedicineList");
      }
    });
  };
  const data = [
    {
      name: "started",
      label: "Primary",
      Date: "10 Feb",
      description: "Our Bachelor of Commerce program is ACBSP-accredited.",
    },
    {
      name: "Another Person",
      label: "Secondary",
      Date: "10 Feb",
      description: "Another description here.",
    },
    {
      name: "Someone Else",
      label: "Tertiary",
      Date: "10 Feb",
      description: "More text goes here.",
    },
  ];
  const [search, setSearch] = useState("");
  // Columns for the DataTable
  const columns = [
    { Header: "", accessor: "star", width: "5%" },
    { Header: "", accessor: "name", width: "20%" },
    { Header: "", accessor: "description", width: "40%" },
    { Header: "", accessor: "time", width: "15%" },
    { Header: "", accessor: "label", width: "15%" },
  ];
  // Rows for the DataTable
  const rows = data
    .filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))
    .map((item) => ({
      select: <Checkbox />,
      star: <StarBorderOutlinedIcon />,
      name: item.name,
      label: (
        <span>
          <MDButton onClick={Delete} style={{ fontSize: "12px" }}>
            <DeleteOutlineIcon />
          </MDButton>
        </span>
      ),
      description: item.description,
      time: item.Date,
    }));
  const navigate = useNavigate();

  const handleRowClick = () => {
    navigate("/inbox/Openmessage");
  };

  return (
    <DashboardLayout>
      <MDBox
        pt={4}
        pb={4}
        // variant="gradient"
        bgColor="none"
        borderRadius="lg"
        coloredShadow="transparent"
        sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
      >
        <MDBox>
          <MDTypography style={{ fontSize: "32px", fontWeight: "bold", color: "#304F5E" }}>
            Inbox
          </MDTypography>
        </MDBox>
      </MDBox>
      <Grid container spacing={3} sx={{ marginLeft: -4 }}>
        <Grid item xs={12} sm={3} md={3}>
          <InboxSide />
        </Grid>

        {/* Main Content */}
        <Grid item xs={12} sm={9} md={9}>
          <MDBox style={{ height: "100%", minHeight: "400px", width: "100%" }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Card>
                  <MDBox>
                    <Card>
                      <MDBox mx={1} mt={2} py={1}>
                        <MDBox sx={{ fontSize: "12px", display: "flex", justifyContent: "center" }}>
                          {" "}
                          Messages that have been in Spam for more than 30 days will be
                          automatically deleted.
                        </MDBox>
                        <DataTable
                          entriesPerPage={{ defaultValue: 6, entries: [5, 10, 15] }}
                          checkboxSelection
                          showTotalEntries={true}
                          table={{ columns, rows }}
                          pagination={{ variant: "gradient", color: "white" }}
                          isSorted={true}
                          sx={{ border: 0 }}
                          onRowClick={handleRowClick}
                        />
                      </MDBox>
                    </Card>
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default Spamdata;
