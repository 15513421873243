import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import logo from "../../../assets/logo/Logo.png";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { getItem } from "utils/localStorage";
import { postData } from "services/request";

function BlockUser({ onBlockStatusChange, blockid }) {
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [isBlocked, setIsBlocked] = useState(false);
  console.log("blockid this is ", blockid);
  useEffect(() => {
    const fetchBlockStatus = async () => {
      const userId = getItem("userId");

      if (!userId || !blockid) {
        setError("User ID or Blocked User ID is missing. Please try again.");
        return;
      }

      try {
        const { data } = await postData("/auths/blockUsers", { userId });
        setIsBlocked(data.isBlocked);
      } catch (err) {
        setError("Error fetching block status: " + (err.response?.data || err.message));
      }
    };

    fetchBlockStatus();
  }, [blockid]);

  const handleBlockUnblockUser = async () => {
    const userId = getItem("userId");

    if (!userId || !blockid) {
      setError("User ID or Blocked User ID is missing. Please try again.");
      return;
    }

    try {
      const endpoint = isBlocked ? "auths/unblock" : "auths/block";
      await postData(endpoint, { userId, blockUserId: blockid });

      setIsBlocked(!isBlocked);
      setMessage(`User has been successfully ${isBlocked ? "unblocked" : "blocked"}.`);
      onBlockStatusChange(!isBlocked);
    } catch (err) {
      setError("Error processing request: " + (err.response?.data || err.message));
    }
  };

  return (
    <Card style={{ width: "30vw" }}>
      <MDBox
        bgColor="transparent"
        borderRadius="lg"
        mt={3}
        p={2}
        mb={1}
        textAlign="center"
        width="100%"
      >
        <img src={logo} alt="logo" />
        <MDTypography variant="h4" fontWeight="medium" color="black" mt={1}>
          {isBlocked ? "Unblock User" : "Block User"}
        </MDTypography>
        <MDTypography
          variant="h6"
          fontWeight="light"
          color="black"
          mt={1}
          sx={{ color: "#21030280" }}
        >
          {`Clicking the proceed button will ${isBlocked ? "unblock" : "block"} this user.`}
        </MDTypography>
      </MDBox>
      <MDBox pt={4} pb={3} px={3}>
        <MDBox component="form">
          <MDBox mt={6} mb={1}>
            <MDButton
              variant="gradient"
              color="info"
              fullWidth
              onClick={handleBlockUnblockUser}
              sx={{
                background:
                  "linear-gradient(90deg, rgba(135, 11, 8, 0.84) 0%, rgba(33, 3, 2, 0.84) 100%)",
                color: "#ffffff",
                borderRadius: "50px",
                "&:hover": {
                  background:
                    "linear-gradient(90deg, rgba(135, 11, 8, 0.84) 0%, rgba(33, 3, 2, 0.84) 100%)",
                },
              }}
            >
              {isBlocked ? "Unblock" : "Block"}
            </MDButton>
            {error && <div className="text-red-500 text-center">{error}</div>}
            {message && <div className="text-green-500 text-center">{message}</div>}
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

BlockUser.propTypes = {
  onBlockStatusChange: PropTypes.func.isRequired,
  blockid: PropTypes.string,
};

export default BlockUser;
