import React from "react";
import { useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MDBox from "components/MDBox";

const MyLineChart = () => {
  const data = [
    { name: "5k", Sales: 1000 },
    { name: "10k", Sales: 1200 },
    { name: "15k", Sales: 1500 },
    { name: "20k", Sales: 1000 },
    { name: "25k", Sales: 750 },
    { name: "30k", Sales: 800 },
    { name: "35k", Sales: 1800 },
    { name: "40k", Sales: 1500 },
    { name: "45k", Sales: 1400 },
    { name: "50k", Sales: 1800 },
    { name: "55k", Sales: 1900 },
    { name: "60k", Sales: 2000 },
  ];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedOption, setSelectedOption] = useState("");
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <MDBox xs={12} md={6} lg={7}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          // width: "100%",
          maxWidth: "1200px",
          boxSizing: "border-box",
          backgroundColor: "#FFFFFF",
          padding: "30px 20px",
        }}
      >
        <div
          style={{
            fontWeight: "bold",
            color: "#304F5E",
            fontSize: "16px",
          }}
        >
          Sales Details
        </div>
        <MDBox>
          {/* <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" onClick={openMenu}>
            more_vert
          </Icon> */}

          <select
            id="options"
            value={selectedOption}
            onChange={handleChange}
            style={{ border: "1px solid #D5D5D5", padding: "10px 8px", fontWeight: "normal" }}
          >
            <option value="January">January</option>
            <option value="February">February</option>
            <option value="March">March</option>
            <option value="April">April</option>
            <option value="May">May</option>
            <option value="June">June</option>
            <option value="July">July</option>
            <option value="August">August</option>
            <option value="September">September</option>
            <option value="October">October</option>
            <option value="November">November</option>
            <option value="December">December</option>
          </select>
        </MDBox>
      </div>
      <div
        style={{
          // width: "100%",
          maxWidth: "1200px", // Restrict max width
          boxSizing: "border-box",
          backgroundColor: "#FFFFFF",
          padding: "30px 20px",
        }}
      >
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={data}>
            <CartesianGrid vertical={false} />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Line
              type="monotone"
              dataKey="Sales"
              stroke="#4CAF50"
              strokeWidth={2}
              dot={true}
              activeDot={true}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </MDBox>
  );
};

export default MyLineChart;
