import { useEffect, useState } from "react";
import React from "react";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "interface/Tables/DataTable";
import DashboardLayout from "interface/LayoutContainers/DashboardLayout";
import DashboardNavbar from "interface/Navbars/DashboardNavbar";
import UserProfile from "layouts/profile/userprofile";
import { useNavigate } from "react-router-dom";
import { Menu } from "@mui/material";
import Avatar from "@mui/material";
import pic from "assets/flags/france.png";
import MenuItem from "@mui/material/MenuItem";
import { getItem } from "utils/localStorage";
import { getData } from "services/request";
import MDButton from "components/MDButton";
import { Button, Box, Typography } from "@mui/material";
// icons
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { responsiveFontSizes } from "@mui/material";
import { CheckBox, WidthFull } from "@mui/icons-material";
import MDInput from "components/MDInput";
import { Action as NavigationType } from "@remix-run/router";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { red } from "@mui/material/colors";
import borders from "assets/theme/base/borders";
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import { Link } from "react-router-dom";
// import { useNavigate } from "react-router-dom";

function Addmedicine() {
  // const [usersData, setUsersData] = useState([]);
  const [showUserProfile, setShowUserProfile] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedName, setSelectedName] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    medicinename: "",
    category: "",
    stock: "",
    weight: "",
    price: "",
    date: "",
    expiredate: "",
    manufacturerdate: "",
    status: "",
    medicinedetails: "",
  });

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form data submitted:", formData);

    // Clear form after submission (optional)
    setFormData({
      medicinename: "",
      category: "",
      stock: "",
      weight: "",
      price: "",
      date: "",
      expiredate: "",
      manufacturerdate: "",
      status: "",
      medicinedetails: "",
    });
    navigate("/MedicineList");
  };
  const [isUploaded, setIsUploaded] = useState(false); // State to track if image is uploaded

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setIsUploaded(true);
    }
  };

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <MDBox
        p={4}
        xs={12}
        md={6}
        lg={3}
        // variant="gradient"
        bgColor="none"
        borderRadius="lg"
        coloredShadow="transparent"
        sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
      >
        <MDBox>
          <MDTypography style={{ fontSize: "32px", fontWeight: "bold", color: "#304F5E" }}>
            Add Medicine
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox xs={12} md={6} sm={4} lg={3}>
        <Card xs={12} md={6} lg={3}>
          <MDBox
            pt={3}
            pb={5}
            style={{ height: "100%", display: "flex", justifyContent: "center" }}
          >
            <form onSubmit={handleSubmit}>
              <MDBox
                pb={5}
                container
                // border={2}
                // pl={18}
                sx={{
                  display: "flex",
                  gap: "15px",
                  justifyContent: { xs: "center", md: "flex-start" },
                  flexDirection: { xs: "column", sm: "row", md: "row", xl: "row" },
                }}
              >
                <MDBox>
                  <MDBox>
                    <MDTypography
                      style={{
                        color: "#202224",
                        fontWeight: " bold ",
                        fontSize: "18px",
                        marginBottom: "10px",
                      }}
                    >
                      Medicine Name
                    </MDTypography>
                    <MDInput
                      style={{
                        // border: "1px solid red",
                        width: "250px",
                      }}
                      type="text"
                      name="medicinename"
                      placeholder="Example"
                      value={formData.medicinename}
                      onChange={handleChange}
                      required
                    />
                  </MDBox>
                </MDBox>
                <MDBox>
                  <MDBox>
                    <MDTypography
                      style={{
                        color: "#202224",
                        fontWeight: " bold ",
                        fontSize: "18px",
                        marginBottom: "10px",
                      }}
                    >
                      Category
                    </MDTypography>
                    <MDBox>
                      <select
                        name="category"
                        value={formData.category}
                        onChange={handleChange}
                        required
                        style={{
                          width: "250px",
                          padding: "12px 55px",
                          borderRadius: "5px",
                          // border: "1px solid red",
                          backgroundColor: "#FFFFFFE3",
                          border: "1px solid #00000045",
                        }}
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        <option value="Tablet">Tablet</option>
                        <option value="Syrup">Syrup</option>
                        <option value="Vitamin">Vitamin</option>
                        <option value="Inhealer">Inhealer</option>
                      </select>
                    </MDBox>
                  </MDBox>
                </MDBox>
                <MDBox>
                  <MDBox>
                    <MDTypography
                      style={{
                        color: "#202224",
                        fontWeight: " bold ",
                        fontSize: "18px",
                        marginBottom: "10px",
                      }}
                    >
                      Stock
                    </MDTypography>
                    <MDInput
                      style={{ width: "250px" }}
                      type="text"
                      name="stock"
                      // placeholder="Example"
                      value={formData.stock}
                      onChange={handleChange}
                      required
                    />
                  </MDBox>
                </MDBox>
              </MDBox>
              <MDBox
                pb={5}
                container
                // border={2}
                // pl={18}
                sx={{
                  display: "flex",
                  gap: "15px",
                  justifyContent: { xs: "center", md: "flex-start" },
                  flexDirection: { xs: "column", sm: "row", md: "row", xl: "row" },
                }}
              >
                <MDBox>
                  <MDBox>
                    <MDTypography
                      style={{
                        color: "#202224",
                        fontWeight: " bold ",
                        fontSize: "18px",
                        marginBottom: "10px",
                      }}
                    >
                      Weight
                    </MDTypography>
                    <MDInput
                      style={{ width: "250px" }}
                      type="text"
                      name="weight"
                      value={formData.weight}
                      onChange={handleChange}
                      required
                    />
                  </MDBox>
                </MDBox>
                <MDBox>
                  <MDBox>
                    <MDTypography
                      style={{
                        color: "#202224",
                        fontWeight: " bold ",
                        fontSize: "18px",
                        marginBottom: "10px",
                      }}
                    >
                      Price
                    </MDTypography>
                    <MDInput
                      style={{ width: "250px" }}
                      type="text"
                      name="price"
                      // placeholder="Example"
                      value={formData.price}
                      onChange={handleChange}
                      required
                    />
                  </MDBox>
                </MDBox>
                <MDBox>
                  <MDBox>
                    <MDTypography
                      style={{
                        color: "#202224",
                        fontWeight: " bold ",
                        fontSize: "18px",
                        marginBottom: "10px",
                      }}
                    >
                      Upload Image
                    </MDTypography>
                    <MDBox
                      sx={{
                        position: "relative",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        padding: "20px",
                        textAlign: "center",
                        width: "250px",
                        height: "40px",
                        backgroundColor: "#f8f8f8",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {isUploaded ? (
                        <Typography variant="body2" color="textSecondary">
                          Uploaded
                        </Typography>
                      ) : (
                        <Typography variant="body2" color="textSecondary">
                          No image
                        </Typography>
                      )}

                      <Button
                        // variant="contained"
                        component="label"
                        sx={{
                          position: "absolute",
                          top: "50%",
                          right: "0px",
                          transform: "translateY(-50%)",
                          padding: "10px",
                          // fontSize: "40px",
                        }}
                      >
                        <UploadOutlinedIcon style={{ color: "black" }} />
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleImageChange}
                          style={{ display: "none" }}
                        />
                      </Button>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>

              <MDBox
                pb={5}
                container
                // border={2}
                // pl={18}
                sx={{
                  display: "flex",
                  gap: "15px",
                  justifyContent: { xs: "center", md: "flex-start" },
                  flexDirection: { xs: "column", sm: "row", md: "row", xl: "row" },
                }}
              >
                <MDBox>
                  <MDBox>
                    <MDTypography
                      style={{
                        color: "#202224",
                        fontWeight: " bold ",
                        fontSize: "18px",
                        marginBottom: "10px",
                      }}
                    >
                      Date
                    </MDTypography>
                    <MDInput
                      style={{ width: "250px" }}
                      type="date"
                      name="date"
                      value={formData.date}
                      onChange={handleChange}
                      required
                    />
                  </MDBox>
                </MDBox>
                <MDBox>
                  <MDBox>
                    <MDTypography
                      style={{
                        color: "#202224",
                        fontWeight: " bold ",
                        fontSize: "18px",
                        marginBottom: "10px",
                      }}
                    >
                      Expire Date
                    </MDTypography>
                    <MDInput
                      style={{ width: "250px" }}
                      type="date"
                      name="expiredate"
                      value={formData.expiredate}
                      onChange={handleChange}
                      required
                    />
                  </MDBox>
                </MDBox>
                <MDBox>
                  <MDBox>
                    <MDTypography
                      style={{
                        color: "#202224",
                        fontWeight: " bold ",
                        fontSize: "18px",
                        marginBottom: "10px",
                      }}
                    >
                      Manufacturer Date
                    </MDTypography>
                    <MDInput
                      style={{ width: "250px" }}
                      type="date"
                      name="manufacturerdate"
                      value={formData.manufacturerdate}
                      onChange={handleChange}
                      required
                    />
                  </MDBox>
                </MDBox>
              </MDBox>
              <MDBox
                pb={5}
                container
                // border={2}
                // pl={18}
                sx={{
                  display: "flex",
                  gap: "15px",
                  justifyContent: { xs: "center", md: "flex-start" },
                  flexDirection: { xs: "column", sm: "row", md: "row", xl: "row" },
                }}
              >
                <MDBox>
                  <MDBox>
                    <MDTypography
                      style={{
                        color: "#202224",
                        fontWeight: " bold ",
                        fontSize: "18px",
                        marginBottom: "10px",
                      }}
                    >
                      Status
                    </MDTypography>
                    <MDBox>
                      <select
                        name="status"
                        value={formData.status}
                        onChange={handleChange}
                        required
                        style={{
                          padding: "12px 55px",
                          borderRadius: "5px",
                          width: "250px",
                          backgroundColor: "#FFFFFFE3",
                          border: "1px solid #00000045",
                        }}
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        <option value="active">Available</option>
                        <option value="inactive">Out of stock</option>
                      </select>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
              <MDBox>
                <MDBox
                  pb={5}
                  item
                  xs={12}
                  sx={{
                    justifyContent: { xs: "center", md: "flex-start" },
                  }}
                >
                  <MDBox>
                    <MDTypography
                      style={{
                        color: "#202224",
                        fontWeight: " bold ",
                        fontSize: "18px",
                        marginBottom: "10px",
                      }}
                    >
                      Medicine Details
                    </MDTypography>
                    <MDInput
                      type="text"
                      name="medicinedetails"
                      value={formData.medicinedetails}
                      onChange={handleChange}
                      required
                      fullWidth
                    />
                  </MDBox>
                </MDBox>

                <MDBox
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "flex-start", md: "flex-start" },
                  }}
                >
                  <MDBox
                    sx={{
                      background: "#5478D9",
                      borderRadius: "8px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <MDButton type="submit" sx={{ color: " white " }}>
                      Add Medicine
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>

              {/* <button type="submit">Submit</button> */}
            </form>
          </MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
export default Addmedicine;
