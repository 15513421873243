import { useState } from "react";
import React from "react";

// @mui material components
import Card from "@mui/material/Card";
import BasicLayout from "../components/BasicLayout";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import MDTypography from "components/MDTypography";
// Data

function BlockUserpopUp() {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleBtnClick = (index) => {
    setSelectedIndex(index);
  };

  return (
    <BasicLayout image={bgImage}>
      <Card style={{ width: "30vw" }}>
        <MDBox
          bgColor="transparent"
          borderRadius="lg"
          mt={3}
          p={2}
          mb={1}
          textAlign="center"
          width="100%"
        >
          <MDTypography variant="h4" fontWeight="medium" color="black" mt={1}>
            Are you sure you want to block this little nugget88 ? :D
          </MDTypography>
          <MDTypography
            variant="h6"
            fontWeight="light"
            color="black"
            mt={1}
            sx={{ color: "#21030280" }}
          >
            You will not be able to see deiscussion or comments they,ve created. They will not be
            notified that you blocked them.
          </MDTypography>
        </MDBox>
        <MDBox display="flex" justifyContent="center" flexDirection="column" p={2}>
          <MDButton
            onClick={() => handleBtnClick(0)}
            sx={{
              background:
                selectedIndex === 0
                  ? "linear-gradient(90deg, rgba(135, 11, 8, 0.84) 0%, rgba(33, 3, 2, 0.84) 100%)"
                  : "transparent",
              color: selectedIndex === 0 ? "#ffffff" : "black",
              borderRadius: "50px",
              marginRight: "10px",
              "&:hover": {
                background:
                  "linear-gradient(90deg, rgba(135, 11, 8, 0.84) 0%, rgba(33, 3, 2, 0.84) 100%)",
              },
            }}
          >
            Block User
          </MDButton>
          <MDButton
            onClick={() => handleBtnClick(1)}
            sx={{
              marginTop: "6px",
              background:
                selectedIndex === 1
                  ? "linear-gradient(90deg, rgba(135, 11, 8, 0.84) 0%, rgba(33, 3, 2, 0.84) 100%)"
                  : "transparent",
              color: selectedIndex === 1 ? "#ffffff" : "black",
              borderRadius: "50px",
              "&:hover": {
                background:
                  "linear-gradient(90deg, rgba(135, 11, 8, 0.84) 0%, rgba(33, 3, 2, 0.84) 100%)",
              },
            }}
          >
            Cancel
          </MDButton>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default BlockUserpopUp;
