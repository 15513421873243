import { Card, Divider, Grid, IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import TextFormatOutlinedIcon from "@mui/icons-material/TextFormatOutlined";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";
import MinimizeOutlinedIcon from "@mui/icons-material/MinimizeOutlined";
import OpenInFullOutlinedIcon from "@mui/icons-material/OpenInFullOutlined";
import CloseFullscreenOutlinedIcon from "@mui/icons-material/CloseFullscreenOutlined";
import DashboardLayout from "interface/LayoutContainers/DashboardLayout";
import InboxSide from "layouts/Inbox/Inboxlayout/Inbox/InboxSide";
import MDTypography from "components/MDTypography";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import ReportGmailerrorredOutlinedIcon from "@mui/icons-material/ReportGmailerrorredOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import MarkAsUnreadOutlinedIcon from "@mui/icons-material/MarkAsUnreadOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import DoubleArrowOutlinedIcon from "@mui/icons-material/DoubleArrowOutlined";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import ReplyIcon from "@mui/icons-material/Reply";
import pic from "assets/flags/france.png";
import ForwardIcon from "@mui/icons-material/Forward";
import { BorderBottom, Padding } from "@mui/icons-material";
import { useFlexLayout } from "react-table";
import borders from "assets/theme/base/borders";
import MDButton from "components/MDButton";
import colors from "assets/theme-dark/base/colors";

const Openmessage = () => {
  return (
    <DashboardLayout>
      <MDBox
        pb={4}
        pt={4}
        bgColor="none"
        borderRadius="lg"
        coloredShadow="transparent"
        sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
      >
        <MDBox>
          <MDTypography style={{ fontSize: "32px", fontWeight: "bold", color: "#304F5E" }}>
            Inbox
          </MDTypography>
        </MDBox>
      </MDBox>

      <Grid container spacing={3} sx={{ marginLeft: -4 }}>
        <Grid item xs={12} sm={3} md={3}>
          <InboxSide />
        </Grid>

        {/* Main Content */}
        <Grid item xs={12} sm={9} md={9}>
          <MDBox style={{ height: "100%", minHeight: "400px", width: "100%" }}>
            <Card
              style={{
                height: "100%",
                minHeight: "600px",
                width: "100%",
                paddingLeft: 3,
              }}
            >
              <MDBox sx={{ paddingLeft: 3.5 }}>
                <Grid
                  container
                  spacing={4}
                  sx={{
                    top: 0,
                    borderBottom: "1px solid gray",
                  }}
                >
                  <Grid item xs={12} md={8}>
                    <Grid container spacing={2}>
                      <Grid item>
                        <MDBox
                          component="button"
                          style={{
                            cursor: "pointer",
                            border: "none",
                            background: "none",
                            padding: 0,
                            fontSize: "24px",
                            paddingTop: "4px",
                          }}
                        >
                          <ArrowBackOutlinedIcon />
                        </MDBox>
                      </Grid>
                      <Grid item>
                        <Grid container spacing={1}>
                          <Grid item>
                            <MDBox
                              component="button"
                              style={{
                                cursor: "pointer",
                                border: "none",
                                background: "none",
                                padding: 0,
                                fontSize: "24px",
                                paddingTop: "4px",
                              }}
                            >
                              <ArchiveOutlinedIcon />
                            </MDBox>
                          </Grid>
                          <Grid item>
                            <MDBox
                              component="button"
                              style={{
                                cursor: "pointer",
                                border: "none",
                                background: "none",
                                padding: 0,
                                fontSize: "24px",
                                paddingTop: "4px",
                              }}
                            >
                              <ReportGmailerrorredOutlinedIcon />
                            </MDBox>
                          </Grid>
                          <Grid item>
                            <MDBox
                              component="button"
                              style={{
                                cursor: "pointer",
                                border: "none",
                                background: "none",
                                padding: 0,
                                fontSize: "24px",
                                paddingTop: "4px",
                              }}
                            >
                              <DeleteOutlineOutlinedIcon />
                            </MDBox>
                          </Grid>
                          <Grid item>
                            <MDBox style={{ display: "flex", gap: "2px" }}>
                              <Divider
                                style={{
                                  width: "2px",
                                  height: "30px",
                                  backgroundColor: "#979797",
                                  margin: "0 10px",
                                }}
                              />
                              <MDBox
                                component="button"
                                style={{
                                  cursor: "pointer",
                                  border: "none",
                                  background: "none",
                                  padding: 0,
                                  fontSize: "24px",
                                  paddingTop: "4px",
                                }}
                              >
                                <MarkAsUnreadOutlinedIcon />
                              </MDBox>
                            </MDBox>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <MDBox sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                      <MDTypography sx={{ color: "#0000008A" }}>1–50 of 2,619</MDTypography>
                      <MDBox
                        component="button"
                        style={{
                          cursor: "pointer",
                          border: "none",
                          background: "none",
                          padding: 0,
                          fontSize: "24px",
                        }}
                      >
                        <ArrowBackIosNewOutlinedIcon />
                      </MDBox>
                      <MDBox
                        component="button"
                        style={{
                          cursor: "pointer",
                          border: "none",
                          background: "none",
                          padding: 0,
                          fontSize: "24px",
                        }}
                      >
                        <ArrowForwardIosOutlinedIcon />
                      </MDBox>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              <Grid
                container
                spacing={2}
                pl={7}
                pb={4}
                pt={4}
                sx={{
                  backgroundColor: "transparent",
                }}
              >
                <Grid item xs={12} sm={10} md={11} container spacing={2} alignItems="center">
                  <Grid item>
                    <MDTypography variant="h3">Email Subject</MDTypography>
                  </Grid>
                  <Grid item>
                    <DoubleArrowOutlinedIcon sx={{ marginTop: 2 }} />
                  </Grid>
                  <Grid item>
                    <MDBox
                      component="button"
                      sx={{
                        border: "2px solid gray",
                        fontSize: "15px",
                        backgroundColor: "rgba(0,0,0,0.1)",
                        cursor: "pointer",
                        padding: "4px 8px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Inbox
                      <CloseOutlinedIcon sx={{ ml: 1, width: 20, paddingTop: 0.2 }} />
                    </MDBox>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={2}
                  md={1}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <MDBox
                    component="button"
                    sx={{
                      cursor: "pointer",
                      border: "none",
                      background: "none",
                      fontSize: "24px",
                    }}
                  >
                    <PrintOutlinedIcon />
                  </MDBox>
                </Grid>
              </Grid>

              <Grid container>
                <Grid itemxs={12} sm={7} md={8.5}>
                  <Grid container spacing={1}>
                    <Grid item>
                      <img src={pic} alt="hlo" style={{ borderRadius: "50%", margin: "0 10px" }} />
                    </Grid>
                    <Grid item sx={{ fontSize: 14 }}>
                      <MDTypography sx={{ fontSize: 14 }}>Michelle Rivera</MDTypography>
                      <MDBox sx={{ display: "flex" }}>
                        <MDTypography sx={{ fontSize: 12 }}>to me</MDTypography>
                        <ArrowDropDownIcon sx={{ fontSize: "20px", marginTop: "2px" }} />
                      </MDBox>
                    </Grid>
                    <Grid item>
                      <MDTypography sx={{ fontSize: 14 }}>
                        michelle.rivera@ example.com
                      </MDTypography>
                    </Grid>
                    <Grid item>
                      <MDTypography sx={{ fontSize: 14, borderBottom: "1px solid gray" }}>
                        {" "}
                        Unsubscribe
                      </MDTypography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={5} md={3.5}>
                  <MDBox sx={{ display: "flex", gap: 1 }}>
                    <MDTypography sx={{ fontSize: 12 }}>9:14 AM</MDTypography>
                    <MDTypography sx={{ fontSize: 12 }}>(8 hours ago)</MDTypography>
                    <MDBox
                      component="button"
                      style={{
                        cursor: "pointer",
                        border: "none",
                        background: "none",
                        padding: 0,
                        fontSize: "24px",
                        paddingTop: "4px",
                      }}
                    >
                      <StarBorderOutlinedIcon />
                    </MDBox>
                    <MDBox
                      component="button"
                      style={{
                        cursor: "pointer",
                        border: "none",
                        background: "none",
                        padding: 0,
                        fontSize: "24px",
                        paddingTop: "4px",
                      }}
                    >
                      <ReplyIcon />
                    </MDBox>
                  </MDBox>
                </Grid>
              </Grid>
              <MDBox sx={{ padding: 4 }}>
                <MDTypography sx={{ fontSize: "14px" }}>
                  Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et. Sunt qui esse
                  pariatur duis deserunt mollit dolore cillum minim tempor enim. Elit aute irure
                  tempor cupidatat incididunt sint deserunt ut voluptate aute id deserunt nisi.
                </MDTypography>
              </MDBox>
              <Grid
                container
                pt={10}
                pl={4}
                pb={5}
                spacing={2}
                sx={{ borderBottom: "1px solid gray", margin: 0, width: "100%" }}
              >
                <Grid item>
                  <MDBox
                    sx={{
                      border: "1px solid gray",
                      borderRadius: "10px",
                      fontSize: "14px",
                      color: "#1A73E8",
                      padding: "4px",
                    }}
                  >
                    Looking forward to it!
                  </MDBox>
                </Grid>
                <Grid item>
                  <MDBox
                    sx={{
                      border: "1px solid gray",
                      borderRadius: "10px",
                      fontSize: "14px",
                      color: "#1A73E8",
                      padding: "4px",
                    }}
                  >
                    We will be there!
                  </MDBox>
                </Grid>
                <Grid item>
                  <MDBox
                    sx={{
                      border: "1px solid gray",
                      borderRadius: "10px",
                      fontSize: "14px",
                      color: "#1A73E8",
                      padding: "4px",
                    }}
                  >
                    Thanks for the update!
                  </MDBox>
                </Grid>
              </Grid>
              <Grid container pl={5} pt={3} spacing={2}>
                {" "}
                <Grid item>
                  <MDBox
                    sx={{
                      border: "1px solid gray",
                      borderRadius: "5px",
                      padding: "2px 15px 2px 15px",
                      display: "flex",
                      gap: "10px",
                      color: "#0000008A",
                    }}
                  >
                    <ReplyIcon />
                    <MDTypography sx={{ fontSize: "14px", color: "#0000008A" }}>
                      {" "}
                      Reply
                    </MDTypography>
                  </MDBox>
                </Grid>
                <Grid item>
                  <MDBox
                    sx={{
                      border: "1px solid gray",
                      borderRadius: "5px",
                      padding: "2px 15px 2px 15px",
                      display: "flex",
                      gap: "10px",
                      color: "#0000008A",
                    }}
                  >
                    <ForwardIcon />
                    <MDTypography sx={{ fontSize: "14px", color: "#0000008A" }}>
                      {" "}
                      Forward
                    </MDTypography>
                  </MDBox>
                </Grid>
              </Grid>
            </Card>
          </MDBox>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default Openmessage;
