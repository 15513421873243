import { useEffect, useState } from "react";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "interface/Tables/DataTable";
import DashboardLayout from "interface/LayoutContainers/DashboardLayout";
import DashboardNavbar from "interface/Navbars/DashboardNavbar";
import UserProfile from "layouts/profile/userprofile";
import { useNavigate } from "react-router-dom";
import { Menu } from "@mui/material";
import Avatar from "@mui/material";
import pic from "assets/flags/france.png";
import MenuItem from "@mui/material/MenuItem";
import { getItem } from "utils/localStorage";
import { getData } from "services/request";
import MDButton from "components/MDButton";
// icons
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { responsiveFontSizes } from "@mui/material";
import { CheckBox, WidthFull } from "@mui/icons-material";
import MDInput from "components/MDInput";
function Users() {
  // const [usersData, setUsersData] = useState([]);
  const [showUserProfile, setShowUserProfile] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedName, setSelectedName] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const navigate = useNavigate();
  // nnewwww
  const [menutag, setMenutag] = useState(null);
  const openMenutag = ({ currentTarget }) => setMenutag(currentTarget);
  const closeMenutag = () => setMenutag(null);
  const renderMenutag = (
    <Menu
      id="simple-menu"
      anchorEl={menutag}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menutag)}
      onClose={closeMenutag}
    >
      <MenuItem onClick={closeMenutag}>Manufaacturer</MenuItem>
      <MenuItem onClick={closeMenutag}>view details</MenuItem>
      <MenuItem onClick={closeMenutag}>Edit Selected</MenuItem>
      <MenuItem onClick={closeMenutag}>Removes selected</MenuItem>
    </Menu>
  );
  // const fetchData = async () => {
  //   try {
  //     const token = getItem("token");
  // if (!token) {
  //   throw new Error("Token is missing from local storage.");
  // }
  // const response = await axios.get("http://192.168.1.27:3000/api/users?pageNo=1&pageSize=20", {
  //   headers: {
  //     "x-access-token": token,
  //   },
  // });
  // console.log("this is a response", response);
  // const response = await getData(
  //   "https://596f-2401-4900-813b-168e-ff8b-108b-1d7e-5e9c.ngrok-free.app/apiusers?pageNo=1&pageSize=20",
  //   {
  //     headers: {
  //       "x-access-token": token,
  //     },
  //   }
  // );
  // const fetchedData = response.data.items.map((user, index) => ({
  //   sNo: index + 1,
  //   name: user.name,
  //   uniqueId: user.userName,
  //   email: user.email || "N/A",
  //   PhoneNo: `${user.countryCode} ${user.phone || "N/A"}`,
  //   Stons: user.stons || 0,
  //   Gems: user.gems || 0,
  //   country: user.countryCode || "N/A",
  //   profileImg: user.currentProfile?.profileImg || "N/A",
  //   profileDesc: user.currentProfile?.profileDesc || "N/A",
  // }));
  //   setUsersData(fetchedData);
  //   console.log("Main Data:", response.data.items);
  //   console.log("Fetched Data:", fetchedData);
  //   console.log("response of the user table data", response.data);
  // } catch (error) {
  //   console.error("Error occurred:", error.response?.data || error.message);
  // }
  // };
  // useEffect(() => {
  //   fetchData().then(() => console.log("Data fetch complete"));
  // }, []);
  const handleRowClick = (row) => {
    setSelectedRow(row.uniqueId);
    // navigate(`/user-profile/${row.uniqueId}`);
    setSelectedName(row.name);
    console.log("this is a name of selected row", selectedName);
  };
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      {/* <MDButton>Alphabetically A - Z</MDButton>
      <MDButton>Alphabetically A - Z</MDButton> */}
      <MDTypography>Alphabetically A - Z</MDTypography>
      <MDTypography>Alphabetically Z - A</MDTypography>
    </Menu>
  );
  const columns = [
    { Header: "Name", accessor: "Name" },
    { Header: "Generic Name", accessor: "GenericName" },
    { Header: "weight", accessor: "weight" },
    { Header: "Category ", accessor: "Category" },
    { Header: "Price ", accessor: "Price" },
    { Header: "Stock", accessor: "Stock" },
    { Header: "Status", accessor: "Status" },
  ];
  const usersData = [
    {
      Name: (
        <Grid
          item
          xs={12}
          container
          spacing={2}
          paddingTop={2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <input type="checkbox" style={{ border: "5px solid red" }} />
          <img src={pic} alt="hlo" style={{ borderRadius: "50%", margin: "0 10px" }} />
          <span>MED - 1008</span>
        </Grid>
      ),
      GenericName: "Azithromycin",
      weight: "500mg",
      Category: "Tablet",
      Price: "$20.55 USD",
      Stock: 42,
      Status: (
        <MDBox>
          <span
            style={{
              background: "#FDF1E8",
              fontWeight: "bold",
              color: "#E46A11",
              padding: "5px 10px",
              borderRadius: "20px ",
            }}
          >
            Available
          </span>
          <MDButton>
            <MoreVertIcon onClick={openMenutag}></MoreVertIcon>
          </MDButton>
          {renderMenutag}
        </MDBox>
      ),
    },
    {
      Name: (
        <Grid
          item
          xs={12}
          container
          spacing={2}
          paddingTop={2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <input type="checkbox" style={{ border: "5px solid red" }} />
          <img src={pic} alt="hlo" style={{ borderRadius: "50%", margin: "0 10px" }} />
          <span>MED - 1008</span>
        </Grid>
      ),
      GenericName: "Azithromycin",
      weight: "500mg",
      Category: "Tablet",
      Price: "$20.55 USD",
      Stock: 42,
      Status: (
        <MDBox>
          <span
            style={{
              background: "#FDF1E8",
              fontWeight: "bold",
              color: "#E46A11",
              padding: "5px 10px",
              borderRadius: "20px ",
            }}
          >
            Available
          </span>
          <MDButton>
            <MoreVertIcon onClick={openMenutag}></MoreVertIcon>
          </MDButton>
          {renderMenutag}
        </MDBox>
      ),
    },
    {
      Name: (
        <Grid
          item
          xs={12}
          container
          spacing={2}
          paddingTop={2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <input type="checkbox" style={{ border: "5px solid red" }} />
          <img src={pic} alt="hlo" style={{ borderRadius: "50%", margin: "0 10px" }} />
          <span>MED - 1008</span>
        </Grid>
      ),
      GenericName: "Azithromycin",
      weight: "500mg",
      Category: "Tablet",
      Price: "$20.55 USD",
      Stock: 42,
      Status: (
        <MDBox>
          <span
            style={{
              background: "#FDF1E8",
              fontWeight: "bold",
              color: "#E46A11",
              padding: "5px 10px",
              borderRadius: "20px ",
            }}
          >
            Available
          </span>
          <MDButton>
            <MoreVertIcon onClick={openMenutag}></MoreVertIcon>
          </MDButton>
          {renderMenutag}
        </MDBox>
      ),
    },
  ];
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={3}
                py={3}
                px={2}
                // variant="gradient"
                bgColor="none"
                borderRadius="lg"
                coloredShadow="transparent"
                sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <MDBox>
                  <MDTypography variant="h3" color="dark">
                    Medicine List
                  </MDTypography>
                </MDBox>
                <MDBox
                  sx={{
                    display: "flex",
                    // justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {renderMenu()}
                  <MDButton
                    size="large"
                    // disableRipple
                    // color="inherit"
                    // sx={navbarIconButton}
                    // aria-controls="notification-menu"
                    // aria-haspopup="true"
                    // variant="contained"
                    onClick={handleOpenMenu}
                  >
                    <FilterAltOutlinedIcon style={{ color: "#000000" }}></FilterAltOutlinedIcon>
                  </MDButton>
                  <MDBox
                    sx={{
                      background: "#5478D9",
                      borderRadius: "8px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <AddIcon sx={{ color: " white ", marginLeft: "10px" }} />
                    <MDButton sx={{ color: " white " }}>Add product</MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
              <MDBox pt={3} style={{ height: "100%", minHeight: "400px" }}>
                <DataTable
                  entriesPerPage={{ defaultValue: 4, entries: [5, 10, 15] }}
                  // canSearch={true}
                  showTotalEntries={true}
                  table={{ columns, rows: usersData }}
                  pagination={{ variant: "gradient", color: "white" }}
                  isSorted={true}
                  noEndBorder={true}
                  onRowClick={handleRowClick}
                />
              </MDBox>
              <MDBox pt={1} style={{ height: "auto" }}>
                {showUserProfile && (
                  <UserProfile uniqueId={selectedRow} onClose={() => setShowUserProfile(false)} />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
export default Users;
