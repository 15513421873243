/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "interface/LayoutContainers/DashboardLayout";
import DashboardNavbar from "interface/Navbars/DashboardNavbar";
import Footer from "interface/Footer";
import UserProfile from "layouts/profile/userprofile";

// import ComplexStatisticsCard from "interface/Cards/StatisticsCards/ComplexStatisticsCard";
import IconTextValueComponent from "interface/Cards/activities/IconTextValueComponent";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import React, { useState, useEffect } from "react";
import Projects from "layouts/dashboard/components/Projects";
import RecentData from "./components/Projects/RecentRecreate";
import Payouts from "./components/Projects/Payouts";

import activity from "../../assets/activities-images/active.png";
import inactive from "../../assets/activities-images/Inactive.png";
import gem from "../../assets/activities-images/gem.png";
import stone from "../../assets/activities-images/stone.png";

import active from "../../assets/activities-images/arrow-trend-up-svgrepo-com 1 (2).png";
import decrease from "../../assets/activities-images/chart-down-svgrepo-com 1 (1).png";

// charts
import MyLineChart from "./data/linechart";
// import PieChart from "interface/Charts/PieChart";
import MyPie from "./data/myPie";
import MyPieData from "./data/myPie";
import { colors } from "@mui/material";
import svgIcon from "assets/theme/components/svgIcon";
import { from } from "stylis";
import MDTypography from "components/MDTypography";
// import PieChart from "interface/Charts/PieChart";

function Dashboard() {
  // const { sales, tasks } = reportsLineChartData;
  // user data
  const [uservalue, setUserValue] = useState("40,689");
  const [usernumber, setUserNumber] = useState("6.5%");
  const [userheading, setUserHeading] = useState("Up from yesterday");
  // order data
  const [ordervalue, setOrderValue] = useState("40,689");
  const [ordernumber, setOrderNumber] = useState("6.5%");
  const [orderheading, setOrderHeading] = useState("Up from yesterday");
  // sale data
  const [salevalue, setSaleValue] = useState("40,689");
  const [salenumber, setSaleNumber] = useState("6.5%");
  const [saleheading, setsaleHeading] = useState("Up from yesterday");
  // Pending data
  const [pendingvalue, setPendingValue] = useState("40,689");
  const [pendingumber, setPendingNumber] = useState("6.5%");
  const [pendingheading, setPendingHeading] = useState("Up from yesterday");

  return (
    <DashboardLayout xs={12} md={6} lg={3}>
      {/* <DashboardNavbar /> */}
      <MDTypography pt={3} pb={3} style={{ color: "#304F5E", fontSize: "32px", fontWeight: "600" }}>
        Dashboard
      </MDTypography>
      <MDBox xs={12} md={5} lg={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <IconTextValueComponent
                icon={activity}
                text="Total Users"
                value={uservalue}
                svg={active}
                number={usernumber}
                heading={userheading}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={2.8}>
            <MDBox mb={1.5}>
              <IconTextValueComponent
                icon={stone}
                text="Total Order"
                value={ordervalue}
                svg={active}
                number={ordernumber}
                heading={orderheading}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={8} md={6} lg={3}>
            <MDBox mb={1.5}>
              <IconTextValueComponent
                icon={inactive}
                text="Total Sales"
                value={salevalue}
                svg={decrease}
                // number="4.3%"
                dcreasenumber={salenumber}
                heading={saleheading}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3.2}>
            <MDBox mb={1.5}>
              <IconTextValueComponent
                icon={gem}
                text=" Total Pending"
                value={pendingvalue}
                svg={active}
                number={pendingumber}
                heading={pendingheading}
              />
            </MDBox>
          </Grid>

          {/* 
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="Today's Users"
                count="2,300"
                percentage={{
                  color: "success",
                  amount: "+3%",
                  label: "than last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="store"
                title="Revenue"
                count="34k"
                percentage={{
                  color: "success",
                  amount: "+1%",
                  label: "than yesterday",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Followers"
                count="+91"
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Just updated",
                }}
              />
            </MDBox>
          </Grid> */}
        </Grid>
        <MDBox mt={4.5}>
          <Grid xs={12} md={6} lg={7}>
            <MDBox
              mb={3}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // border: "2px solid black",
              }}
            >
              <div style={{ height: "100%", width: "100%" }}>
                <MyLineChart />
              </div>
            </MDBox>
          </Grid>
        </MDBox>

        <MDBox>
          <Grid container spacing={3} sm={12} style={{ width: "100%", margin: 0 }}>
            <Grid item xs={12}>
              {/* <Projects /> */}
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ width: "100%", margin: 0 }}>
            <Grid item xs={12}>
              {/* <RecentData /> */}
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ width: "100%", margin: 0 }}>
            {/* <Grid item xs={12}>
              <Payouts />
            </Grid> */}
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Dashboard;
