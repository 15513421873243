import Dashboard from "layouts/dashboard";
import user from "layouts/user";

import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

// @mui icons
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import MedicationOutlinedIcon from "@mui/icons-material/MedicationOutlined";
import AvTimerOutlinedIcon from "@mui/icons-material/AvTimerOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";

import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
// import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import Users from "layouts/user";
import Stickers from "layouts/stickers";
import Invoice from "layouts/Invoice";
import Categories from "layouts/profile";
import BlockAndReport from "layouts/authentication/BlockandReport";

import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import ResetThePassword from "layouts/authentication/sign-in/passwordreset";
import RequestNewPassword from "layouts/authentication/sign-in/RequestReset";
import ChangePassword from "layouts/authentication/change-password/ChangePassword";
import Logout from "layouts/authentication/Log-Out/LogOut";
import BlockUserpopUp from "layouts/authentication/Block-user-popup/BlockUserpopup";

import VerifyOtp from "layouts/authentication/sign-in/verify-otp";

import { Component } from "react";
import { element } from "prop-types";
import UserProfile from "layouts/profile/userprofile";
import BlockUser from "layouts/profile/block-User/Block-User";
import MDButton from "components/MDButton";
import { icon } from "@fortawesome/fontawesome-svg-core";
import Addcustomer from "interface/Add-customer/add-customer";
import CustomerLayout from "layouts/customerlayout";
// import Customermain from "layouts/stickers";
import Customermain from "layouts/customerlayout/customerMain";
import MedicineLayout from "layouts/medicinelayout";
import MedicineList from "layouts/medicinelayout/Medicinelist";
import Addmedicine from "interface/Add-medicine/Add-medicine";
import Manufacturerdata from "layouts/medicinelayout/Manufacturerdata";
import Addmanufacture from "interface/Add-Manufacturer";
import Medicinedetail from "layouts/medicinelayout/Medicinedetail";
import Editmedicinepopup from "interface/Edit-medicine";
import Orderlayout from "layouts/Order-layout";
import Orderlist from "layouts/Order-layout/Order-list";
// import Orderaccount from "layouts/Order-layout/Order-account";
import Medicinestock from "layouts/medicinelayout/Medicine-stock";
import Medicinereport from "layouts/medicinelayout/Medicine-stockreport";
import Inboxdata from "layouts/Inbox/Inboxlayout/Inbox-data";
import Inboxlayout from "layouts/Inbox/Inboxlayout";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import PersonIcon from "@mui/icons-material/Person";
import ForumIcon from "@mui/icons-material/Forum";
import ListIcon from "@mui/icons-material/List";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: (
      <QueryBuilderIcon style={{ color: "#202224" }} fontSize="small">
        dashboard
      </QueryBuilderIcon>
    ),
    route: "/dashboard",
    component: <Dashboard />,
  },
  // {
  //   type: "collapse",
  //   name: "Medicine List",
  //   key: "MedicineList",
  //   icon: <MedicationOutlinedIcon style={{ color: "#202224" }} />,
  //   route: "/MedicineList",

  //   component: <Users />,
  // },
  {
    type: "collapse",
    name: "Medicine List",
    key: "MedicineList",
    icon: <DashboardOutlinedIcon style={{ color: "#202224" }} />,
    route: "/MedicineList",
    component: <MedicineLayout />,
    children: [
      { path: "", element: <MedicineList /> },
      { path: "addmedicine", element: <Addmedicine /> },
      // { path: "manufacturerdata", element: <Manufacturerdata /> },
      // { path: "medicinedetail", element: <Medicinedetail /> },
      // { path: "editpopup", element: <Editmedicinepopup /> },
      { path: "medicinestock", element: <Medicinestock />, name: "MedicineStock" },
      { path: "medicinereport", element: <Medicinereport />, name: "Stock Report" },
    ],
  },
  {
    type: "collapse",
    name: "Customer",
    key: "Customermain",
    icon: <PersonIcon style={{ color: "#202224" }} />,
    route: "/Customermain",
    component: <CustomerLayout />,
    // children: [
    //   { path: "", element: <Customermain /> },
    //   { path: "addcustomer", element: <Addcustomer /> },
    // ],
  },

  // {

  //   type: "collapse",
  //   name: "Customer",
  //   key: "Customer",
  //   icon: <FavoriteBorderOutlinedIcon style={{ color: "#202224" }} />,
  //   route: "/Customer",
  //   component: <CustomerLayout />,
  // },

  // {
  //   type: "collapse",
  //   name: "Inbox",
  //   // key: "Invoice",
  //   icon: <FileCopyOutlinedIcon style={{ color: "#202224" }} />,
  //   route: "/Inbox",
  //   component: <Inbox />,
  // },
  {
    type: "collapse",
    name: "Inbox",
    key: "Inbox",
    icon: <ForumIcon style={{ color: "#202224" }} />,
    route: "/Inbox",
    component: <Inboxlayout />,
    // children: [
    //   { path: "", element: <Inboxdata /> },
    //   // { path: "/starred", element: <Inboxdata /> },
    //   // { path: "orderaccount", element: <Orderaccount />, name: "Oder account" },
    // ],
  },
  { route: "/Customermain/addcustomer", component: <Addcustomer /> },

  {
    type: "collapse",
    name: "Order List",
    key: "Order",
    icon: <ListIcon style={{ color: "#202224" }} />,
    route: "/Order",
    component: <Orderlayout />,
    // children: [
    //   { path: "", element: <Orderlist /> },
    //   { path: "orderaccount", element: <Orderaccount />, name: "Oder account" },
    // ],
  },
  // {
  //   type: "collapse",
  //   name: "Order Lists",
  //   key: "OrderLists",

  //   icon: (
  //     <ListAltOutlinedIcon style={{ color: "#202224" }} fontSize="small">
  //       dashboard
  //     </ListAltOutlinedIcon>
  //   ),

  //   route: "/OrderLists",
  //   component: <Categories />,
  // },

  // {
  //   type: "collapse",
  //   name: "Reports",
  //   key: "BlockandReport",
  //   icon: <ArticleOutlinedIcon style={{ color: "#202224" }} />,
  //   route: "/BlockandReport",

  //   component: <BlockAndReport />,
  // },
  {
    type: "collapse",
    name: "Invoice",
    key: "Invoice",
    icon: <PaymentsOutlinedIcon style={{ color: "#202224" }} />,
    route: "/Invoice",

    component: <Invoice />,
  },
  {
    // type: "collapse",
    // name: "SignIn",
    key: "sign-in",
    // icon: <SupportAgentIcon style={{ color: "#9a3230" }} />,
    route: "/sign-in",

    component: <SignIn />,
  },
  // {
  //   name: "Block and Report",
  //   key: "sign-up",
  //   icon: (
  //     <ListAltOutlinedIcon style={{ color: "#9a3230" }} fontSize="small">
  //       assignment
  //     </ListAltOutlinedIcon>
  //   ),
  //   route: "/authentication/sign-up",
  //   route: "/sign-up",
  //   component: <SignUp />,
  // },
  {
    key: "reset-password",
    route: "/ResetThePassword",
    component: <ResetThePassword />,
  },
  // {
  //   key: "verify-otp",
  //   route: "/otp-verification",
  //   component: <VerifyOtp />,
  // },
  {
    key: "reset-reset",
    route: "/newPasswordRequest",
    component: <RequestNewPassword />,
  },
  {
    key: "reset-reset",
    route: "/Block-user-popup",
    component: <BlockUserpopUp />,
  },
  {
    key: "user-profile",
    route: "/user-profile/:uniqueId",
    component: <UserProfile />,
  },
  {
    key: "block-user",
    route: "/block-user",
    component: <BlockUser />,
  },
  // {
  //   key: "Customermain",
  //   route: "/addcustomer",
  //   component: <Addcustomer />,
  // },
];

export default routes;
