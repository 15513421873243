import React from "react";
import PropTypes from "prop-types";
import { useTable, usePagination, useGlobalFilter, useSortBy, useAsyncDebounce } from "react-table";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDPagination from "components/MDPagination";
// import MDPaginationItemRoot from "components/MDPagination/MDPaginationItemRoot";
import DataTableHeadCell from "interface/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "interface/Tables/DataTable/DataTableBodyCell";
// import CustomMDPaginationItemRoot from "./CustomMDPaginationItemRoot";
// import CustomMDPaginationItemRoot from "./customMDPagination";
// import MDPagination from "components/MDPagination";
import MDInput from "components/MDInput";
function DataTable({
  entriesPerPage,
  canSearch,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  noEndBorder,
  onRowClick,
  // customColor = "#9a3230",
  customColor = "#9a3230",
}) {
  const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries.map((el) => el.toString())
    : ["5", "10", "15", "20", "25"];
  const columns = React.useMemo(() => table.columns, [table]);
  const data = React.useMemo(() => table.rows, [table]);

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  React.useEffect(() => {
    setPageSize(defaultValue || 10);
  }, [defaultValue, setPageSize]);

  const setEntriesPerPage = (value) => {
    setPageSize(value);
  };

  const [search, setSearch] = React.useState(globalFilter);

  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;
  const entriesEnd = Math.min((pageIndex + 1) * pageSize, table.rows.length);

  const handleRowClick = (row) => {
    if (onRowClick) {
      onRowClick(row.original);
    }
  };

  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      {/* {entriesPerPage || canSearch ? (
        <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          {entriesPerPage && (
            <MDBox display="flex" alignItems="center">
              <MDInput
                select
                value={pageSize.toString()}
                onChange={(event) => setPageSize(Number(event.target.value))}
                size="small"
                sx={{ width: "5rem" }}
              >
                {entries.map((entry, idx) => (
                  <option key={idx} value={entry}>
                    {entry}
                  </option>
                ))}
              </MDInput>
              <MDTypography variant="caption" color="secondary">
                &nbsp;&nbsp;entries per page
              </MDTypography>
            </MDBox>
          )}
          {canSearch && (
            <MDBox width="12rem" ml="auto">
              <MDInput
                placeholder="Search..."
                value={search}
                size="small"
                fullWidth
                onChange={({ currentTarget }) => {
                  setSearch(currentTarget.value);
                  onSearchChange(currentTarget.value);
                }}
              />
            </MDBox>
          )}
        </MDBox>
      ) : null} */}
      <Table {...getTableProps()}>
        <MDBox component="thead">
          {headerGroups.map((headerGroup, key) => (
            <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, idx) => (
                <DataTableHeadCell
                  key={idx}
                  {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                  width={column.width ? column.width : "auto"}
                  align={column.align ? column.align : "left"}
                  sorted={setSortedValue(column)}
                >
                  {column.render("Header")}
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </MDBox>
        <TableBody {...getTableBodyProps()}>
          {page.map((row, key) => {
            prepareRow(row);
            return (
              <TableRow
                key={key}
                {...row.getRowProps()}
                onClick={() => handleRowClick(row)}
                style={{ cursor: "pointer" }}
              >
                {row.cells.map((cell, idx) => (
                  <DataTableBodyCell
                    key={idx}
                    noBorder={noEndBorder && page.length - 1 === key}
                    align={cell.column.align ? cell.column.align : "left"}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </DataTableBodyCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <MDBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        p={3}
      >
        <MDBox mb={{ xs: 3, sm: 0 }}>
          {showTotalEntries && (
            <MDTypography variant="button" color="#202224" sx={{ fontWeight: "bold" }}>
              Showing {entriesStart} to {entriesEnd} of {table.rows.length} entries
            </MDTypography>
          )}
        </MDBox>
        <MDBox
          component="ul"
          display="flex"
          justifyContent="flex-end"
          p={0}
          m={0}
          sx={{ listStyle: "none" }}
        >
          <MDPagination item onClick={() => previousPage()} disabled={!canPreviousPage}>
            <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
          </MDPagination>
          {pageOptions.map((pageIndexOption) => (
            <MDPagination
              key={pageIndexOption}
              item
              onClick={() => gotoPage(pageIndexOption)}
              active={pageIndex === pageIndexOption}
              customColor={customColor}
            >
              {pageIndexOption + 1}
            </MDPagination>
          ))}
          <MDPagination item onClick={() => nextPage()} disabled={!canNextPage}>
            <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
          </MDPagination>
        </MDBox>
      </MDBox>
    </TableContainer>
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: ["5", "10", "15", "20", "25"] },
  canSearch: false,
  showTotalEntries: false,
  pagination: true,
  isSorted: false,
  noEndBorder: false,
  onRowClick: null,
  customColor: "#F1F4F9",
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.string),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.shape({
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  pagination: PropTypes.bool,
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  onRowClick: PropTypes.func,
  customColor: PropTypes.string,
};

export default DataTable;
