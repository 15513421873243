import { styled } from "@mui/material/styles";
// import MDPaginationItemRoot from "components/MDPagination/MDPaginationItemRoot";
import MDPaginationItemRoot from "components/MDPagination/MDPaginationItemRoot";

const CustomMDPaginationItemRoot = styled(MDPaginationItemRoot)(({ ownerState }) => {
  const { variant, active, customColor } = ownerState;

  return {
    backgroundColor: active ? customColor : "transparent",
    "&:hover, &:focus, &:active": {
      backgroundColor: active ? customColor : "transparent",
    },
  };
});

export default CustomMDPaginationItemRoot;
