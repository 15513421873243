import React, { useState } from "react";
import { Modal, Box, Typography, IconButton } from "@mui/material";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90%", sm: "450px" },
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  position: "relative",
};

const Changepassword = ({ open, handleClosep }) => {
  const [medicineName, setMedicineName] = useState("");
  const [dosage, setDosage] = useState("");
  const [manufacturer, setManufacturer] = useState("");

  const handleSave = (e) => {
    e.preventDefault();
    if (!medicineName || !dosage || !manufacturer) {
      alert("Please fill out all fields.");
    } else {
      console.log("Form Data:", { medicineName, dosage, manufacturer });
      handleClosep();
    }
  };

  return (
    <Modal
      open={open}
      onClose={(e, reason) => {
        if (reason === "backdropClick") {
          e.stopPropagation();
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      BackdropProps={{
        invisible: true,
      }}
      disableEscapeKeyDown
    >
      <Box sx={style}>
        <IconButton
          onClick={handleClosep}
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
            zIndex: 10,
          }}
        >
          <CloseIcon />
        </IconButton>

        <MDBox
          bgColor="transparent"
          borderRadius="lg"
          mt={3}
          p={2}
          mb={1}
          textAlign="center"
          width="100%"
        >
          <MDTypography variant="h4" fontWeight="medium" color="black" mt={1}>
            Create New Password
          </MDTypography>
          <MDTypography
            variant="h6"
            fontWeight="light"
            color="black"
            mt={1}
            sx={{ color: "#008080" }}
          >
            Your new password must be different from the old password.
          </MDTypography>
        </MDBox>

        <Box
          pt={2}
          pb={3}
          px={2}
          sx={{
            width: "100%",
            maxHeight: "60vh",
            overflowY: "auto",
          }}
        >
          <MDBox component="form" role="form">
            <MDBox mb={2} sx={{ paddingLeft: "50px" }}>
              <MDInput
                type="password"
                label="Password"
                fullWidth
                sx={{
                  width: { xs: "100%", sm: "75%" },
                }}
              />
              <MDTypography
                variant="h6"
                fontWeight="light"
                color="black"
                mt={1}
                mb={2}
                sx={{ color: "#21030280" }}
              >
                Must be at least 8 characters
              </MDTypography>
            </MDBox>

            <MDBox mb={2} sx={{ paddingLeft: "50px" }}>
              <MDInput
                type="password"
                label="Confirm Password"
                fullWidth
                sx={{
                  width: { xs: "100%", sm: "75%" },
                }}
              />
              <MDTypography
                variant="h6"
                fontWeight="light"
                color="black"
                mt={1}
                mb={2}
                sx={{ color: "#21030280" }}
              >
                Both Passwords must match
              </MDTypography>
            </MDBox>

            <MDBox mt={4} mb={1} display="flex" justifyContent="center">
              <MDButton
                variant="dark"
                color="dark"
                fullWidth
                to="../../dashboard"
                sx={{
                  background: "#5478D9",
                  textTransform: "none",
                  fontSize: "16px",
                  color: "#ffffff",
                  borderRadius: "50px",
                  width: { xs: "100%", sm: "60%" },
                  "&:hover": {
                    background: "#5478D9",
                  },
                }}
              >
                Change Password
              </MDButton>
            </MDBox>
          </MDBox>
        </Box>
      </Box>
    </Modal>
  );
};

Changepassword.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClosep: PropTypes.func.isRequired,
};

export default Changepassword;
