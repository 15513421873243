import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "interface/LayoutContainers/DashboardLayout";
import DashboardNavbar from "interface/Navbars/DashboardNavbar";
import Footer from "interface/Footer";
import ProfileInfoCard from "interface/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "interface/Lists/ProfilesList";
import DefaultProjectCard from "interface/Cards/ProjectCards/DefaultProjectCard";
import ViewOrder from "interface/viewOrder";
// import NewCategories from "./Newcategories";
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";
import profilesListData from "layouts/profile/data/profilesListData";
import axios from "axios";
// import ResponsiveBoxes from "./Newcategories";
import { useEffect } from "react";
import { getItem } from "utils/localStorage";
import UserProfile from "layouts/profile/userprofile";
import { Card } from "@mui/material";
import MDButton from "components/MDButton";
import DataTable from "interface/Tables/DataTable";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import pic from "assets/flags/france.png";
import { space } from "postcss/lib/list";
import MenuItem from "@mui/material/MenuItem";
import { Menu } from "@mui/material";
import Ordernav from "interface/OrderNav";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import Invoiceform from "./invoiceform";

// import backgroundimage from "assets/Invoices/Wallet.png";
const handleRowClick = (rows) => {
  console.log("Clicked row:", { rows });
};

function Invoice() {
  // const [showUserProfile, setShowUserProfile] = useState(false);
  // const [selectedRow, setSelectedRow] = useState(null);
  // <MDBox pt={6} pb={3}>
  //   <Grid container spacing={6}>
  //     <Grid item xs={12}>
  //       <Card>
  //         <MDBox pt={3} style={{ height: "100%", minHeight: "400px" }}>
  //           <DataTable
  //             entriesPerPage={{ defaultValue: 5, entries: [5, 10, 15] }}
  //             canSearch={true}
  //             showTotalEntries={true}
  //             table={{ columns, rows }}
  //             pagination={{ variant: "gradient", color: "info" }}
  //             isSorted={true}
  //             noEndBorder={true}
  //             onRowClick={handleRowClick}
  //           />
  //         </MDBox>

  //         <MDBox pt={3} style={{ height: "100%" }}>
  //           {showUserProfile && (
  //             <UserProfile rowData={selectedRow} onClose={() => setShowUserProfile(false)} />
  //           )}
  //         </MDBox>
  //       </Card>
  //     </Grid>
  //   </Grid>
  // </MDBox>;

  const [invoiceData, setInvoiceData] = useState({
    from: { name: "Virginia Walker", address: "9694 Krajcik Locks Suite 635" },
    to: { name: "John Doe", address: "1234 Elm Street, Apt 42" },
    invoiceDate: "2024-11-08",
    dueDate: "2024-12-08",
    total: 4680,
  });
  const [status, setStatus] = useState("Processing");
  const [statusColor, setStatusColor] = useState("#E46A11"); // Default color for Processing
  const [statusBackgroundColor, setStatusBackgroundColor] = useState("#FFFFFF"); // Default background color

  // Step 2: Function to change the status and color
  const changeStatus = (newStatus) => {
    setStatus(newStatus);
    // Set the color based on the status
    if (newStatus === "Cancelled") {
      setStatusColor("#F04438"); // Red for Cancelled
      setStatusBackgroundColor("#FEEDEC");
    } else if (newStatus === "Shipped") {
      setStatusColor("#13B2E4"); // Blue for Shipped
      setStatusBackgroundColor("#FFFFFF");
    } else if (newStatus === "Delivered") {
      setStatusColor("#2ecc71"); // Green for Delivered
      setStatusBackgroundColor("#E7F4EE");
    } else {
      setStatusColor("#0D894F"); // Default color for Processing
      setStatusBackgroundColor("#FFFFFF");
    }
  };

  // Step 3: Optionally, use useEffect to automatically change status after 3 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      changeStatus("Shipped"); // Change to "Shipped" after 3 seconds
    }, 3000); // Change after 3 seconds

    return () => clearTimeout(timer); // Cleanup on component unmount
  }, []);

  // Step 4: Optionally, you can add more timeouts or conditions to update the status
  useEffect(() => {
    const timer2 = setTimeout(() => {
      changeStatus("Delivered"); // Change to "Delivered" after 6 seconds
    }, 6000);

    return () => clearTimeout(timer2);
  }, []);
  const Delete = () => {
    Swal.fire({
      title: "Are You Sure?",
      text: "This event data will be removed permanently.",
      icon: "error",
      showCancelButton: true,
      confirmButtonText: "Yes Delete",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#9FEFAB",
      cancelButtonColor: "#E8D0D0",
      allowOutsideClick: false, // Prevents clicking outside to close
      allowEscapeKey: false, // Prevents pressing Escape to close
      customClass: {
        confirmButton: "custom-confirm-button", // Apply custom confirm button style
        cancelButton: "custom-cancel-button", // Apply custom cancel button style
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // navigate("/MedicineList");
      }
    });
  };
  const addCustomButtonStyles = () => {
    const style = document.createElement("style");
    style.innerHTML = `
      .custom-confirm-button {
        color: #000000 ; 
      }
      .custom-cancel-button {
        color: #000000 ; 
      }
    `;
    document.head.appendChild(style);
  };
  const [modalOpen, setModalOpen] = useState(false);
  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  // Call this function once to add the styles globally
  addCustomButtonStyles();

  // nnewwww
  const [menutag, setMenutag] = useState(null);
  const openMenutag = ({ currentTarget }) => setMenutag(currentTarget);
  const closeMenutag = () => setMenutag(null);
  const renderMenutag = (
    <Menu
      id="simple-menu"
      anchorEl={menutag}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menutag)}
      onClose={closeMenutag}
    >
      <MenuItem onClick={closeMenutag}>Manufaacturer</MenuItem>
      <MenuItem onClick={closeMenutag}>view details</MenuItem>
      <MenuItem onClick={closeMenutag}>Edit Selected</MenuItem>
      <MenuItem onClick={closeMenutag}>Removes selected</MenuItem>
    </Menu>
  );

  const columns = [
    { Header: "Invoice ID", accessor: "OrderID" },
    // { Header: " Medicine Name", accessor: "MedicineName" },
    { Header: "Customer Name", accessor: "Customer" },
    { Header: "Invoice Date ", accessor: "Date" },
    { Header: "Total Items ", accessor: "item" },
    { Header: "Total Amount ", accessor: "Total" },
    { Header: "Payment Status", accessor: "Payment" },
    { Header: "Delivery Status", accessor: "Status" },
    { Header: "Action", accessor: "Action" },
  ];

  const orderlistData = [
    {
      OrderID: "#30201002",
      MedicineName: (
        <Grid
          item
          xs={12}
          container
          spacing={2}
          paddingTop={2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Betadine
        </Grid>
      ),
      Date: "4/5/2022",
      Customer: "John Bushmill Johnb@mail.com",
      Total: "$121.00",
      item: "3",
      Payment: "Mastercard",
      Status: (
        <MDBox
          p={1}
          style={{
            backgroundColor: statusBackgroundColor,
            color: statusColor,
            borderRadius: "10px",
          }}
        >
          {status}
        </MDBox>
      ),
      Action: (
        <MDBox
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid #979797",
            padding: "6px",
            borderRadius: "10px",
            backgroundColor: "#f0f0f0",
            fontSize: "16px",
            position: "relative",
          }}
        >
          <MDBox style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <RemoveRedEyeOutlinedIcon onClick={handleOpen} />
          </MDBox>
          <Divider
            style={{
              width: "2px",
              height: "24px",
              backgroundColor: "#979797",
              margin: "0 10px",
            }}
          />

          <MDBox style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <DeleteOutlinedIcon style={{ color: "#EF3826" }} onClick={Delete} />
          </MDBox>
        </MDBox>
      ),
    },
  ];
  return (
    <DashboardLayout>
      <MDBox p={4}>
        <MDTypography style={{ fontSize: "32px", fontWeight: "bold", color: "#304F5E" }}>
          Invoice
        </MDTypography>
      </MDBox>
      <MDBox>
        <Invoiceform open={modalOpen} handleClose={handleClose} />
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3} style={{ height: "100%", minHeight: "400px" }}>
                <DataTable
                  // rows={rows}
                  // columns={columns}
                  entriesPerPage={{ defaultValue: 6, entries: [5, 10, 15] }}
                  // pageSizeOptions={[5, 10]}
                  checkboxSelection
                  showTotalEntries={true}
                  table={{ columns, rows: orderlistData }}
                  pagination={{ variant: "gradient", color: "white" }}
                  isSorted={true}
                  // noEndBorder={true}
                  sx={{ border: 0 }}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Invoice;
