import React, { useState } from "react";
import { Grid } from "@mui/material";
import Popup from "reactjs-popup";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import ReplayIcon from "@mui/icons-material/Replay";
import Divider from "@mui/material/Divider";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import dayjs from "dayjs";
import { DateRangeCalendar } from "@mui/x-date-pickers-pro/DateRangeCalendar";

function Ordernav() {
  const [selectedData, setSelectedData] = useState({
    orderType: [],
    orderStatus: [],
  });
  const toggleSelection = (category, item) => {
    setSelectedData((prevData) => {
      const currentCategory = prevData[category];
      const isSelected = currentCategory.includes(item);
      return {
        ...prevData,
        [category]: isSelected
          ? currentCategory.filter((i) => i !== item)
          : [...currentCategory, item],
      };
    });
  };
  const handleApply = () => {
    console.log("Selected Data:", selectedData);
  };
  const [value, setValue] = React.useState([dayjs("2022-04-17"), dayjs("2022-04-21")]);
  return (
    <MDBox>
      <Grid
        container
        sx={{
          maxWidth: 656,
          border: "1px solid #D5D5D5",
          backgroundColor: "#F9F9FB",
          borderRadius: 2,
          padding: "5px 0",
          color: "#000000",
          display: "flex",
          flexDirection: { xs: "column", sm: "row", md: "row", xl: "row" },
          // justifyContent: "center",
          alignItems: { xs: "center" },
        }}
      >
        <Grid item>
          <MDButton>
            <FilterAltOutlinedIcon
              sx={{ width: 50, height: 50, color: "#000000", opacity: "100%" }}
            />
          </MDButton>
        </Grid>
        <Grid item sx={{ borderLeft: { xs: 0, sm: 1, xl: 1 } }}>
          <Popup
            trigger={
              <MDButton style={{ color: "#000000" }}>
                Date
                <ExpandMoreOutlinedIcon />
              </MDButton>
            }
            modal
            nested
          >
            {(close) => (
              <MDBox
                style={{
                  // width: "400px",
                  height: "480px",
                  // backgroundColor: "#FFFFFF",
                  //   border: "1px solid red",
                  borderRadius: "20px",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  width: {
                    xl: "400px",
                  },
                  // maxHeight: "600px",
                  backgroundColor: "#FFFFFF",
                  // borderRadius: "20px",
                  // boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  margin: "auto",
                  paddingTop: 2,
                }}
              >
                <MDBox pt={4} style={{ height: "200px" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateRangeCalendar calendars={1} />
                  </LocalizationProvider>
                </MDBox>
                <MDBox mt={18} pb={2} style={{ borderTop: "2px solid #979797" }}>
                  <MDTypography
                    variant="h6"
                    color="#434343"
                    pl={2}
                    pt={2}
                    style={{ fontWeight: "normal" }}
                  >
                    *You can choose multiple Dates
                  </MDTypography>
                </MDBox>
                <MDBox pb={2} style={{ display: "flex", justifyContent: "center" }}>
                  <MDButton
                    onClick={() => {
                      handleApply();
                      close();
                    }}
                    style={{ backgroundColor: "#FF5722", color: "white" }}
                  >
                    Apply
                  </MDButton>
                </MDBox>
              </MDBox>
            )}
          </Popup>
        </Grid>
        <Grid item sx={{ borderLeft: { xs: 0, sm: 1, xl: 1 } }}>
          <Popup
            trigger={
              <MDButton style={{ color: "#000000" }}>
                Order Type
                <ExpandMoreOutlinedIcon />
              </MDButton>
            }
            modal
            nested
          >
            {(close) => (
              <MDBox
                sx={{
                  width: {
                    xl: "700px",
                  },
                  maxHeight: "600px",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "20px",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  // border: "1px solid red ",
                  // display: "flex",
                  // flexDirection: {
                  //   xs: "column",
                  // },
                  paddingTop: 2,
                  margin: "auto",
                }}
              >
                <MDTypography variant="h4" style={{ padding: "10px" }}>
                  Select Order Type
                </MDTypography>
                <MDBox
                  sx={{
                    maxHeight: "200px",
                    overflowY: "auto",
                    padding: "30px",
                    borderRadius: "10px",
                  }}
                >
                  {[
                    "Health & Medicine",
                    "Book & Stationary",
                    "Services & Industry",
                    "Fashion & Beauty",
                    "Home & Living",
                    "Electronics",
                    "Mobile & Phone",
                    "Accessories",
                  ].map((type) => (
                    <MDButton
                      key={type}
                      onClick={() => toggleSelection("orderType", type)}
                      style={{
                        textTransform: "none",
                        backgroundColor: selectedData.orderType.includes(type)
                          ? "#4CAF50"
                          : "#FFFFFF",
                        color: selectedData.orderType.includes(type) ? "#FFFFFF" : "#000000",
                        border: selectedData.orderType.includes(type)
                          ? "2px solid #4CAF50"
                          : "2px solid #D5D5D5",
                        padding: "10px 35px",
                        cursor: "pointer",
                        borderRadius: "15px",
                        margin: "5px",
                      }}
                    >
                      {type}
                    </MDButton>
                  ))}
                </MDBox>

                <MDBox mt={2} pb={2} style={{ borderTop: "2px solid #979797" }}>
                  <MDTypography
                    variant="h6"
                    color="#434343"
                    pl={2}
                    pt={2}
                    style={{ fontWeight: "normal" }}
                  >
                    *You can choose multiple Order status
                  </MDTypography>
                </MDBox>
                <MDBox pb={2} style={{ display: "flex", justifyContent: "center" }}>
                  <MDButton
                    onClick={() => {
                      handleApply();
                      close();
                    }}
                    style={{ backgroundColor: "#4880FF", color: "white" }}
                  >
                    Apply
                  </MDButton>
                </MDBox>
              </MDBox>
            )}
          </Popup>
        </Grid>
        <Grid item sx={{ borderLeft: { xs: 0, sm: 1, xl: 1 } }}>
          <Popup
            trigger={
              <MDButton style={{ color: "#000000" }}>
                Order Status
                <ExpandMoreOutlinedIcon />
              </MDButton>
            }
            modal
            nested
          >
            {(close) => (
              <MDBox
                style={{
                  // backgroundColor: "#FFFFFF",
                  // borderRadius: "20px",
                  // boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  // width: {
                  //   xl: "700px",
                  // },
                  maxHeight: "500px",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "20px",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  // border: "1px solid red ",
                  // display: "flex",
                  // flexDirection: {
                  //   xs: "column",
                  // },
                  paddingTop: 2,
                  margin: "auto",
                }}
              >
                <MDTypography variant="h4" style={{ padding: "10px" }}>
                  Select Order Status
                </MDTypography>
                <MDBox
                  style={{
                    maxWidth: "480px",
                    maxHeight: "200px",
                    overflowY: "auto",
                    padding: "30px",
                    borderRadius: "10px",
                  }}
                >
                  {["Completed", "Processing", "Rejected", "On Hold", "In Transit"].map(
                    (status) => (
                      <MDButton
                        key={status}
                        onClick={() => toggleSelection("orderStatus", status)}
                        style={{
                          textTransform: "none",
                          backgroundColor: selectedData.orderStatus.includes(status)
                            ? "#4CAF50"
                            : "#FFFFFF",
                          color: selectedData.orderStatus.includes(status) ? "#FFFFFF" : "#000000",
                          border: selectedData.orderStatus.includes(status)
                            ? "2px solid #4CAF50"
                            : "2px solid #D5D5D5",
                          padding: "10px 35px",
                          cursor: "pointer",
                          borderRadius: "15px",
                          margin: "5px",
                        }}
                      >
                        {status}
                      </MDButton>
                    )
                  )}
                </MDBox>
                <MDBox mt={2} pb={2} style={{ borderTop: "2px solid #979797" }}>
                  <MDTypography
                    variant="h6"
                    color="#434343"
                    pl={2}
                    pt={2}
                    style={{ fontWeight: "normal" }}
                  >
                    *You can choose multiple Order status
                  </MDTypography>
                </MDBox>
                <MDBox pb={2} style={{ display: "flex", justifyContent: "center" }}>
                  <MDButton
                    onClick={() => {
                      handleApply();
                      close();
                    }}
                    style={{ backgroundColor: "#4880FF", color: "white" }}
                  >
                    Apply
                  </MDButton>
                </MDBox>
              </MDBox>
            )}
          </Popup>
        </Grid>
        <Grid item sx={{ borderLeft: { xs: 0, sm: 1, xl: 1 } }}>
          <MDButton
            sx={{ width: 150, color: "#EA0234" }}
            onClick={() => setSelectedData({ orderType: [], orderStatus: [] })}
          >
            <ReplayIcon />
            Reset Filter
          </MDButton>
        </Grid>
      </Grid>
    </MDBox>
  );
}
export default Ordernav;
