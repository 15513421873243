import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "interface/LayoutContainers/DashboardLayout";
import DashboardNavbar from "interface/Navbars/DashboardNavbar";
import { useNavigate } from "react-router-dom";
import Footer from "interface/Footer";
import ProfileInfoCard from "interface/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "interface/Lists/ProfilesList";
import DefaultProjectCard from "interface/Cards/ProjectCards/DefaultProjectCard";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
// import NewCategories from "./Newcategories";
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";
import profilesListData from "layouts/profile/data/profilesListData";
import axios from "axios";
// import ResponsiveBoxes from "./Newcategories";
import { useEffect } from "react";
import { getItem } from "utils/localStorage";
import UserProfile from "layouts/profile/userprofile";
import { Card } from "@mui/material";
import MDButton from "components/MDButton";
import DataTable from "interface/Tables/DataTable";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import pic from "assets/flags/france.png";
import { space } from "postcss/lib/list";
import MenuItem from "@mui/material/MenuItem";
import { Menu } from "@mui/material";

function Medicinereport() {
  // const [usersData, setUsersData] = useState([]);
  // const [showUserProfile, setShowUserProfile] = useState(false);
  // const [selectedRow, setSelectedRow] = useState(null);
  // const [selectedName, setSelectedName] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      {/* <MenuItem>
        <MDButton>Alphabetically A - Z</MDButton>
      </MenuItem>
      <MenuItem>
        <MDButton>Alphabetically Z - A</MDButton>
      </MenuItem> */}
    </Menu>
  );
  const columns = [
    { Header: "Image", accessor: "Image", color: "#202224" },
    { Header: " Medicine Name", accessor: "MedicineName" },
    { Header: "Price ", accessor: "Price" },
    { Header: "Stock", accessor: "Stock" },
    { Header: "Status", accessor: "Status" },
  ];
  const MedicinereportData = [
    {
      Image: <img src={pic} />,
      MedicineName: "Trueview Infrared Thermometer ",
      Price: "$690.00",
      Status: (
        <MDButton style={{ backgroundColor: "#FDF1E8", borderRadius: "20px" }}>Primary</MDButton>
      ),
      Stock: "3090",
    },
  ];
  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <MDBox
        mx={2}
        mt={3}
        mb={3}
        pt={3}
        px={2}
        // variant="gradient"
        bgColor="none"
        borderRadius="lg"
        coloredShadow="transparent"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          // border: "1px solid red",
        }}
      >
        <MDBox>
          <MDTypography variant="h3">Stock Report</MDTypography>
        </MDBox>
        <MDBox>
          {renderMenu()}
          <MDButton
            size="large"
            // disableRipple
            // color="inherit"
            // sx={navbarIconButton}
            // aria-controls="notification-menu"
            // aria-haspopup="true"
            // variant="contained"
            // onClick={handleOpenMenu}
          >
            <FilterAltOutlinedIcon style={{ color: "#000000" }}></FilterAltOutlinedIcon>
          </MDButton>
        </MDBox>
      </MDBox>
      <MDBox>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3} style={{ height: "100%", minHeight: "400px" }}>
                <DataTable
                  entriesPerPage={{ defaultValue: 6, entries: [5, 10, 15] }}
                  checkboxSelection
                  showTotalEntries={true}
                  table={{ columns, rows: MedicinereportData }}
                  pagination={{ variant: "gradient", color: "white" }}
                  isSorted={true}
                  sx={{ border: 0 }}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
export default Medicinereport;
