// Necessary imports
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import Bell from "../../../assets/Navigation/Bell.png";
import {
  Box,
  Menu,
  MenuItem,
  AppBar,
  Toolbar,
  IconButton,
  Icon,
  Card,
  Divider,
} from "@mui/material";
import boy from "../../../assets/profile-pic/boy.png";
import { useLocation, Link, useNavigate } from "react-router-dom";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import KeyOutlinedIcon from "@mui/icons-material/KeyOutlined";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import PropTypes from "prop-types";
import ProfileBox from "./ProfileBox";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import Breadcrumbs from "interface/Breadcrumbs";
import NotificationItem from "interface/Items/NotificationItem";
//icon
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import DehazeOutlinedIcon from "@mui/icons-material/DehazeOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "interface/Navbars/DashboardNavbar/styles";
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";
import usaFlag from "../../../assets/flags/united-states.png";
import franceMap from "../../../assets/flags/france.png";
import spainMap from "../../../assets/flags/spain.png";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import outlined from "assets/theme/components/button/outlined";
import { position } from "stylis";
import { BorderBottom } from "@mui/icons-material";
import Changepassword from "interface/ChangePassword";
import Logout from "interface/Logout";

// LanguageSelector component
const toggleBoxVisibility = () => {
  setBoxVisible(!isBoxVisible);
};
const LanguageSelector = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState({
    name: "English",
    icon: usaFlag,
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    handleClose();
  };

  const languages = [
    { name: "English", icon: usaFlag },
    { name: "French", icon: franceMap },
    { name: "Spanish", icon: spainMap },
  ];

  return (
    <>
      <IconButton
        sx={{
          gap: "5px",
          width: "150px",
          justifyContent: "space-between",
        }}
        onClick={handleClick}
      >
        <img src={selectedLanguage.icon} alt="Language" style={{ height: "30px" }} />
        <Box
          sx={{
            flexGrow: 1,
            textAlign: "left",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          <h6 style={{ margin: 0 }}>{selectedLanguage.name}</h6>
        </Box>
        <FontAwesomeIcon icon={faCaretDown} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{ "aria-labelledby": "basic-button" }}
      >
        <Box p={2}>
          <h6>Select Language</h6>
          {languages.map((language) => (
            <MenuItem key={language.name} onClick={() => handleLanguageChange(language)}>
              <img
                src={language.icon}
                alt={language.name}
                style={{ height: "20px", marginRight: "10px" }}
              />
              {language.name}
            </MenuItem>
          ))}
        </Box>
      </Menu>
    </>
  );
};

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);

  useEffect(() => {
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    window.addEventListener("scroll", handleTransparentNavbar);
    handleTransparentNavbar();

    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);
  const [modalOpen, setModalOpen] = useState(false);
  const handleOpenp = () => setModalOpen(true);
  const handleClosep = () => setModalOpen(false);
  const [modalOpenLog, setModalOpenLog] = useState(false);
  const handleOpenLog = () => setModalOpenLog(true);
  const handleCloseLog = () => setModalOpenLog(false);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 3 }}
    >
      {/* <MDButton style={{ display: "flex", alignItems: "center" }}>
        <div style={{ marginRight: "20px" }}>
          {<ManageAccountsIcon sx={{ height: "20px", width: "20px", color: "#80C9FC" }} />}
        </div>
        <MDTypography style={{ fontWeight: "bold", fontSize: "12px" }}>Manage Account</MDTypography>
      </MDButton> */}
      {/* <div style={{ borderBottom: "1px solid #979797", marginTop: "2px" }} /> */}
      <MDButton style={{ display: "flex", alignItems: "center" }} onClick={handleOpenp}>
        <div style={{ marginRight: "20px" }}>
          {<KeyOutlinedIcon sx={{ height: "20px", width: "20px", color: "#F97FD9" }} />}
        </div>
        <MDTypography style={{ fontWeight: "bold", fontSize: "12px" }}>
          Change Password
        </MDTypography>
      </MDButton>
      <div style={{ borderBottom: "1px solid #979797", marginTop: "2px" }} />
      {/* <MDButton style={{ display: "flex", alignItems: "center" }}>
        <div style={{ marginRight: "20px" }}>
          {<CachedOutlinedIcon sx={{ height: "20px", width: "20px", color: "#9E8FFF" }} />}
        </div>
        <MDTypography style={{ fontWeight: "bold", fontSize: "12px" }}>Activity Log </MDTypography>
      </MDButton>
      <div style={{ borderBottom: "1px solid #979797", marginTop: "2px" }} /> */}
      <MDButton style={{ display: "flex", alignItems: "center" }} onClick={handleOpenLog}>
        <div style={{ marginRight: "20px" }}>
          {<LogoutOutlinedIcon sx={{ height: "20px", width: "20px", color: "#FF8F8F" }} />}
        </div>
        <MDTypography style={{ fontWeight: "bold", fontSize: "12px" }}>Log out</MDTypography>
      </MDButton>
    </Menu>
  );

  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;
      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }
      return colorValue;
    },
  });

  return (
    <>
      <AppBar
        position={absolute ? "absolute" : navbarType}
        // color="white"
        sx={{ pb: 5, right: 0 }}
        // sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
      >
        <Toolbar
          sx={{
            width: "100%",
            padding: 0,
            display: "flex",
            justifyContent: "right",
            backgroundColor: "#ffffff",
            right: 0,
            position: "fixed",
            // paddingBottom: "200px",
          }}
        >
          <IconButton
            DehazeOutlinedIcon
            size="small"
            disableRipple
            color="inherit"
            sx={navbarMobileMenu}
            onClick={handleMiniSidenav}
          >
            <Icon sx={iconsStyle} fontSize="medium">
              {miniSidenav ? "menu_open" : "menu"}
            </Icon>
          </IconButton>
          {isMini ? null : (
            <MDBox
              sx={(theme) => navbarRow(theme, { isMini })}
              // style={{ border: "1px solid green" }}
              display="flex"
              justifyContent={{ xs: "flex-end", xl: "space-between" }}
              alignItems="center"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "75vw",
                  // Width: "400px",
                }}
              >
                <MDBox
                  pr={1}
                  display="flex"
                  alignItems="center"
                  sx={{
                    flexGrow: 1,
                    maxWidth: "450px",
                    display: { xs: "none", sm: "flex" },
                  }}
                >
                  {/* <SearchOutlinedIcon
                    style={{
                      position: "absolute",
                      left: "10px",
                      color: "red",
                    }}
                  /> */}
                  <MDInput
                    sx={{
                      ml: 2,
                      flex: 1,
                      backgroundColor: "#F5F6FA",
                      borderRadius: "20px",
                      position: "relative",
                      border: "1px solid #D5D5D5",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&:focus": {
                          border: "none",
                          outline: "none",
                        },
                      },
                    }}
                    placeholder="Search"
                  />
                </MDBox>

                <MDBox
                  display="flex"
                  alignItems="center"
                  // border="2px solid red"
                  color={light ? "white" : "inherit"}
                >
                  {/* <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={handleOpenMenu}
                >
                  <img src={Bell} alt="Bell" style={{ height: "20px" }} />
                </IconButton> */}
                  {/* <LanguageSelector /> */}
                  <Link to="/sign-in/basic">
                    {/* <IconButton sx={navbarIconButton} size="small" disableRipple>
                    <Icon sx={iconsStyle}>account_circle</Icon>
                  </IconButton> */}
                  </Link>
                  <ProfileBox name="Bhatia" imageSrc={boy} p="Admin" />

                  {renderMenu()}
                  <Changepassword open={modalOpen} handleClosep={handleClosep} />
                  <Logout open={modalOpenLog} handleClose={handleCloseLog} />
                  <MDButton>
                    <ExpandMoreOutlinedIcon
                      // sx={{ color: " #565656", border: "1px solid red ", fontSize: "16px" }}
                      size="small"
                      disableRipple
                      color="inherit"
                      // sx={navbarIconButton}
                      aria-controls="notification-menu"
                      aria-haspopup="true"
                      variant="contained"
                      onClick={handleOpenMenu}
                    ></ExpandMoreOutlinedIcon>
                  </MDButton>
                </MDBox>
              </div>
            </MDBox>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
}

DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
