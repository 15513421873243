import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import burceMars from "assets/images/bruce-mars.jpg";
import backgroundImage from "assets/images/bg-profile.jpeg";
import breakpoints from "assets/theme/base/breakpoints";
import BlockUser from "layouts/profile/block-User/Block-User";
import UnBlockUser from "layouts/profile/block-User/UnblockUser";

function Header({ userDetails, selectedName, BlockedUserId, children }) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [showBlockUser, setShowBlockUser] = useState(false);
  const [showUnBlockUser, setShowUnBlockUser] = useState(false);
  const [isUserBlocked, setIsUserBlocked] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [blockid, setBlockId] = useState(null);
  // setBlockId(BlockedUserId);

  useEffect(() => {
    // Fetch all blocked users on component mount
    const fetchAllUsers = async () => {
      try {
        const userId = localStorage.getItem("userId");
        if (!userId) {
          throw new Error("User ID is missing from local storage.");
        }
        const response = await axios.post(`http://192.168.1.27:3000/api/auths/blockUsers`, {
          page: "1",
          limit: "20",
          userId: "66c816c80a10abc3059387fd",
        });
        setAllUsers(response.data.data.blockedUsers);
      } catch (error) {
        console.error("Error fetching all users:", error.response?.data || error.message);
      }
    };

    fetchAllUsers();
  }, []);

  useEffect(() => {
    // checking if any user of blocked users list matches the BlockedUserId
    if (BlockedUserId && allUsers.length > 0) {
      const user = allUsers.find((user) => user._id === BlockedUserId);
      setIsUserBlocked(!!user);
      setBlockId(BlockedUserId);
    }
  }, [BlockedUserId, allUsers]);

  useEffect(() => {
    // Handle tabs orientation based on screen width
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, []);

  const handleBlockButtonClick = () => {
    setShowBlockUser(!showBlockUser);
  };

  const handleUnblockButtonClick = () => {
    setShowUnBlockUser(!showUnBlockUser);
  };

  const handleCloseClick = () => {
    setShowBlockUser(false);
    setShowUnBlockUser(false);
  };

  const handleBlockStatusChange = (isBlocked) => {
    setIsUserBlocked(isBlocked);
    setShowBlockUser(false);
    setShowUnBlockUser(false);
  };

  return (
    <>
      <MDBox position="relative" mb={5}>
        <MDBox
          display="flex"
          alignItems="center"
          position="relative"
          minHeight="18.75rem"
          borderRadius="xl"
          sx={{
            backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
              `${linearGradient(rgba(gradients.info.main, 0.6), rgba(gradients.info.state, 0.6))},
               url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "hidden",
          }}
        />
        <Card
          sx={{
            position: "relative",
            mt: -8,
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <MDAvatar src={burceMars} alt="profile-image" size="xl" shadow="sm" />
            </Grid>
            <Grid item>
              <MDBox height="100%" mt={0.5} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium">
                  {selectedName || "User Name"}
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
              <MDBox
                mt={4}
                mb={1}
                padding={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {isUserBlocked ? (
                  <MDButton
                    variant="dark"
                    color="dark"
                    onClick={handleUnblockButtonClick}
                    sx={{
                      background:
                        "linear-gradient(90deg, rgba(135, 11, 8, 0.84) 0%, rgba(33, 3, 2, 0.84) 100%)",
                      color: "#ffffff",
                      borderRadius: "50px",
                    }}
                  >
                    Unblock User
                  </MDButton>
                ) : (
                  <MDButton
                    variant="dark"
                    color="dark"
                    onClick={handleBlockButtonClick}
                    sx={{
                      background:
                        "linear-gradient(90deg, rgba(135, 11, 8, 0.84) 0%, rgba(33, 3, 2, 0.84) 100%)",
                      color: "#ffffff",
                      borderRadius: "50px",
                    }}
                  >
                    Block User
                  </MDButton>
                )}
              </MDBox>
            </Grid>
          </Grid>
          {children}
        </Card>
      </MDBox>

      {showBlockUser && (
        <MDBox
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            backdropFilter: "blur(5px)",
            zIndex: 1300,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <MDBox
            sx={{
              position: "relative",
              backgroundColor: "#fff",
              borderRadius: "8px",
              padding: "20px",
              maxWidth: "500px",
              width: "100%",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            }}
          >
            <IconButton
              onClick={handleCloseClick}
              sx={{
                position: "absolute",
                top: "10px",
                right: "10px",
                color: "#000000",
                zIndex: "99",
              }}
            >
              <CloseIcon />
            </IconButton>
            <BlockUser blockid={blockid} onBlockStatusChange={handleBlockStatusChange} />
          </MDBox>
        </MDBox>
      )}

      {showUnBlockUser && (
        <MDBox
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            backdropFilter: "blur(5px)",
            zIndex: 1300,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <MDBox
            sx={{
              position: "relative",
              backgroundColor: "#fff",
              borderRadius: "8px",
              padding: "20px",
              maxWidth: "500px",
              width: "100%",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            }}
          >
            <IconButton
              onClick={handleCloseClick}
              sx={{
                position: "absolute",
                top: "10px",
                right: "10px",
                color: "#000000",
                zIndex: "99",
              }}
            >
              <CloseIcon />
            </IconButton>
            <UnBlockUser blockid={blockid} onBlockStatusChange={handleBlockStatusChange} />
          </MDBox>
        </MDBox>
      )}
    </>
  );
}

Header.propTypes = {
  userDetails: PropTypes.object,
  selectedName: PropTypes.string,
  BlockedUserId: PropTypes.string,
  children: PropTypes.node,
};

export default Header;
