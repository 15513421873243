import React from "react";
import { Outlet, Route, Switch } from "react-router-dom";
import InboxSide from "./Inbox/InboxSide";
import DashboardLayout from "interface/LayoutContainers/DashboardLayout";
import { Grid, Box } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Inboxdata from "./Inbox-data";

function Inboxlayout() {
  return (
    <>
      <Inboxdata />
    </>
  );
}

export default Inboxlayout;
