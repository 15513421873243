import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { updateData } from "services/request";
import { getItem } from "utils/localStorage";
// import { useNavigate } from "react-router-dom";

const userequestResetLogic = () => {
  const userId = getItem("userId");

  const [loading, setloading] = useState(false);
  const [password, setPassword] = useState("");
  const [newPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();
  // const [loading, setloading] = useState(second);
  const [message, setMessage] = useState("");
  const [characterserror, setCharacterError] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setError(false);
    setCharacterError(false);
  }, [password, newPassword]);

  // const API = "http://192.168.1.27:3000/api/auths/updatePassword/6694e50ab7cac48d5af4b9fc";

  //   const API = `${process.env.REACT_APP_BASE_URL}/auths/resetPassword/${userId}`;
  const API = "http://192.168.29.141:7000/api/auths/resetPassword/6746ffe70c278f7c530c7f07";
  const payload = { password: password };

  const handleResetPassword = async (e) => {
    setloading(true);
    e.preventDefault();
    // navigate("/");
    if (password.trim().length < 8) {
      setloading(false);
      return setCharacterError(true);
    }
    if (password !== newPassword) {
      setError(true);
      setloading(false);
      return;
    }

    try {
      const response = await axios.post(API, payload);
      console.log(response);
      console.log("Password reset successfully!");
      setMessage("Password reset successfully!");
      navigate("/login");
    } catch (error) {
      console.log(error);
      console.log("Failed to reset password. Please try again.");
    } finally {
      setloading(false);
    }
  };
  return {
    handleResetPassword,
    error,
    characterserror,
    setConfirmPassword,
    setPassword,
    message,
    password,
    newPassword,
    loading,
  };
};
export default userequestResetLogic;
