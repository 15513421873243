import React from "react";
import PropTypes, { number } from "prop-types";
import { Card, Grid, Typography, Box, Icon } from "@mui/material";

function IconTextValueComponent({ icon, text, heading, value, svg, number, dcreasenumber }) {
  return (
    <Card sx={{ borderRadius: 5 }}>
      <Grid
        container
        display={"flex"}
        justifyContent={"space-between"}
        alignItems="center"
        // marginLeft="5px"
        spacing={2}
        p={1}
      >
        <Grid item xs={8}>
          <Typography
            sx={{ color: "#202224", fontWeight: "normal", fontSize: "16px", paddingLeft: "4px" }}
          >
            {text}
          </Typography>
          <Typography
            sx={{ fontWeight: "medium", fontSize: "28px", color: "#304F5E", paddingLeft: "4px" }}
          >
            {value}
          </Typography>
        </Grid>
        <Grid item>
          <Box fontSize="3rem">
            <img
              src={icon}
              alt="Icon"
              style={{ width: "3rem", height: "3rem", borderRadius: "1px" }}
            />
          </Box>
        </Grid>

        <Grid
          item
          display={"flex"}
          // justifyContent={"space-between"}
          alignItems="center"
          // marginLeft="5px"
          // spacing={5}
          // gap={"5px"}
          // p={1}
        >
          <Box>
            <img src={svg} alt="icon" style={{ width: "2rem", height: "2rem" }} />
          </Box>
          <Typography
            sx={{ color: "#00B69B", fontWeight: "bold", fontSize: "14px", paddingLeft: "4px" }}
          >
            {number}
          </Typography>
          <Typography
            sx={{ color: "#F93C65", fontWeight: "bold", fontSize: "14px", paddingLeft: "4px" }}
          >
            {dcreasenumber}
          </Typography>
          <Typography
            sx={{ color: "#606060", fontWeight: "bold", fontSize: "14px", paddingLeft: "4px" }}
          >
            {heading}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
}

IconTextValueComponent.propTypes = {
  icon: PropTypes.string.isRequired, // Assuming 'icon' is a string (icon name or URL)
  text: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  svg: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  dcreasenumber: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default IconTextValueComponent;
