import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Divider,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Editemail from "interface/Edit-eamil";
import Newcompose from "interface/New-Compose";

const InboxSide = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);
  const [selectedButton, setSelectedButton] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const buttons = [
    { name: "Inbox", icon: EmailOutlinedIcon, count: "1245", route: "/inbox" },
    { name: "Starred", icon: StarBorderOutlinedIcon, count: "245", route: "/inbox/starred" },
    { name: "Sent", icon: SendOutlinedIcon, count: "24,534", route: "/inbox/sent" },
    { name: "Draft", icon: ModeEditOutlineOutlinedIcon, count: "09", route: "/inbox/draft" },
    { name: "Spam", icon: WarningAmberOutlinedIcon, count: "14", route: "/inbox/spam" },
    { name: "Important", icon: ChatOutlinedIcon, count: "18", route: "/inbox/important" },
    { name: "Bin", icon: DeleteOutlineOutlinedIcon, count: "10", route: "/inbox/bin" },
  ];

  const handleButtonClick = (name, route) => {
    setSelectedButton(name);
    navigate(route);
    if (isSmallScreen) {
      setIsSidebarOpen(false);
    }
  };

  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <Box sx={{ display: "flex" }}>
      {isSmallScreen && (
        <IconButton
          color="inherit"
          aria-label="open sidebar"
          onClick={handleSidebarToggle}
          sx={{ position: "fixed", top: 150, left: 16 }}
        >
          <MenuIcon />
        </IconButton>
      )}

      <Box
        sx={{
          width: { xs: "300px", sm: "300px" },
          backgroundColor: "#f8f9fa",
          height: "100vh",
          overflowY: "auto",
          borderRight: "1px solid #e0e0e0",
          padding: "16px",
          display: isSmallScreen && !isSidebarOpen ? "none" : "block",
          position: isSmallScreen ? "fixed" : "relative",
          zIndex: isSmallScreen ? 1200 : "auto",
          boxShadow: isSmallScreen ? 2 : "none",
        }}
      >
        {isSmallScreen && isSidebarOpen && (
          <Box mb={2} textAlign="right">
            <IconButton onClick={handleSidebarToggle}>
              <CloseIcon />
            </IconButton>
          </Box>
        )}

        <Box mb={3} textAlign="center">
          <Button
            variant="contained"
            sx={{
              width: "90%",
              backgroundColor: "#4172DC",
              color: "#FFFFFF",
              borderRadius: "8px",
              textTransform: "capitalize",
            }}
            onClick={handleOpen}
          >
            + Compose
          </Button>
        </Box>

        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 2, pl: 1 }}>
          My Email
        </Typography>

        <Box>
          {buttons.map(({ name, icon: Icon, count, route }) => (
            <Button
              key={name}
              onClick={() => handleButtonClick(name, route)}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                padding: "10px",
                backgroundColor: selectedButton === name ? "#4880FF" : "#fffff",
                color: selectedButton === name ? "#fffff" : "#202224",
                textTransform: "capitalize",
                marginBottom: "8px",
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: selectedButton === name ? "#4880FF" : "#f0f0f0",
                },
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Icon sx={{ marginRight: "8px" }} />
                <Typography variant="body2">{name}</Typography>
              </Box>
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                {count}
              </Typography>
            </Button>
          ))}
        </Box>

        {isSmallScreen && isSidebarOpen && <Divider sx={{ my: 2 }} />}

        {/* <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", mt: 4, mb: 2, pl: 1, color: "#202224" }}
        >
          Label
        </Typography> */}
        <Newcompose open={modalOpen} handleClose={handleClose} />
        {/* <FormGroup sx={{ pl: 1 }}>
          <FormControlLabel control={<Checkbox />} label="Primary" />
          <FormControlLabel control={<Checkbox />} label="Social" />
          <FormControlLabel control={<Checkbox />} label="Work" />
          <FormControlLabel control={<Checkbox />} label="Friends" />
        </FormGroup> */}

        {/* <Box mt={2} textAlign="center" color={"dark"}>
          <Button sx={{ width: "90%", textTransform: "capitalize" }}>+ Create New Label</Button>
        </Box> */}
      </Box>
    </Box>
  );
};

export default InboxSide;
