import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React interface
import DataTable from "interface/Tables/DataTable";

// Data
import data from "layouts/dashboard/components/Projects/data/recentPayouts";
import { Padding } from "@mui/icons-material";

function Payouts() {
  const { columns, rows } = data();
  const [menu, setMenu] = useState(null);
  // State to store the selected option
  const [selectedOption, setSelectedOption] = useState("");

  // Handler function to update the state on option change
  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>Action</MenuItem>
      <MenuItem onClick={closeMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMenu}>Something else</MenuItem>
    </Menu>
  );

  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox>
          <MDTypography
            sx={{
              fontWeight: "bold",
              color: "#304F5E",
              fontSize: {
                xs: "12px",
                sm: "14px",
                md: "16px",
                lg: "20px",
                xl: "24px",
              },
              // mt: -0.5,
            }}
            // gutterBottom
          >
            Deals Details
          </MDTypography>
        </MDBox>
        <MDBox>
          {/* <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" onClick={openMenu}>
            more_vert
          </Icon> */}

          <select
            id="options"
            value={selectedOption}
            onChange={handleChange}
            style={{ border: "1px solid #D5D5D5", padding: "10px 8px", fontWeight: "normal" }}
          >
            <option value="January">January</option>
            <option value="February">February</option>
            <option value="March">March</option>
            <option value="April">April</option>
            <option value="May">May</option>
            <option value="June">June</option>
            <option value="July">July</option>
            <option value="August">August</option>
            <option value="September">September</option>
            <option value="October">October</option>
            <option value="November">November</option>
            <option value="December">December</option>
          </select>
        </MDBox>
        {renderMenu}
      </MDBox>
      <MDBox>
        <DataTable
          table={{ columns, rows }}
          showTotalEntries={false}
          isSorted={false}
          noEndBorder
          entriesPerPage={false}
        />
      </MDBox>
    </Card>
  );
}

export default Payouts;
