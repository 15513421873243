import { useEffect, useState } from "react";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "interface/Tables/DataTable";
import DashboardLayout from "interface/LayoutContainers/DashboardLayout";
import DashboardNavbar from "interface/Navbars/DashboardNavbar";
import UserProfile from "layouts/profile/userprofile";
import { useNavigate } from "react-router-dom";
import { Menu } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { getItem } from "utils/localStorage";
import { getData } from "services/request";
import MDButton from "components/MDButton";
import { Modal, Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
// icons
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { responsiveFontSizes } from "@mui/material";
import { CheckBox, WidthFull } from "@mui/icons-material";
import MDInput from "components/MDInput";
import { Link } from "react-router-dom";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "Full",
  width: { xs: "300px", md: "700px", sm: "100px", xl: "1000px" },
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  position: "relative",
};
function Medicinedetail({ open, handleCloseview }) {
  // const [usersData, setUsersData] = useState([]);
  const [showUserProfile, setShowUserProfile] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedName, setSelectedName] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const navigate = useNavigate();

  const handleRowClick = (row) => {
    setSelectedRow(row.uniqueId);
    // navigate(/user-profile/${row.uniqueId});
    setSelectedName(row.name);
    console.log("this is a name of selected row", selectedName);
  };

  const columns = [
    { Header: "Name", accessor: "Name" },
    { Header: "Generic Name", accessor: "GenericName" },
    { Header: "weight", accessor: "weight" },
    { Header: "Category ", accessor: "Category" },
    { Header: "Price ", accessor: "Price" },
    { Header: "Stock", accessor: "Stock" },
    { Header: "Status", accessor: "Status" },
  ];
  const usersData = [
    {
      Name: "MED - 1008",
      GenericName: "Azithromycin",
      weight: "500mg",
      Category: "Tablet",
      Price: "$20.55 USD",
      Stock: 42,
      Status: "HLO",
    },
  ];
  return (
    <>
      {/* <DashboardNavbar /> */}
      <Modal
        open={open}
        onClose={(e, reason) => {
          if (reason === "backdropClick") {
            e.stopPropagation();
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{
          invisible: true,
        }}
        disableEscapeKeyDown
      >
        <MDBox pt={6} pb={3} sx={style}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <MDBox
                mx={2}
                pt={3}
                px={2}
                // variant="gradient"
                bgColor="none"
                borderRadius="lg"
                coloredShadow="transparent"
                sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <MDBox>
                  <MDTypography variant="h3" color="dark">
                    Medicine details
                  </MDTypography>
                </MDBox>
                <MDBox
                  sx={{
                    display: "flex",
                    // justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <MDBox>
                    <MDButton onClick={handleCloseview}>
                      <CancelOutlinedIcon style={{ color: "black", fontSize: "50px" }} />
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
              <MDBox pt={3} style={{ height: "100%", minHeight: "400px" }}>
                <DataTable
                  entriesPerPage={{ defaultValue: 4, entries: [5, 10, 15] }}
                  // canSearch={true}
                  showTotalEntries={true}
                  table={{ columns, rows: usersData }}
                  pagination={{ variant: "gradient", color: "white" }}
                  isSorted={true}
                  noEndBorder={true}
                  onRowClick={handleRowClick}
                />
              </MDBox>
              {/* <MDBox pt={1} style={{ height: "auto" }}>
                {showUserProfile && (
                  <UserProfile uniqueId={selectedRow} onClose={() => setShowUserProfile(false)} />
                )}
              </MDBox> */}
            </Grid>
          </Grid>
        </MDBox>
      </Modal>
    </>
  );
}
Medicinedetail.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseview: PropTypes.func.isRequired,
};
export default Medicinedetail;
