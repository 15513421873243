import { styled } from "@mui/material/styles";
import MDButton from "components/MDButton";
// import CustomMDPaginationItemRoot from "./customMDPagination";

export default styled(MDButton)(({ theme, ownerState }) => {
  const { borders, functions, typography, palette } = theme;
  const { borderColor } = borders;
  const { pxToRem } = functions;
  const { fontWeightRegular, size: fontSize } = typography;
  const { light } = palette;

  // Increase width as needed
  const widthValue = pxToRem(50); // Example width, adjust as necessary

  return {
    borderColor,
    margin: `0 ${pxToRem(1)}`,
    pointerEvents: ownerState.active ? "none" : "auto",
    fontWeight: fontWeightRegular,
    fontSize: fontSize.sm,
    width: widthValue,
    minWidth: widthValue,
    height: "auto",
    borderRadius: 0,
    backgroundColor: ownerState.selected ? "#9a3230" : "transparent",

    "&:hover, &:focus, &:active": {
      transform: "none",
      boxShadow:
        (ownerState.variant !== "gradient" || ownerState.variant !== "contained") &&
        "none !important",
      opacity: " !important",
    },

    "&:hover": {
      backgroundColor: ownerState.selected ? light.main : "transparent",
      borderColor,
    },
  };
});
