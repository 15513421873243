import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { setItem } from "utils/localStorage";

const useBasicLogic = () => {
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  //   const API = "http://192.168.29.141:7000/api/auths/login";

  console.log(process.env.REACT_APP_BASE_URL);

  const API = `${process.env.REACT_APP_BASE_URL}/auths/login`;

  // Clear errors when inputs change
  useEffect(() => {
    setEmailError("");
    setPasswordError("");
  }, [email, password]);

  // Toggle password visibility
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  // Handle checkbox toggle
  const handleCheckboxChange = (event) => {
    setRememberMe(event.target.checked);
  };

  // Form submission and API call
  const handleApi = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!email.trim()) {
      setLoading(false);
      return setEmailError("Field Required");
    }
    if (!email.trim().includes("@")) {
      setLoading(false);
      return setEmailError("Enter a valid Email");
    }
    if (!password.trim()) {
      setLoading(false);
      return setPasswordError("Field Required");
    }

    const payload = {
      username: email.trim(),
      password: password.trim(),
      authMethod: "email",
      verificationType: "password",
      deviceId: "123",
      deviceType: "web",
    };

    try {
      const response = await axios.post(API, payload);
      if (response.status === 200) {
        navigate("../../dashboard");
        setItem("token", response.data.data.session.accessToken);
        setItem("userId", response.data.data.id);
        setEmail("");
        setPassword("");
        setLoading(false);
        return;
      }
    } catch (error) {
      setPasswordError(error.response.data.message);
      setLoading(false);
    }
  };

  return {
    rememberMe,
    setRememberMe,
    email,
    setEmail,
    emailError,
    password,
    setPassword,
    passwordError,
    showPassword,
    handleClickShowPassword,
    handleCheckboxChange,
    handleApi,
    loading,
  };
};

export default useBasicLogic;
