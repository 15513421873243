import Card from "@mui/material/Card";
import { Checkbox } from "@mui/material";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import logo from "../../../assets/logo/Logo.png";
// import { postData } from "services/request";
import userequestResetLogic from "./RequestResetLogic";
import { red } from "@mui/material/colors";

function RequestNewPassword() {
  const {
    handleResetPassword,
    error,
    characterserror,
    setConfirmPassword,
    setPassword,
    password,
    message,
    newPassword,
    loading,
  } = userequestResetLogic();
  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          bgColor="transparent"
          borderRadius="lg"
          // mt={3}
          p={2}
          // mb={1}
          textAlign="center"
          width="100%"
        >
          {/* <img src={logo} alt="Logo" /> */}
          <MDTypography variant="h4" fontWeight="bold" color="black" mt={1}>
            Create New Password
          </MDTypography>
          <MDTypography
            variant="h6"
            fontWeight="normal"
            color="black"
            mt={1}
            sx={{ color: "#008080" }}
          >
            Your new password must be different from the old password
          </MDTypography>
        </MDBox>
        <MDBox pt={2} pb={1} px={3}>
          <form onSubmit={handleResetPassword}>
            <MDBox mb={5}>
              <MDTypography
                style={{
                  color: "#210302",
                  fontWeight: " semibold ",
                  fontSize: "18px",
                  marginBottom: "10px",
                }}
              >
                Password
              </MDTypography>

              <MDInput
                type="password"
                // label="Password"
                // variant="standard"
                placeholder="Enter your password"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <MDTypography
                style={{
                  color: characterserror ? "#FF0000" : "#21030275",
                  fontWeight: " light ",
                  fontSize: "12px",
                  marginTop: "8px",
                  marginLeft: "10px",
                }}
              >
                Must be at least 8 characters
              </MDTypography>
            </MDBox>
            <MDBox mb={4}>
              <MDTypography
                style={{
                  color: "#210302",
                  fontWeight: " semibold ",
                  fontSize: "18px",
                  marginBottom: "10px",
                }}
              >
                Confirm Password
              </MDTypography>
              <MDInput
                type="password"
                // label="Confirm Password"
                // variant="standard"
                placeholder="Enter your confirm password"
                fullWidth
                value={newPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <MDTypography
                // {console.log(Error)

                style={{
                  color: error ? "#FF0000" : "#21030275",

                  fontWeight: " light ",
                  fontSize: "12px",
                  marginTop: "8px",
                  marginLeft: "10px",
                }}
              >
                Both passwords must matchs
              </MDTypography>
            </MDBox>
            <MDBox mt={6} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                onClick={(e) => handleResetPassword(e)}
                fullWidth
                type="submit"
                sx={{
                  textTransform: "none",
                  background:
                    // "linear-gradient(90deg, rgba(135, 11, 8, 0.84) 0%, rgba(33, 3, 2, 0.84) 100%)",
                    "#7F9AE3",
                  color: "#ffffff",
                  borderRadius: "50px",
                  "&:hover": {
                    background:
                      // "linear-gradient(90deg, rgba(135, 11, 8, 0.84) 0%, rgba(33, 3, 2, 0.84) 100%)",
                      "#7F9AE3",
                  },
                }}
              >
                {loading ? "Loading..." : "Reset Password"}
              </MDButton>
            </MDBox>
            {message && (
              <MDTypography variant="body2" color="error" align="center">
                {message}
              </MDTypography>
            )}
          </form>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default RequestNewPassword;
