import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";

const ProfileBox = ({ name, imageSrc, p }) => {
  return (
    <div style={styles.profileBox}>
      {imageSrc ? (
        <img src={imageSrc} alt="Profile" style={styles.image} />
      ) : (
        <FontAwesomeIcon icon={faUserCircle} style={styles.icon} />
      )}

      <div>
        <h3 style={styles.name}>{name}</h3>
        <p style={styles.p}>{p}</p>
      </div>
    </div>
  );
};

ProfileBox.propTypes = {
  name: PropTypes.string.isRequired,
  imageSrc: PropTypes.string,
  p: PropTypes.string.isRequired,
};

const styles = {
  profileBox: {
    display: "flex",
    alignItems: "center",
    // border: "1px solid #F5F7F8",
    // borderRadius: "50px",
    padding: "10px",
  },
  image: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    marginRight: "15px",
  },
  icon: {
    fontSize: "40px",
    marginRight: "10px",
  },
  name: {
    margin: 0,
    fontSize: "14px",
    color: "#404040",
    fontWeight: "700",
  },
  p: {
    margin: 0,
    fontSize: "12px",
    color: "#565656",
    fontWeight: "600",
  },
};

export default ProfileBox;
