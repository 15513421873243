import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "interface/LayoutContainers/DashboardLayout";
import DashboardNavbar from "interface/Navbars/DashboardNavbar";

// Material-UI components
import { Card, Hidden, IconButton } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import Checkbox from "@mui/material/Checkbox";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import MDTypography from "components/MDTypography";
import InboxSide from "../Inbox/InboxSide"; // Sidebar
import DataTable from "interface/Tables/DataTable";
import StarIcon from "@mui/icons-material/Star";
import Openmessage from "interface/Open-Message";
import { Navigate, useNavigate } from "react-router-dom";

function Inboxdata() {
  const [data, setData] = useState([
    {
      id: 1,
      name: "Jullu Jalal",
      label: "Primary",
      time: "8:38 AM",
      description: "Our Bachelor of Commerce program is ACBSP-accredited.",
      isStarred: false, // Initial star state
    },
    {
      id: 2,
      name: "Another Person",
      label: "Secondary",
      time: "9:00 AM",
      description: "Another description here.",
      isStarred: false, // Initial star state
    },
    {
      id: 3,
      name: "Someone Else",
      label: "Tertiary",
      time: "10:00 AM",
      description: "More text goes here.",
      isStarred: false, // Initial star state
    },
  ]);

  const [search, setSearch] = useState("");

  // Columns for the DataTable
  const columns = [
    { Header: "", accessor: "select", width: "5%" },
    { Header: "", accessor: "star", width: "5%" },
    { Header: "", accessor: "name", width: "20%" },
    { Header: "", accessor: "label", width: "15%" },
    { Header: "", accessor: "description", width: "40%" },
    { Header: "", accessor: "time", width: "15%" },
  ];

  // Toggle star state
  const toggleStar = (id) => {
    setData((prevData) =>
      prevData.map((item) => (item.id === id ? { ...item, isStarred: !item.isStarred } : item))
    );
  };

  // Rows for the DataTable
  const rows = data
    .filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))
    .map((item) => ({
      select: <Checkbox />,
      star: (
        <IconButton onClick={() => toggleStar(item.id)}>
          {item.isStarred ? <StarIcon color="warning" /> : <StarBorderOutlinedIcon />}
        </IconButton>
      ),
      name: item.name,
      label: (
        <span
          style={{
            background: "#FDF1E8",
            fontWeight: "bold",
            color: "#E46A11",
            padding: "2px 5px",
            fontSize: "12px",
            borderRadius: "10px",
          }}
        >
          {item.label}
        </span>
      ),
      description: item.description,
      time: item.time,
    }));
  const [showMessage, setShowMessage] = useState(false);
  const navigate = useNavigate();

  const handleRowClick = () => {
    navigate("/inbox/Openmessage");
  };

  return (
    <DashboardLayout>
      <MDBox
        pb={4}
        pt={4}
        bgColor="none"
        borderRadius="lg"
        coloredShadow="transparent"
        sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
      >
        <MDBox>
          <MDTypography style={{ fontSize: "32px", fontWeight: "bold", color: "#304F5E" }}>
            Inbox
          </MDTypography>
        </MDBox>
      </MDBox>

      <Grid container spacing={3} sx={{ marginLeft: -4 }}>
        <Grid item xs={12} sm={3} md={3}>
          <InboxSide />
        </Grid>

        {/* Main Content */}
        <Grid item xs={12} sm={9} md={9}>
          <MDBox style={{ height: "100%", minHeight: "400px", width: "100%" }}>
            <Card>
              <MDBox>
                {/* <MDTypography
                  pt={2}
                  style={{ paddingLeft: "50px", paddingBottom: "20px", fontWeight: "bold" }}
                >
                  Inbox
                </MDTypography> */}

                {/* Search Bar */}
                <MDBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mt: 3,
                    mb: 2,
                    pl: 2,
                    ml: 2,
                    border: "1px solid black",
                    borderRadius: "20px",
                    overflow: "hidden",
                    maxWidth: "200px",
                    backgroundColor: "#F5F6FA",
                    width: "100%", // Make search bar width responsive
                  }}
                >
                  <IconButton sx={{ p: "10px" }} aria-label="search">
                    <SearchIcon />
                  </IconButton>
                  <input
                    style={{
                      border: "none",
                      outline: "none",
                      fontSize: "15px",
                      padding: "10px",
                      flex: 1,
                      backgroundColor: "#F5F6FA",
                    }}
                    placeholder="Search Mail"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </MDBox>

                {/* Data Table */}
                <DataTable
                  entriesPerPage={{ defaultValue: 6, entries: [5, 10, 15] }}
                  checkboxSelection
                  showTotalEntries={true}
                  table={{ columns, rows }}
                  pagination={{ variant: "gradient", color: "white" }}
                  isSorted={true}
                  sx={{ border: 0 }}
                  onRowClick={handleRowClick}
                />
              </MDBox>
            </Card>
          </MDBox>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default Inboxdata;
