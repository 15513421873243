import React, { useState } from "react";
import { Modal, Box, Typography, IconButton } from "@mui/material";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDSnackbarIconRoot from "components/MDSnackbar/MDSnackbarIconRoot";
import borders from "assets/theme/base/borders";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90%", sm: "450px" },
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  position: "relative",
};

const Logout = ({ open, handleClose }) => {
  const navigate = useNavigate();

  const Log = () => {
    navigate("/sign-in");
  };
  return (
    <Modal
      open={open}
      onClose={(e, reason) => {
        if (reason === "backdropClick") {
          e.stopPropagation();
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      BackdropProps={{
        invisible: true,
      }}
      disableEscapeKeyDown
    >
      <Box sx={style}>
        <MDBox
          bgColor="transparent"
          borderRadius="lg"
          mt={3}
          p={2}
          mb={1}
          textAlign="center"
          width="100%"
        >
          <MDTypography variant="h4" fontWeight="medium" color="black" mt={1}>
            Are you sure you want to logout?
          </MDTypography>
          <MDBox sx={{ paddingTop: 5 }}>
            <MDButton
              type="submit"
              onClick={Log}
              sx={{
                width: "300px",
              }}
            >
              <MDTypography
                sx={{
                  fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" },
                  color: "#FFFFFF",
                  backgroundColor: "#5478D9",
                  padding: "10px",
                  borderRadius: "8px",
                  width: "100%",
                }}
              >
                Logout
              </MDTypography>
            </MDButton>
            <MDButton
              type="submit"
              onClick={handleClose}
              sx={{
                width: "300px",
              }}
            >
              <MDTypography
                sx={{
                  fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" },
                  color: "black",
                  backgroundColor: "inhert",
                  padding: "10px",
                  borderRadius: "8px",
                  border: "1px solid gray",
                  width: "100%",
                }}
              >
                Cancel
              </MDTypography>
            </MDButton>
          </MDBox>
        </MDBox>
      </Box>
    </Modal>
  );
};

Logout.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default Logout;
